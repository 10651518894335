import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function DataHubFeature() {
  const benefits = [
    {
      img: img1,
      title: "Farm & Asset Data Store ",
      bodycopy: "Farm & Asset Data Store, a feature provided by DataHub, securely stores and manages agricultural data, supporting informed decision-making within agribusiness."    },
    {
      img: img2,
      title: "Enviornmental Data Store",
      bodycopy: "Environmental Data Store, a feature provided by DataHub, securely stores environmental data from sensors and other devices, facilitating analysis and decision-making for environmental monitoring within various applications.",
    },
    {
      img: img3,
      title: "Behaviour Data Store",
      bodycopy:
        "Rule management, a feature provided by DeviceHub, specifies topics for devices, applications, partners, and agribusiness to connect, publish, and subscribe to the MQTT broker, enabling efficient communication and data exchange within the IoT ecosystem.",
    },
    {
        img: img3,
        title: "History Management",
        bodycopy:
          "Farm weather history management is a feature that provides historical weather information for specific sites and site under the solution, aiding in informed decision-making and agricultural planning based on past weather patterns and trends.",
      },
     
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4}  key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
