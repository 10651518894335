import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgribusinessStack from "../AgribusinessStack/AgribusinessStack";
import './ForAgriTransporter.css';
export default function ForAgriTransporter() {
  return (
    <>
      <HeaderComponent />
   
   <AgribusinessStack
        classname="agri-transporter"
        level1="System Integrator"
        level2="Agribusiness"
        level3="Agri-Transporters"
      />
     
      <SolutionEcosystem
      title="DTN Deployment for Agri-Transporters"
        type="AT"
        bodycopy=" ACN is a type of DTN network where agri-transporters can join to monetize unutilized transportation capacity."
      />
      
      <FooterComponent />
    </>
  );
}
