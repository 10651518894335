import React from 'react'
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
import ProductionManagerImg from "../../images/gbrPN/PNImg5.webp";

export default function ABNNetworkOperator() {
  return (
    <div className='subSectionWrapper'>
          <h2 className=' container-title text-center mx-md-4'>ABN NETWORK OPERATORS</h2>

    <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="agro-dealer-img"
          description="
          Telecom operators, also known as telecommunications operators, are companies that provide communication services to consumers and businesses. These services typically include voice calling, messaging, internet connectivity, and other telecommunications-related services. Telecom operators manage and operate the infrastructure necessary for these services, such as telephone networks, mobile networks, fiber optic cables, and satellite communication systems
              "
          descriptionTitle="
          Telecom Operators"
          src={ProductionManagerImg}
    
          // href4="http://abn.gbrapp.com/"
          // buttoncont1="Sign In"
        />

<HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image2 "
          description="Financial institutions encompass a wide range of entities that offer financial services such as banking, lending, investing, insurance, and asset management. These institutions include banks, credit unions, insurance companies, investment firms, stock exchanges, pension funds, mutual funds, and hedge funds. They play a crucial role in the economy by facilitating the flow of funds, managing risk, providing liquidity, and supporting economic growth and development through the allocation of capital.
            "
          descriptionTitle="
          Financial Institutions "
          
          // href4="http://abn.gbrapp.com/"
          // buttoncont1="Sign In"
        />

<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="agro-dealer-img"
          description="
          Agribusiness value aggregators, IoT solution providers, IT solution providers, IT companies, and value-added resellers are operators of the Agribusiness Network (ABN). These entities can create, own, and manage agribusinesses by leveraging the ABN's advanced digital infrastructure. This network enables seamless integration of IoT solutions, data analytics, and IT services to optimize agricultural operations. Through the ABN, operators can enhance efficiency, improve resource utilization, and ensure regulatory compliance. By offering real-time monitoring, automated processes, and customer interfaces, the ABN supports informed decision-making and boosts overall productivity, leading to more efficient, sustainable, and profitable agribusinesses."
                    descriptionTitle="
          Other System Integrators"
          src={ProductionManagerImg}
    
          // href4="http://abn.gbrapp.com/"
          // buttoncont1="Sign In"
        />
    </div>
  )
}
