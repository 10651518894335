import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import './NetworkOperatorAppStoreService.css';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
export default function NetworkOperatorAppStoreService() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="network-operator-appstore-bg"
        bannerTitle={[
          "Access to Agribusiness App Store",
        ]}/>

<div className="main-wrapper">
        <div className="subSectionWrapper">
    <HomeSubSection
        className3="app-store-access-sevice"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Access to Agribussiness App Store"
        description="GBR Network extends its services to network operators by offering access to the Agribusiness App Store—a platform designed exclusively for the agricultural sector. Through this service, network operators gain access to a curated selection of applications tailored specifically for agribusinesses. These applications cover a wide range of functionalities, from crop management and livestock tracking to market analysis and supply chain optimization. By providing access to the Agribusiness App Store, GBR Network empowers network operators to enhance their offerings to agricultural clients, enabling them to streamline operations, boost productivity, and ultimately drive growth in the agricultural sector.
        "
        href5="https://partner.gbrfarming.in/register.html?src=registerReq"
        buttoncont2="Register"
        buttoncont1="Explore"
        href1="/ComingSoon"
       
      />
      </div>
      </div>
    <FooterComponent/>
    
    </>  )
}
