import React from "react";
import InventoryManagement from "../../images/agrifood-stack-features-icon/inventory-management.svg"
import ProductCategorization from "../../images/agrifood-stack-features-icon/categorization.svg"
import UserFriendlyInterface from "../../images/agrifood-stack-features-icon/user-friendly-interface.svg"
import TemplateLibrary from "../../images/agrifood-stack-features-icon/template-library.svg"
import DragDrop from "../../images/agrifood-stack-features-icon/drag-and-drop.svg"
import SectorManagement from "../../images/agrifood-stack-features-icon/sector-management.svg"
import AssetListing from "../../images/agrifood-stack-features-icon/asset-listing.svg"
import AssetCreation from "../../images/agrifood-stack-features-icon/asset-creation.svg"
import QRCodeGeneration from "../../images/agrifood-stack-features-icon/qr-code-generation.svg";
import AssetOperation from "../../images/agrifood-stack-features-icon/asset-operation.svg";
import DeviceIntegration from "../../images/agrifood-stack-features-icon/device-integration.svg"
import SiteViewCreate from "../../images/agrifood-stack-features-icon/view-and-create.svg"
import AssetManagement from "../../images/agrifood-stack-features-icon/asset-management.svg"
import SiteInformation from "../../images/agrifood-stack-features-icon/Information.svg"
import SiteRating from "../../images/agrifood-stack-features-icon/rating.svg"


import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function IOBFeatures() {
  const benefits = [
    {
      img: InventoryManagement,
      title: "Inventory Management",
      bodycopy: "Track all agriculture products, including processed, raw, and semi-processed items, for efficient stock management."},
    {
      img: ProductCategorization,
      title: "Product Categorization      ",
      bodycopy: "  Organize products into different categories like processed, raw, semi-processed, plant health, and animal health for easier management.",
    },
    {
      img: UserFriendlyInterface,
      title: "User-Friendly Interface",
      bodycopy:
        "DocGen Management boasts an intuitive design that simplifies document generation for users of all technical backgrounds.",
    },
    {
      img: TemplateLibrary,
      title: "Template Library",
      bodycopy:
        "Gain access to an extensive collection of pre-designed templates catering to various document types, including RFQs, Purchase Orders (POs), Proforma Invoices, reports, invoices, and more.",
    },
    {
      img: DragDrop,
      title: "Drag-and-Drop Functionality",
      bodycopy:
        "Easily enhance your documents by incorporating images, logos, and signatures through a seamless drag-and-drop interface.",
    },

    

    {
      img: SectorManagement,
      title: "Sector, Category, Asset Type, and Model Management",
      bodycopy:
        " Easily categorize assets based on various parameters such as sector, category, asset type, and model for efficient organization.",
    },
    {
      img: AssetListing,
      title: "Asset Listing",
      bodycopy:
        "View a comprehensive list of assets created by the user, categorized by sector, category, asset type, and model.",
    },
    {
      img: AssetCreation,
      title: "Asset Creation",
      bodycopy:
        " Create new assets seamlessly within the system, allowing for easy addition of agricultural resources.",
    },
    {
      img: QRCodeGeneration,
      title: "QR Code Generation",
      bodycopy:
        " Generate QR codes for each asset, enabling quick and easy tracking and identification.",
    },
    {
      img: AssetOperation,
      title: "Asset Operations",
      bodycopy:
        "  Perform various operations on assets, including mapping assets to sites, updating asset information and status, and deleting assets when necessary.",
    },

    {
      img: DeviceIntegration,
      title: "Device Integration",
      bodycopy:
        "  View data points and analytics from devices attached to assets, enabling informed decision-making and proactive management.",
    },
    {
      img: SiteViewCreate,
      title: "Site Viewing and Creation",
      bodycopy:
        "   Users can easily view existing agricultural sites and create new ones as needed, facilitating efficient site management.",
    },




    {
      img: AssetManagement,
      title: " Asset Management",
      bodycopy:
        "  Gain insights into assets assigned to individual sites, allowing for better resource allocation and optimization.",
    },{
      img: SiteInformation,
      title: "Site Information",
      bodycopy:
        "   Access detailed information about each site, including specifications, location details, and any relevant notes.",
    },{
      img: SiteRating,
      title: "Site Rating",
      bodycopy:
        "   Rate and evaluate the performance of each site based on various criteria, enabling continuous improvement and quality control.",
    },
















    // {
    //   img: img3,
    //   title: "Supply Chain Management ",
    //   bodycopy:
    //     "Streamline the movement of products from production to distribution, ensuring timely delivery and reducing wastage. ",
    // },
    // {
    //     img: img3,
    //     title: "Quality Control",
    //     bodycopy:
    //       "Implement measures to ensure products meet regulatory standards and are safe for consumption or use.",
    //   },

    //   {
    //     img: img3,
    //     title: "Plant and Animal Health Monitoring",
    //     bodycopy:
    //       "Track growth metrics, diseases, treatments, and vaccinations to monitor the health of plants and animals.",
    //   },
    //   {
    //     img: img3,
    //     title: "Compliance Management",
    //     bodycopy:
    //       "Ensure compliance with regulations related to product safety, labeling, and environmental sustainability.",
    //   },
    //   {
    //     img: img3,
    //     title: "Customer Relationship Management (CRM)",
    //     bodycopy:
    //       "Provide customers with access to product information, orders, deliveries, and address inquiries promptly.",
    //   },
    //   {
    //     img: img3,
    //     title: "Product Differentiation",
    //     bodycopy:
    //       "Highlight unique features like lab certification, sustainable farming practices, or nutritional value to attract customers.",
    //   },
      

  




    //   {
    //     img: img3,
    //     title: "Asset Information",
    //     bodycopy:
    //       "  Access detailed information about each asset, including specifications, maintenance requirements, and usage details.",
    //   },
    //   {
    //     img: img3,
    //     title: " Real-Time Location Monitoring",
    //     bodycopy:
    //       " Monitor the live location of assets in real-time, ensuring better visibility and control over their movements.",
    //   },
    //     {
    //     img: img3,
    //     title: "Environmental Monitoring",
    //     bodycopy:
    //       "  Gain insights into the environment surrounding each asset, facilitating proactive measures to optimize conditions for better performance.",
    //   },
    //  {
    //     img: img3,
    //     title: "Environmental Monitoring",
    //     bodycopy:
    //       "   Monitor environmental conditions at each site in real-time, ensuring optimal conditions for agricultural activities.",
    //   },{
    //     img: img3,
    //     title: "Issue Tracking",
    //     bodycopy:
    //       "   Track and manage issues encountered at each site, facilitating prompt resolution and minimizing disruptions to operations.",
    //   },{
    //     img: img3,
    //     title: " Site Operations",
    //     bodycopy:
    //       "   Perform various operations on sites, including updating site information, deleting outdated sites, providing feedback, and reporting issues for resolution.",
    //   },{
    //     img: img3,
    //     title: " Daily Reports",
    //     bodycopy:
    //       "  Generate daily reports summarizing site activities, performance metrics, and any notable events, facilitating data-driven decision-making.",
    //   },{
    //     img: img3,
    //     title: "Site Alarms",
    //     bodycopy:
    //       "   Set up alarms and notifications for critical events or deviations from desired conditions, allowing for timely interventions and preventive measures.",
    //   },{
    //     img: img3,
    //     title: "Group Nutrition",
    //     bodycopy:
    //       "   Manage nutrition plans for groups of sites, ensuring consistent and optimized nutrition for crops or livestock across multiple locations.",
    //   },
    //   {
    //     img: img3,
    //     title: "Site Attendance",
    //     bodycopy:
    //       "    Track attendance and activities at each site, providing insights into workforce productivity and resource utilization.",
    //   },{
    //     img: img3,
    //     title: "Farm Updates",
    //     bodycopy:
    //       "    Keep stakeholders informed with updates on farm activities, developments, and any other relevant news or announcements.",
    //   },
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
        Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
      </div>
  );
}
