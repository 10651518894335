import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "./TPN.css";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import TPNFeatures from "./TPNFeatures";
import TitleDescription from "../TitleDescription/TitleDescription";
import TPNBeneficaries from "./TPNBeneficaries";
import TPNServices from "./TPNServices";
import NetworkOperatorBenefits from "../ForNetworKOperators/NetworkOperatorBenefits";
export default function TPN() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="tpn-bg"
        bannerTitle={["GBR Network"]}
        animation="true
        "
      />
      <div className=" main-wrapper subSectionWrapper mb-0 ">
        <Container >
          <TitleDescription
            classname="mb-0 mt-0"
            title="What is GBR Network"
            description={[
              "GBR Network is network of network's where system integrators can create his own network by creating an account in GBR Network. This is a network operated and managed by GBR for their partners such as System Integrators, Device Makers and Solution Providers.",
            ]}
          />
        </Container>
      </div>
      <SolutionEcosystem title="GBR Network Architecture" type="TPN" body />
      <TPNServices/>
      <TPNFeatures />
      <div className='subSectionWrapper main-wrapper'>
           <Container className="subSectionWrapper">
          <NetworkOperatorBenefits  />
        </Container>
        </div> 
             <TPNBeneficaries/>
      <FooterComponent />
    </>
  );
}
