import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function DataHubBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Bidirectional Interface ",
      bodycopy: "Bidirectional Interface is a benefit of DataHub, enabling communication via HTTP API and Socket.IO, facilitating versatile and efficient data exchange between the platform and external systems."    },
    {
      img: img2,
      title: "Easy to use Data APIs",
      bodycopy: "Easy-to-use Data APIs are a benefit of DataHub, offering convenient access to accurate information for streamlined decision-making in agribusiness.",
    },
    {
      img: img3,
      title: "Adaptability",
      bodycopy:
        "Adaptability is a benefit of DataHub, featuring a flexible communication framework connecting growers, their machines, and partners, fostering seamless collaboration and efficient data exchange within the agricultural ecosystem.",
    },
    {
        img: img3,
        title: "Interoperatability",
        bodycopy:
          "Interoperability is a benefit of DataHub, facilitating seamless communication and compatibility between different systems and devices, enhancing efficiency and collaboration within the agricultural ecosystem.",
      },
     
  ];
  return (
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
      Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    
  );
}
