import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function TrackTraceBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Streamlined Logistics Operations  ",
      bodycopy: "Using Track & Trace services allows smooth trip management for assets and vehicles, geofencing setup, and real-time movement monitoring, enhancing logistics efficiency and control."    },
      {
        img: img1,
        title: "Provenace & Traceability  ",
        bodycopy: "Track and Trace API ensures accurate tracking of product origin, journey, and authenticity, fostering transparency and trust in supply chain operations."    },
      
  ];
  return (
    <div className="">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Benefits
      </h2>
      <Row>
        {benefits.map((e, index) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard key={index} src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
