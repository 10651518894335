import React from "react";
import GovernmentOrganizationImg from "../../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../../images/gbrHome/advisor.webp";
import InvestorImg from "../../../images/gbrHome/investor.webp";
import MachineryImg from "../../../images/gbrHome/machinery.webp";
import ExporterImg from "../../../images/gbrHome/exporter.webp";
import MSMEImg from "../../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";

import TransporterImg from "../../../images/gbrCBN/CBNImg7.webp";

export default function CommunitySection(props) {
  const exploreButton = props.exploreBtn;
  const extraData = props.extraData;
  return (
    <div className="subSectionWrapper">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
        ABN BENEFICIARIES - PARTNERS
           
        </h4>

    
      </Container>
     

      <HomeSubSection
        id="ABN-Advisory"
        className="rowReverseAbout--mod borderedCards"
        className3="image1"
        description="Consultants at GBR are essential partners, providing expertise in animal health, plant health, and machinery. Their insights ensure the well-being of livestock, optimize crop management, and maintain efficient machinery operations. Committed to excellence, these advisors play a pivotal role in GBR's holistic approach to agriculture, contributing to overall farm success.
                "
        descriptionTitle="
        Consultants"
        src={AdvisorImg}
      //   href4="https://abn.gbrapp.com/"
      //  buttoncont1="Sign In"
        description1="Agronomist | Vet | Certification agencies "
       
      />
 
      <HomeSubSection
        id="ABN-Investor"
        className3="image2"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
                Agrifood Investors"
        description="Agri Investors are key partners for GBR, investing in both Core Agribusiness and Micro-entrepreneurs. Their financial support drives growth and sustainability in the agricultural sector. Focusing on key activities and empowering Micro-entrepreneurs, Agri Investors contribute to a diverse and impactful investment portfolio, enhancing GBR's success and benefiting the broader agricultural community.
        "
        src={InvestorImg}
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In" 
        description1="Banks | NBFCs | Angel Investors | Institutional Investors "
      />
    
      <HomeSubSection
        id="ABN-Advertisement"
        className3="image3"
        className="rowReverseAbout--mod borderedCards"
        description="Agri-Input Manufacturers are crucial partners for GBR, supplying essential inputs like animal feed, supplements, and healthcare products. This collaboration ensures a reliable source of quality inputs, supporting GBR in maintaining optimal animal health and well-being. The partnership underscores the importance of a strong relationship with input manufacturers for the overall success of livestock farming.
                "
        descriptionTitle="
                Agri-Input Manufacturers"
        description1="FMC | PMC | AHSP | AIC"
        src={MachineryImg}
      //   href4="https://abn.gbrapp.com/"
      //  buttoncont1="Sign In"
      />

      {exploreButton && (
        <>
        

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards borderedCards"
            className3="image19"
            descriptionTitle="
            Machinery Providers"
            description="
            GBR is your trusted source for a diverse range of agricultural machinery. Whether you're a farmer or an farmer franchise, we supply the equipment you need to optimize your operations. Count on us to provide reliable machinery that supports your farming goals and drives efficiency.
            "
            // href4="https://abn.gbrapp.com/"
            // buttoncont1="Sign In"
            userType="Agri-producers-Machinery-Provider"
          />
         
        </>
      )}

      <HomeSubSection
        id="ABN-Tenders"
        className3="image4"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Buyers"
        description="Buyers are integral partners for GBR, forming a crucial link in the agricultural supply chain. These partners play a vital role in the success of GBR by purchasing the produced crops and livestock. The collaboration with buyers ensures a market for the farm's products, contributing to financial sustainability and fostering a mutually beneficial relationship. This partnership highlights the interconnected nature of the agricultural ecosystem, where GBR and buyers work together to meet market demands and achieve overall success."
        // description="Through GBR distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
        // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
        src={ExporterImg}
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In"
        description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands "
      />
      
      <HomeSubSection
        id="ABN-Transporters"
        className3="image5"
        className="rowReverseAbout borderedCards"
        description="GBR collaborates seamlessly with Logistic Providers through the GBR Logistics Feature. This innovative platform empowers logistics companies and their teams to efficiently manage their entire fleet, including booking details, trip scheduling, and load coordination. GBR Platform facilitates smooth interactions between logistics providers and their drivers, whether in-house or third-party, streamlining the transportation process and enhancing overall operational efficiency within the agricultural supply chain.
        "
        // description="GBR Platform provides logistics faeture. With GBR Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In"
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />


      <HomeSubSection
        id="ABN-Govt"
        className3="image6"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Government Organizations"
        description="GBR provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
        description1="Local Goverments | Central Goverments "
        src={GovernmentOrganizationImg}
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In"
      />
   


    

{exploreButton && !extraData && (<>




<HomeSubSection
  className="rowReverseAbout borderedCards borderedCards"
  className3="image24"
  description="At GBR, we've built a supportive network where renowned agri-input companies come together to provide essential solutions for farming animals and fields. This collaborative effort ensures agri-producers receive expert guidance and high-quality agri-input options, boosting the health, productivity, and sustainability of their farms.
  "
  descriptionTitle="
  Agri Input Companies"
  // href4="https://abn.gbrapp.com/"
  // buttoncont1="Sign In"
/>








<HomeSubSection
  className="rowReverseAbout--mod borderedCards borderedCards"
  className3="image29"
  description="Within the dynamic GBR community, we partner with leading processing machinery providers to offer essential solutions customized for farms. This collaborative effort ensures agri-producers gain access to expert guidance and high-quality processing machinery options, resulting in improved efficiency, productivity, and sustainability for their farming operations."
  descriptionTitle="
Processing Machinery"
  
  // href4="http://abn.gbrapp.com/"
  // buttoncont1="Sign In"
  userType="Agri Input Companies"
/>

{/* <Container className="mt-4">
  <ProcessedProducts
    type="MACHINERY"
    userType={["PMC", "FMC"]}
    cbn="true"

  />
</Container> */}


{/* <div className="mb-lg-5 mb-4">
            <JobsComponent />
          </div>
          <div className="mb-lg-5 mb-4">
            <PriceDiscovery />
          </div>
          <div className="mb-lg-5 mb-4">
            <ClubUpdates />
          </div>

          <div className="mb-lg-5 mb-4">
            <SiteWeatherManagement />
          </div> */}
{/* 
          <div id="tendersContainer" className="container-fluid">
            <h3 id="AgriAssets" className="container-title">
              {" "}
              Agri Assets
            </h3>
            <Container>
              <ProcessedProducts
                type="AGRI-ASSETS"
                userType={["FOOD-PROCESSOR"]}
                cbn="true"
              />



            </Container>
          </div> */}


          {/* <h3 class="container-title text-center">Storage</h3> */}

          <HomeSubSection
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards borderedCards"
            className3="image23"
            descriptionTitle="
            Storage Providers"
            description="Partnering with established storage providers, we offer essential storage solutions for agricultural produce and products. This collaboration ensures agri-producers have reliable access to storage facilities, facilitating the secure preservation and effective management of their goods."
          
  // href4="http://abn.gbrapp.com/"
  // buttoncont1="Sign In"
          />

      

          {/* <h3 class="container-title text-center">Contaract Maufacturers</h3> */}

         




</>)}



<HomeSubSection
            className3="insurance-company-bg"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Insurance Company"
            description="Insurance Company is a valued partner of GBR, providing essential coverage for our crops, livestock, and agricultural assets. This collaboration enhances our resilience and ensures the sustainability of our operations, allowing us to navigate uncertainties with confidence. Together, we are committed to fostering a secure and thriving environment for sustainable farming practices            . "
            description1="Agri-Asset Insurer | Supply Chain Distribution Insurer"
            src={GovernmentOrganizationImg}
            href2="auth/3/signup"
            buttoncont2="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/ComingSoon"
            buttoncont3="Explore More"
          />
{/* <HomeSubSection
            className3="image55"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Farmers"
            description="Farmers are vital partners for GBR, collaborating to cultivate crops and raise livestock sustainably. This partnership emphasizes mutual support, with GBR providing resources and expertise, and farmers contributing dedication for a successful harvest. Together, they advance agricultural practices and promote food security.
            "
            description1="Crop Farmers | Livestock Farmers | Organic Farmers | Diversified Farmers"
            src={GovernmentOrganizationImg}
            href2="auth/3/signup"
            buttoncont2="Register"
            href1="auth/3/signin"
            buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/agri-producers"
            buttoncont3="Explore More"
          /> */}


          

<HomeSubSection
            className3="technology-provider-image"
            className="rowReverseAbout borderedCards pb-0"
            descriptionTitle="
        Technology Providers"
            description="GBR is proud to partner with leading technology providers to advance our agricultural practices. This collaboration integrates state-of-the-art technologies into our farming operations, enhancing efficiency, precision, and overall productivity. The expertise and innovative solutions provided by our technology partners contribute significantly to our success. Together, GBR and our technology partners are dedicated to leveraging the latest advancements to ensure sustainable and cutting-edge practices in modern agriculture.
            "
            description1="IoT Solution Providers | CRM & ERP Providers | Irrigation Providers | Satellite Imagery provider | Device Makers"
           
  // href4="http://abn.gbrapp.com/"
  // buttoncont1="Sign In"
          />
          {/* <SiteUpdates />
        </> */}
    









<HomeSubSection
        id="ABN-Govt"
        className3="ari-bg"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
         Agriculture Research Institutions"
        description="GBR is pleased to collaborate with Agriculture Research Institutions as a valuable partner. This partnership enhances our farming practices by integrating cutting-edge research and innovations. The expertise and resources from Agriculture Research Institutions contribute to the continual improvement of our agricultural methods, fostering sustainable and efficient farming practices at GBR. Together, we strive to achieve excellence in agriculture through the synergy of practical experience and scientific advancements. "
        description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
        src={GovernmentOrganizationImg}
        
  // href4="http://abn.gbrapp.com/"
  // buttoncont1="Sign In"
      />
      
      <HomeSubSection
        id="ABN-Govt"
        className3="ari-bg"
        className1="aboutBgGrey"
        className="rowReverseAbout  borderedCards"
        descriptionTitle="
         Brands"
        description="GBR is pleased to collaborate with Agriculture Research Institutions as a valuable partner. This partnership enhances our farming practices by integrating cutting-edge research and innovations. The expertise and resources from Agriculture Research Institutions contribute to the continual improvement of our agricultural methods, fostering sustainable and efficient farming practices at GBR. Together, we strive to achieve excellence in agriculture through the synergy of practical experience and scientific advancements. "
        // description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
        src={GovernmentOrganizationImg}
        
  // href4="http://abn.gbrapp.com/"
  // buttoncont1="Sign In"
      />

<HomeSubSection
            className3="insurance-company-bg"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Institutional Lenders"
            description="Institutional lenders partnering with GBR Network support farmers by providing accessible financing for modern agricultural needs. These loans and credit lines help farmers invest in advanced technologies, high-quality seeds, and sustainable practices. By bridging the financial gap, these lenders empower farmers to enhance productivity, manage risks, and achieve financial stability, driving growth and innovation in agriculture. "
            description1="Agri-Asset Insurer | Supply Chain Distribution Insurer"
            src={GovernmentOrganizationImg}
           
  // href4="http://abn.gbrapp.com/"
  // buttoncont1="Sign In"
          />

    </div>


  );
}
