import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./OnMarketplaceSell.css";


export default function OnMarketplaceSell(){

    const navData = [{to:"/onMarketplace", link:"For Buyers"},
    {to:"/onMarketplaceSell", link:"For Sellers"},
    // {to:"/onMarketplaceRFQ", link:"RFQ"}
  ]
    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="onMarketplaceBanner text-white"
         bannerText="Start selling Organic products with GBR"
         buttonText="Sign Up"
         href="auth/3/signup"
        />
        <FooterComponent/>
        </>
    )
}