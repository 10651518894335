import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function AgroCareMarketplaceFeature() {
  const benefits = [

   
     
    {
      img: img3,
      title: "Price Comparison",
      bodycopy:
      " Compare prices of plant and animal health care products across different agrodealers within the marketplace, ensuring farmers get the best value for their purchases.",
    },
    {
        img: img1,
        title: "Supplier Integration",
        bodycopy: " Integrate with suppliers or manufacturers to streamline procurement processes, manage supplier relationships, and ensure a reliable supply chain of health care products."    },
     
        {
          img: img1,
          title: "Agro-Dealer Discovery",
          bodycopy: "Unlock the Agrocare marketplace's Agro-Dealer Discovery feature, facilitating seamless connections with a spectrum of agricultural suppliers to cater to your farming requirements."    },
          {
            img: img1,
            title: "Agro-Retailer Discovery",
            bodycopy: "Discover the Agro-Retailer Discovery feature within the Agrocare marketplace, connecting you with a network of agricultural retailers to meet your farming needs efficiently."    },
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
