import React from 'react';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
 export default function TechnologyPartnerSection(){
    return(
        <div className="subSectionWrapper mt-0">
       

          
          <HomeSubSection
        id="ABN-Advertisement"
        className3="network-operator-img"
        className="rowReverseAbout--mod borderedCards--mod"
        description="Agri-Input Manufacturers are crucial partners for GBR, supplying essential inputs like animal feed, supplements, and healthcare products. This collaboration ensures a reliable source of quality inputs, supporting GBR in maintaining optimal animal health and well-being. The partnership underscores the importance of a strong relationship with input manufacturers for the overall success of livestock farming.
                "
        descriptionTitle="
                Network Operators"
                description1="Financial Institutions | Telecom Companies | Aggregators "
        href2="auth/3/signup"
        buttoncont2="Register"
      
        href3="/network-operators"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />

<HomeSubSection
        id="ABN-Advertisement"
        className3="system-integrator-img"
        className="rowReverseAbout borderedCards--mod"
        description="GBR Network is proud to partner with Agri system integrators, forging alliances that drive innovation and efficiency in agricultural technology solutions. Together, we combine our expertise to deliver comprehensive systems tailored to the needs of modern farmers. Through collaboration with Agri system integrators, we enable seamless integration of advanced technologies, empowering farmers to optimize productivity, sustainability, and profitability in their operations.
                "
                description1="System Integrators | IoT Solution Providers | CRM Providers | ERP Providers "

        descriptionTitle="
                Agri-Solution Providers"
        href2="auth/3/signup"
        buttoncont2="Register"
      
        href3="/solution-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />

<HomeSubSection
        id="ABN-Advertisement"
        className3="device-maker-img"
        className="rowReverseAbout--mod borderedCards--mod"
        description="Intelligent device makers are valued partners of GBR Network, uniting to pioneer groundbreaking solutions that redefine connectivity and efficiency across industries. Through our collaborative efforts, we harness cutting-edge technology to develop intelligent devices that empower businesses and individuals alike. Together, we drive innovation, unlock new possibilities, and shape the future of smart technology, creating transformative experiences for our clients and users worldwide.
                "
        descriptionTitle="
                Intelligent Device Makers"
        href2="auth/3/signup"
        buttoncont2="Register"
        description1 ="Sensor Providers"
        href3="/device-makers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />
          
          <HomeSubSection
        id="ABN-Advertisement"
        className3="system-integrator-img"
        className="rowReverseAbout borderedCards--mod"
        description="GBR Network is proud to partner with Agri system integrators, forging alliances that drive innovation and efficiency in agricultural technology solutions. Together, we combine our expertise to deliver comprehensive systems tailored to the needs of modern farmers. Through collaboration with Agri system integrators, we enable seamless integration of advanced technologies, empowering farmers to optimize productivity, sustainability, and profitability in their operations.
                "
                description1="IT Companies | Managed Service Providers | Value Added Re-Sellers "

        descriptionTitle="
                System Integrators"
        href2="auth/3/signup"
        buttoncont2="Register"
      
        href3="/system-integrators"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />  
          
          


          </div>

    )

}