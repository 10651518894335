import React from 'react'

export default function ABNBenefits() {
  return (
    <ul className='benefitList mb-0'>
        <li>
            Agribusiness can export easily.
        </li>
        <li>
        Agribusiness can own their club to trade locally and find the best sourcing partners.
        </li>
        <li>
        Agribusiness can improve the yield and quality of the assets under their management.
        </li>
        <li>
        Agribusiness can trade the assets in exchange of investments.
        </li>
        <li>
        Agribusiness can browse the best machinery providers and contact them.
        </li>
        <li>
        Agribusiness can browse the best advisors to get support for preparing documentation and advice on organic certifications, government schemes and legal documentation.
        </li>
        <li>
            Can access transport of their choice.
        </li>
        <li>
            Can access tenders from the government and exporters.
        </li>
    </ul>
  )
}
