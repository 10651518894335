import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import { Row } from "react-bootstrap";

import CTA from "../CTA/CTA";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import IOBBenefits from "./IOBBenefits";
import IOBFeatures from "./IOBFeatures";
export default function IoBStack() {
  const data1 = [
    "content Server: The content server facilitates the management of content within the category, allowing users to upload various media types like images and videos. It includes features such as setting expiry dates for content, detailed descriptions, and attribution to content creators, ensuring organized and relevant information for users in the purchasing sector.",
    "DocGen & Management:  DocGen Management empowers users to effortlessly generate documents, streamlining the process with its user-friendly interface and efficient functionality. Seamlessly create a variety of documents tailored to your needs with ease and convenience.",
    "Agri Product Management: Agri Product Management facilitates the creation and sale of various agricultural products, including raw, semi-processed, and processed goods, as well as animal and plant care products.",
  ];

  const data2 = [
    "Agri Asset Management: Agri Asset Management simplifies agricultural Asset and Farm management. Easily organize and track assets by type and model, while seamlessly integrating with Farm oversight. From generating QR codes for tracking to real-time monitoring and analysis, optimize performance effortlessly. With streamlined operations and insights, Agri Asset Management boosts agricultural efficiency.   ",

    "IAM: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.    ",
    "S3: S3 is used for scalable, durable, and reliable cloud storage, allowing users to securely store and retrieve data of any size from anywhere on the web. ",
    "DB: The database (db) stores configurations, device metadata, topics, and commands, serving as a central repository for managing crucial system information and facilitating efficient data retrieval and manipulation.",
  ];
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["Agrifood Stack"]}
        bannerbodycopy="Asset Behaviour | Site Behaviour | Actors Behaviour


      "
      />
      <SolutionEcosystem title="Overview" type="Agrifood" />
      <Container>
        <Row>
          <OverviewFeatures data1={data1} data2={data2} />
        </Row>
      </Container>
      <IOBFeatures />
      <IOBBenefits />

      <Container className="subSectionWrapper">
        <CTA
          btnText="View APIs"
          href="/ComingSoon"
          heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
        />
      </Container>
      <FooterComponent />
    </>
  );
}
