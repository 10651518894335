import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";

import CTA from "../CTA/CTA";
import AgriTechStackBenefits from "./AgriTechStackBenefits";
import AgriTechStackFeatures from "./AgriTechStackFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
export default function AgriTechStack() {
  const data1 = [
    "AEP (Standard Gateway): Provide API for third pary to access the data from farms and routes to their own server for analystics and business intelligence. It receives data in asynchronous mode from SEP but invokes SEP services in synchronous mode. ",
    "Messaging Service: A messaging service in the context of cloud  gateway refers to service that enables communication and exchange of data between distributed applications, services, or components deployed in the cloud environment around cloud gateway stack. Here messaging services facilitate asynchronous communication, allowing different components of 3rd party application and clouds to communicate with each other without requiring them to be simultaneously available or connected.",
    "SEP: SEP in Cloud Gateway (Part of SDH ) enables seamless integration with third-party clouds and enterprise data centers, ensuring agnostic compatibility for efficient data exchange, enhancing system integrators' flexibility.",
  ];
  const data2 = [
    "             API: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.",

    "             IAM: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.",

    "                3rd Party Service: Third-party services refer enterprise data center hosted services  or cloud services and solutions offered by major cloud platform providers like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP) or can be party of enterrpise data center or standalone application such as ERP , MES etc.",
  ];
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["Cloud Gateway Stack"]}
        bannerbodycopy="Enable Interoperability and Integration with third party services and applications"
      />
      <SolutionEcosystem title="Overview" type="Cloud" />
      <Container>
     <OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <AgriTechStackFeatures/>
      <AgriTechStackBenefits/>
      {/* <ServiceTypes
              href="http://35.92.73.23:8081/v2/api-docs/"
              alt="Icon"
              id="agritech"
              src={systemViewImg}
              title="Agri-Tech Experience"
              
              description2="Investor App View | Grower App View | Buyer App View |
            Transporter App View | BusinessPartner App View"
              target="true"
            /> */}


<Container className="subSectionWrapper">
<CTA
      btnText="View APIs"
      href="/ComingSoon"
            heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
           
          />
          </Container>
      <FooterComponent />
    </>
  );
}
