import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
// import './NetworkOperatorAppStoreService.css';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
export default function DeviceStoreService() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="network-operator-appstore-bg"
        bannerTitle={[
          "Access to Agribusiness Device Store",
        ]}/>

<div className="main-wrapper">
        <div className="subSectionWrapper">
    <HomeSubSection
        className3="app-store-access-sevice"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Access to Agribussiness Device Store"
        description="Access to Device Store is a specialized service offered by GBR Network to its partners. Through this service, partners gain access to a comprehensive inventory of devices essential for their operations. This includes a wide range of technological devices and equipment tailored to meet the specific needs of their business. By leveraging the Device Store service, partners can efficiently procure the necessary tools and resources, enabling them to enhance their capabilities and optimize their performance in various sectors and industries.
        "
        href5="https://partner.gbrfarming.in/register.html?src=registerReq"
        buttoncont2="Register"
        buttoncont1="Explore"
        href1="/ComingSoon"
       
      />
      </div>
      </div>
    <FooterComponent/>
    
    </>  )
}
