import React from "react";
import Security from "../../images/blockchain-icons/security.svg";
import Transparency from "../../images/blockchain-icons/transparency.svg";
import Efficency from "../../images/blockchain-icons/efficiency.svg";
import Automation from "../../images/blockchain-icons/automation.svg";
import Decentralization from "../../images/blockchain-icons/decentralization.svg";
import FasterSettelementTime from "../../images/blockchain-icons/settelement-time.svg";


import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function BlockChainBenefits() {
  const benefits = [
    {
      img: Security,
      title: "Enhanced security",
      bodycopy: "Blockchain technology ensures security with immutable records, cryptographic methods, and decentralized consensus, protecting data and transactions from unauthorized access and tampering."    },
    {
      img: Transparency,
      title: "Enhanced Transparency     ",
      bodycopy: "     Blockchain offers enhanced transparency through its shared, immutable ledger, fostering trust among network participants with clear and auditable transaction histories.",
    },
    {
      img: Efficency,
      title: "Increased Efficiency and Speed ",
      bodycopy:
        "Blockchain enhances efficiency and speed by automating processes, reducing intermediaries, and enabling near-instantaneous transactions across decentralized networks.  ",
    },
    {
        img: Automation,
        title: "Automation ",
        bodycopy:
          "Blockchain enables automation by executing predefined smart contracts and transactions without the need for intermediaries, streamlining processes and reducing manual intervention.",
      },

      {
        img: Decentralization,
        title: "Decentralization ",
        bodycopy:
          "Blockchain's decentralization offers a key advantage by distributing data across a network of nodes, eliminating single points of failure and preventing any one entity from controlling the entire system.",
      },

      {
        img: FasterSettelementTime,
        title: "Faster settlement times",
        bodycopy:
          "Blockchain's removal of intermediaries leads to faster settlement times, crucial in time-sensitive industries like finance. Settlements can occur within minutes on blockchain, as opposed to the days or weeks traditional systems may require.",
      },
      
  ];
  return (
   
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
        Benefits
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
  );
}
