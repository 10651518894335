import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import AgroCareMarketplaceFeature from "./AgroCareMarketplaceFeature";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import AgroCareMarketplaceBenefits from "./AgroCareMarketplaceBenefits";

export default function AgroCareMarketPlace() {
  const data1 = [
    "Buy and Sell Service: Agrocare Marketplace offers a platform for buying and selling animal care, plant care, and farm equipment products, catering to diverse agricultural needs.",
  ]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["AgroCare Marketplace"]}
        
      />
      <SolutionEcosystem title="Overview" type="AGM" />
      <Container>
      <OverviewFeatures data1={data1}/>

      </Container>
      <AgroCareMarketplaceFeature/>
      <AgroCareMarketplaceBenefits/>
    
      <FooterComponent />
    </>
  );
}
