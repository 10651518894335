import React, { Component } from "react";
import "./Club.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ClubDetails from "./clubDetailsModal/ClubDetails";
import ClubMainPage from "./ClubMainPage";

class Club extends Component {
  constructor() {
    super();
    this.state = {
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      joinwithus: [],
      offset: 0,
      clubsPageCount: 1,
      perPage: 6,
      currentPage: 0,
      userTypes: ["FOOD-PROCESSOR", "AssetDeveloper"],
      getShow: false,
      showModal: false,
      clubname: "",
      userInfoData: [],
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.joinClubValues(this.state.userTypes[i]);
    };
  };

  joinClubValues(userTypes) {
    fetch(
      `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${userTypes}`,
      // `${window.appConfig.allConsumerUsreswithAssetType}${window.appConfig.Relam}${window.appConfig.Relam}${userTypes}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState((prevState) => ({
          joinwithus: [...prevState.joinwithus, res.users].flatMap(
            (num) => num
          ),
          clubsPageCount: Math.ceil(
            [...prevState.joinwithus, res.users].flatMap((num) => num).length /
              this.state.perPage
          ),
        }));
      })
      .catch((err) => console.log("err", err));
  };

  loginHandler(username) {
    localStorage.setItem("clubusername", username);
  };

  /************* Pagination *****************/
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };
  /************* EOD Pagination *****************/
  /************* View Details modal *****************/
  // modalHandler = () =>{
  //   this.setState({showModal:true});
  //   console.log("jvvvvvvvvvvvvvvv")
  // }

  openModal = (adminName) => {
    this.userInfo(adminName);
  };

  userInfo(adminName){
    fetch(
      `${window.appConfig.userInfo}${window.appConfig.Relam}${adminName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          userInfoData : res.requestInfo,
          showModal: true,
        });
      })
      .catch((err) => {
        console.log("err", err)
      });
  };

  closeModal = () => this.setState({ showModal: false });

  render() {
    // const { joinwithus } = this.state;
    // const clubsList = joinwithus.slice(
    //   this.state.offset,
    //   this.state.offset + this.state.perPage
    // );

    return (
      <>
        <ClubDetails 
          show={this.state.showModal} 
          onHide={this.closeModal}
          userInfo={this.state.userInfoData}
        />
        <HeaderComponent />
      <div className="club-container-wrapper">
<ClubMainPage/>
      </div>
        <FooterComponent />
      </>
    );
  }
}

export default Club;
