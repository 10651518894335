import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function TradeHubFeatures() {
  const benefits = [
    {
      img: img1,
      title: "Contract Manufacturing ",
      bodycopy: "contract manufacturing allows buyers to submit RFQ (Request for Quotation) and order requests to sellers for processed or semi-processed products, optionally specifying a time frame for delivery."    },
    {
      img: img2,
      title: "Contract Farming",
      bodycopy: "Contract Farming feature allows buyers to submit RFQ (Request for Quotation) and order requests to sellers weather it can be  assets or only raw products, optionally specifying a time frame for delivery.",
    },
    {
      img: img3,
      title: "Retail Order Management",
      bodycopy:
        "Buyers can purchase any type of agricultural product, such as raw or processed or semi-processed, and send a request to the seller.",
    },
    {
        img: img3,
        title: "Agri Asset Management",
        bodycopy:
          "With the help of Agri Asset Investment, investors may see available white-label assets and start making investments in them, giving them the chance to diversify their holdings within the agricultural industry.          ",
      },
      {
        img: img3,
        title: "Logistic management",
        bodycopy:
          "It enables users to request transportation services from logistic providers, facilitating efficient handling of goods and materials.",
      },

      {
        img: img3,
        title: "Storage management",
        bodycopy:
          "It enables users to request storage services from storage providers, facilitating efficient storage of goods and materials.",
      },
      {
        img: img3,
        title: " Beckn API",
        bodycopy:
          "TradeHub backend network based api implemented to achieve decentralized and P2P architecture. This is done for wider interoperability among the agrifood ecosysten players.",
      },
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
