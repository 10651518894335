import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";
export default function AgriminerAppFeatures() {
  const benefits = [
    {
      img: img2,
      title: "Onboarding Services",
      bodycopy:
      "Onboarding Services manage account creation requests on behalf of offline users by sending them to their parent entity for approval and processing.",
    },
    {
      img: img3,
      title: " Doc Verification Services",
      bodycopy:
      "Outsourced Doc Verification Services facilitate the uploading of KYC documents and signed agreements by offline users, ensuring compliance and documentation completion."    },
   
      {
        img: img1,
        title: "Site Verification Services",
        bodycopy:
        "Outsourced Node Management Services streamline the process of adding nodes to a user's account after its creation, ensuring efficient management and organization of user accounts.",
      },

      {
        img: img1,
        title: "Wallet",
        bodycopy: "The Wallet feature enables users to view their wallet balance and withdraw funds to their linked bank account, providing convenient access to manage their finances within the system."

      }
  ];
  return (
    <div className="main-wrapper">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">Features</h2>
        <Row>
          {benefits.map((e,index) => {
            return (
              <Col md={4} key={index} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
