import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AWS from "../../images/third-party-infrastructure/aws.svg";
import Decentro from "../../images/third-party-infrastructure/decentro.svg";
import RazorPay from "../../images/third-party-infrastructure/razor-pay.svg";
import ONDC from "../../images/third-party-infrastructure/ondc.svg";
import "./ThirdPartyInfrastructure.css"
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";

export default function ThirdPartyInfrastructure() {
  const apps = [
    {
        image: AWS,
        title: "AWS",
        alt : "Icon"
      },
    {
    
      image: Decentro,
      title: "Decentro",
      alt : "Icon"

    },
    {
      image: RazorPay,
      title: "razorpay",
      alt : "Icon"

    }, {
      image: ONDC,
      title: "ONDC",
      alt : "Icon"

    },
   
    
  ];

  return (
    <><HeaderComponent/>
    <div maxwidth={12} className="businessPartnerContainer infrastructure-background">
      <Container maxWidth="sm" className="gridSection ">
        <Grid container={true} alignItems="center" spacing={1} direction="row">
          {apps.map((e) => {
            return (
              <Grid
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={3}
                sm={4}
                xs={6}
              >
                {/* <NavLink to={e.href}> */}
                  <img src={e.image} alt={e.alt} />
                  <h4>{e.title}</h4>
                {/* </NavLink> */}
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
    <FooterComponent/></>
  );
}
