import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Icon1 from "../../images/app2.png";
import Icon2 from "../../images/app6.png";
import Icon3 from "../../images/app4.png";

import Icon8 from "../../images/talking.svg";


import { NavLink } from "react-router-dom";

export default function ExploreTechnologyBanner() {
  const apps = [
    {
      href: "/dtn-app",
      image: Icon2,
      title: "DTN App",
    },
    {
      href: "/abn-app",
      image: Icon1,
      title: "ABN App",
    }, {
      href: "/cbn-app",
      image: Icon8,
      title: "CBN App",
    },
    {
      href: "/agriminer-app",
      image: Icon3,
      title: "AgriMiner App",
    },
    
  ];

  return (
    <div maxwidth={12} className="businessPartnerContainer agristackContainer">
      <Container maxWidth="sm" className="gridSection ">
        <Grid container={true} alignItems="center" spacing={1} direction="row">
          {apps.map((e,index) => {
            return (
              <Grid
              key={index}
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={3}
                sm={4}
                xs={6}
              >
                <NavLink to={e.href}>
                  <img src={e.image} alt="ecoSystem & Mobile Apps View " />
                  <h4>{e.title}</h4>
                </NavLink>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
