import React from 'react';
import { Row , Col} from 'react-bootstrap';
import "./OverviewFeatures.css"
export default function OverviewFeatures({data1, data2}) {
  return (
    <>
    
    <Row className='mb-5 justify-content-center overview-features'>
     {data1 &&   <Col md={6}>
        <ul>
            {data1.map((e, i) =>{
                return <li key={i}>{e}</li>
            })}
        </ul>
    </Col>}
{data2 && <Col md={6}>
<ul>
            {data2.map((e, i) =>{
                return <li key={i}>{e}</li>
            })}
        </ul>

</Col>}
    </Row></>
  )
}
