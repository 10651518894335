import React from 'react';
import { Container } from 'react-bootstrap';
import "./AgribusinessStack.css"

export default function AgribusinessStack({level1, level2, level3, classname}) {
  return (
    <>
    <div className={`${classname} agribusiness-stack`}>
        <Container className= "deployment-container text-center text-white d-flex align-items-center flex-column ">
        <div>{level1}</div>
        <div>{level2}</div>
        <div>{level3}</div>


        </Container>
    </div>
    </>
  )
}
