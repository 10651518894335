import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

const commonProps = {
  className1: "aboutBgGrey",
};

const ffSection = (
  <HomeSubSection
  buttoncont1="Request for Demo"
  demoTrue = "true"
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport2"
    descriptionTitle="Create & manage own farmer community by using CBO solution"
    description="GBR empowers Agri-entrepreneurs to establish and nurture their unique farmer communities, fostering local trade and collaboration. Through GBR, agri-entrepreneurs can connect with fellow agri-producers, share valuable insights, and facilitate seamless transactions, ultimately revitalizing the local agricultural ecosystem while promoting sustainable growth."
  />
);

const ffSection1 = (
  <HomeSubSection
  buttoncont1="Request for Demo"
  demoTrue = "true"
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport2"
    descriptionTitle="Create & manage own Agri-Retailer network by using ADN Solution"
    description="GBR's ADN Solution empowers Agri-retailers to create and manage their own Agri-Retailer network, facilitating significant growth opportunities. This innovative service streamlines operations, enhances connectivity, and maximizes efficiency within the agricultural sector, driving success and profitability for agri-retailers."
  />
);

const growerSection = (
  <HomeSubSection
  buttoncont1="Request for Demo"
  demoTrue = "true"
    {...commonProps}
    className="rowReverseAbout--mod borderedCards borderedCards--mod"
    className3="processingSupport4"
    descriptionTitle="Contract Farming by using OFC account"
    description="GBR offers a unique service that facilitates agri-entrepreneurs in engaging in contract farming through the convenience of their OFC (Organic farming company) accounts. This streamlined platform not only simplifies the process of managing contractual agreements but also provides a centralized hub for entrepreneurs to efficiently oversee and optimize their agricultural operations."
  />
);

const PMSection = (
  <HomeSubSection
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="processingSupport3"
    description="GBR facilitates production managers in establishing their own brand by offering a Food Processor Account as part of their services. This comprehensive solution empowers managers to oversee the entire production cycle, from cultivation to processing, fostering brand autonomy and ensuring high-quality, market-ready agricultural products.
"
    descriptionTitle="
Brand (Food-Processor)"
  />
);



const LPSection = (
  <HomeSubSection
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="LPaccount"
//     description="Utilizing a Logistic Provider account as a business growth service, agri-transporters can leverage GBR Farming to enhance their operations. This account offers a specialized platform connecting logistics providers with agricultural stakeholders, fostering efficient communication and resource allocation. Agri-transporters can optimize their routes, collaborate with farmers, and expand their network through this service, contributing to increased business growth. GBR Farming's Logistic Provider account serves as a valuable tool for agri-transporters, enabling them to elevate their services and cultivate growth opportunities within the agricultural supply chain.
// "
escription="Agri-transporters can boost their business growth with GBR's Logistic Provider account a specialized platform connecting them with agricultural stakeholders. This tool streamlines communication, optimizes routes, and facilitates collaboration with agri-business, enhancing resource allocation. By leveraging this service, agri-transporters can expand their networks and elevate their services within the global agricultural supply chain, fostering increased business growth.
"
    descriptionTitle="
Logistic provider by using Logistic provider account"
  />
);


  const CollectivesSection = (
    <HomeSubSection
    {...commonProps}
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="LPaccount"
  //     description="Utilizing a Logistic Provider account as a business growth service, agri-transporters can leverage GBR Farming to enhance their operations. This account offers a specialized platform connecting logistics providers with agricultural stakeholders, fostering efficient communication and resource allocation. Agri-transporters can optimize their routes, collaborate with farmers, and expand their network through this service, contributing to increased business growth. GBR Farming's Logistic Provider account serves as a valuable tool for agri-transporters, enabling them to elevate their services and cultivate growth opportunities within the agricultural supply chain.
  // "
  description="'Create & manage own CBN network' is a cutting-edge business growth solution provided by GBR exclusively for collectives. With this innovative service, collectives can establish and oversee their own Community Buying Network (CBN), empowering them to forge direct connections with local farmers for seamless trade. Through GBR's platform, collectives gain the tools and support needed to efficiently manage their network, facilitating local trade and fostering mutually beneficial relationships within their community.
  "
    descriptionTitle="
    Create & manage own CBN network"
  />
)
export default function ProcessingSubsection(props) {

  return (
    <div className="subSectionWrapper" id="FieldSupport">
      <h2 className="container-title text-center">Business Growth Service</h2>
      {props.customPropValue === "Grower" ? growerSection : null}
      {props.customPropValue === "FF" ? ffSection : null}
      {props.customPropValue === "PM" && PMSection}
      {props.customPropValue === "FF1" ? ffSection1 : null}
      {props.customPropValue === "LP" ? LPSection : null}
      {props.customPropValue === "Collectives" ? CollectivesSection : null}

    </div>
  );
}
