import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function DeviceHubBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Simplify Device-to-Cloud",
      bodycopy: "Network modules, OS Link SDK, and edge gateways allow you to easily connect devices to the cloud, and then remotely manage and control IoT devices from the cloud.      ."    },
    {
      img: img2,
      title: "Stable Performance      ",
      bodycopy: "      Provide a 99.9% availability and auto-scaling to support several hundred million devices and handle tens of billions of requests daily.",
    },
    {
      img: img3,
      title: "Secure and Reliable Communication ",
      bodycopy:
        "Implement strict security measures like device certificates and one-key-per-device authentication to prevent illegal access. The TLS protocol creates secure data communication channels.        ",
    },
    {
        img: img3,
        title: "Adaptable to 3rd party IoT system",
        bodycopy:
          "Adaptability to 3rd party IoT systems is a benefit of DeviceHub, ensuring seamless integration with diverse IoT platforms, devices, and protocols, enhancing interoperability and flexibility within the IoT ecosystem.",
      },
      
  ];
  return (
    <div className="">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
        Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
