import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function SDHFeatures() {
  const benefits = [
    {
      img: img1,
      title: "DEP",
      bodycopy: "DEP is a feature offered by SDH to system integrators, facilitates seamless integration and management of various devices, enhancing operational efficiency and scalability."    },
    {
      img: img2,
      title: "SEP",
      bodycopy: "SEP in SDH enables seamless integration with third-party clouds and enterprise data centers, ensuring agnostic compatibility for efficient data exchange, enhancing system integrators' flexibility.",
    },
    {
      img: img3,
      title: "AEP",
      bodycopy:
        "Provide API for third pary to access the data from farms and routes to their own server for analystics and business intelligence.",
    },
    {
        img: img3,
        title: "Interoperatability Enabler",
        bodycopy:
          "Bridge services can  be developed to achieve interoperability across many different vendor sub-system or machinery.",
      },
      {
        img: img3,
        title: "Security",
        bodycopy:
          "Security Gateway (SG) ensures secure communication channels from farms to FMIS, enhancing data protection and confidentiality in agricultural operations.",
      },

      {
        img: img3,
        title: "Farm Insight",
        bodycopy:
          "Farm Insight, a feature within SDH, empowers system integrators to develop AI/ML applications tailored for agribusiness, enabling data-driven decision-making and optimization of agricultural operations.",
      },
     
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
         SDH Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
