import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
// import CTA from "../CTA/CTA";
// import MoneyMakingAssets from "../ForFarmers/MoneyMakingAssets";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
// import GrowersContribution from "../ForFarmers/GrowersContribution";
import AgribusinessStack from "../AgribusinessStack/AgribusinessStack";
export default function ForAgriEntreprenuer() {
  return (
    <>
      <HeaderComponent />
     
         
         <AgribusinessStack
        classname="agriEntrepreneur-bg"
        level1="System Integrator"
        level2="Agribusiness"
        level3="Agri-Contract Manufacturer"
      />
      <SolutionEcosystem
            title="DTN Deployment for Agri-Contract Manufacturer"

        type="CM"
        bodycopy="CMN is a type of DTN network where agri-Contract Manufacturer can join to monetize unutilized manufacturing capacity."
      />
   
     
      <FooterComponent />
    </>
  );
}
