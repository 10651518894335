import React from "react";
import NetworkBanner from "../WebSite/NetworkBanner/NetworkBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import CommunitySection from "../WebSite/CommunitySection/CommunitySection";
import ABNServices from "../OurServices/ABNServices";
import AgribusinessMainSection from "../AgribusinessMain/AgribusinessMainSection"
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import ABN from "../../images/ABN-network.gif";
import ABNBenefits from "../WebSite/Benefits/ABNBenefits";
import ABNNetworkOperator from "../ABNNetworkOperator/ABNNetworkOperator";
import ABNMainServices from "./ABNMainServices";
import NetworkOperatorPartnerBenefits from "../ForNetworKOperators/NetworkOperatorPartnerBenefits";
import Container from "@material-ui/core/Container";
export default function ABNComponent() {
  return (
    <>
      <HeaderComponent />
      <NetworkBanner bannerTitle="Agribusiness Network is to create a Digital Trust among agribusiness " bodycopy="Digital Trust | Digital Transformation | Build your own Community" className="abnbg" />
     
      <div className="mt-5 mb-5 ">
      <h4 className="container-title text-center mx-md-4 ">
      Overview          </h4>        <HomeSubSection
          fontBold="true"
          className="rowReverseAbout align-items-center"
          listItems={<ABNBenefits/>}
   
      description="ABN is a Blockchain enabled online community for agribusiness to maximize profit. Agribusiness can join agri business network to access following benefits"
        
          // descriptionTitle="Agri Business Network (ABN)  & Benefits
          // "
          src={ABN}
          // href1="/ABN"
          // buttoncont1="Explore Ecosystem for agribusiness"
          grid="true"
          btn2= "true"

        />
      </div>
      <div>
        <ABNMainServices/>
      </div>
      <div className="">

      <ABNServices/>
</div>

<div className='main-wrapper pb-0 '>
           <Container className="subSectionWrapper">
           <NetworkOperatorPartnerBenefits title="Benefits"/>
        </Container>
        </div>
      <div className="">
        <div className="subSectionWrapper">
          <h4 className="container-title text-center mx-md-4 ">
          ABN BENEFICIARIES - CORE AGRIBUSINESS
          </h4>
          <AgribusinessMainSection />
        </div>
      </div>


      <div id="ExploreCommunity" className="main-wrapper">
        <CommunitySection exploreBtn="true" />
      </div>
     

      <ABNNetworkOperator/>
      <FooterComponent />
    </>
  );
}
