import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import Icon4 from "../../images/market-positioning (1).png";
import Icon5 from "../../images/socialmarketW.svg";
import Icon6 from "../../images/retail (1).svg";
import Icon7 from "../../images/farming.svg";


import { NavLink } from "react-router-dom";

export default function ExploreMarketplaceBanner() {
  const apps = [
  
    {
      href: "/onmarketplace-app",
      image: Icon4,
      title: "onMarketplace",
    },

    {
      href: "/social-onmarketplace",
      image: Icon5,
      title: "Social onMarketplace",
    },
    {
      href: "/social-iaamarketplace",
      image: Icon6,
      title: "Social iaaMarketplace",
    },
    {
      href: "/agrocare-marketplace",
      image: Icon7,
      title: "Agrocare Marketplace",
    },
    {
      href: "/device-marketplace",
      image: Icon6,
      title: "pSolution Marketplace",
    },
  ];

  return (
    <div maxwidth={12} className="businessPartnerContainer explore-marketplace-bg">
      <Container maxWidth="sm" className="gridSection ">
        <Grid container={true} alignItems="center" spacing={1} direction="row">
          {apps.map((e) => {
            return (
              <Grid
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to={e.href}>
                  <img src={e.image} alt="ecoSystem & Mobile Apps View " />
                  <h4>{e.title}</h4>
                </NavLink>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
