import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import TechnologyPartnerSection from "./TechnologyPartnerSection";
import './TechnologyPartners.css';
export default function TechnologyPartners() {
  return (
    <>
      <HeaderComponent />
      {/* <UpdatesBanner
        className="partnerbg" bannerText={["Technology Partners"]}
        /> */}

      {/* <NetworkBanner /> */}
      {/* <div className="main-wrapper"> */}
        <TechnologyPartnerSection/>
      {/* </div>
      <FooterComponent /> */}
    </>
  );
}
