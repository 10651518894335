import React from "react";
import CommunitySection from "../WebSite/CommunitySection/CommunitySection";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function CommunityPartners() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="partnerbg" bannerText={["GBR Partners who helps Agri-bussiness", <br className="d-lg-block"></br>, "and Micro-entrepreneurs (Agri-MSMEs)"]}
        />

      {/* <NetworkBanner /> */}
      <div className="main-wrapper">
        <CommunitySection  extraData = "true"/>
      </div>
      <FooterComponent />
    </>
  );
}
