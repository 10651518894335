import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Network_Operator_-_GBR_Network_-__Challenges_-_26-03-2024_C-removebg-preview.png";
// import production from "../../../images/challangesImg/production (2).svg";
import Ecosystem from "../../images/service.svg";
import Marketing from "../../images/Technological Disruption.svg";


export default function NetworkOperatorChallenges() {
  const challanges =[{
    title:"Interoperability",
    description:"Agro-industry involves a wide range of legacy systems that needs to be integrated and make interoperable with the latest smart technologies to enable the digital transformation. Partners must need innovate framework to adapt and collect data from different equipments , farms , factories and software systems to provide value to agribusiness.",
    image:Ecosystem
  },
  {
    title:"Technological Disruption ",
    description:"Rapid advancements in technology, such as 5G, Internet of Things (IoT), artificial intelligence (AI), Blockchain and edge computing, are reshaping the agro-food industry. Partners must adapt to these technological changes and invest in upgrading their networks and services to remain competitive in agro-food industry.",
    image: Marketing
  },


]
  return (
    <Container className="challenge-container subSectionWrapper">
      <h2 className="container-title mb-md-5 text-center">
        {" "}
        Challenges
      </h2>

      <Row className="align-items-center">
        <Col md={4}>
          <img src={challangeImg} alt="System-Integrator-Challenges" className="mb-md-0 mb-4" />
        </Col>

        <Col md={8}>
         

          <Row className="challangesRow justify-content-between ">
            {challanges.map((e,index)=>{
              return <Col key={index} md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={e.image} className="mb-3" alt="Icon" />
                <h6 className="mb-2">{e.title}</h6>
                <p>
                 {e.description}
                </p>
              </div>
            </Col>
           
            })}
         
          {/* <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards  mb-md-4 mb-3 ">
                <img src={Finance} className="mb-3" />
                <h6 className="mb-2">Extra Source of Income</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
           

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-4 mb-3 ">
                <img src={Ecosystem} className="mb-3" />
                <h6 className="mb-2">Ecosystem</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </Col>
            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Marketing} className="mb-3" />
                <h6 className="mb-2">Marketing</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been
                </p>
              </div>
            </Col>

            <Col md={6} sm={6} xs={10} className="d-flex">
              <div className="challangeCards mb-md-0 mb-3">
                <img src={Brand} className="mb-3" />
                <h6 className="mb-2">Brand</h6>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum
                </p>
              </div>
            </Col> */}
          
          </Row>
   
        </Col>
      </Row>
    </Container>
  );
}
