import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import CTA from "../CTA/CTA";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import DataHubFeature from "./DataHubFeature";
import DataHubBenefits from "./DataHubBenefits";
export default function DataHubStack() {
  const data1 = [
"              Data Hub Gateway API: The Data Hub Gateway API validates tokens and forwards requests to the appropriate services, ensuring secure and efficient communication within the Data Hub ecosystem.",
"                Core Device Hub: The Core Device Hub facilitates data transfer between devices and services based on specified topics, serving as a central component for seamless communication and coordination within the system.",
"                IoT Data Pipe Line: The IoT Data Pipeline stores real-time IoT device data in a database and publishes this data to a Socket.IO server, facilitating efficient data storage and distribution for further processing and analysis.",
    "              IoB Data Pipe Line: The IoB Data Pipeline stores live IoB assets and site data in a database, then broadcasts it to a Socket.IO server for real-time access and analysis.    ",
    
   ];
 
   const data2 = [
     
     "                wether: The weather module retrieves weather information by querying an external weather API based on specified location parameters. ",
     "                Data lake: The Data Lake serves as a repository for storing data related to devices, sites, assets, and historical records, consolidating diverse datasets for comprehensive analysis and insights.     ",
     "Enviornmental Data: Environmental Data refers to data collected from devices providing insights into the surrounding conditions.",

    " Operational Data:  Operational Data includes data specific to GBR sites and assets, providing information crucial for operational management and decision-making."
   ];
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["DataHub Stack Experience"]}
        bannerbodycopy="Integrate | Connect | Manage | Operate


      "
      />
      <SolutionEcosystem title="Overview" type="DataHB" />
      <Container>
<OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <DataHubFeature/>
      <DataHubBenefits/>
      
<Container className="subSectionWrapper">
<CTA
            href4="http://54.185.221.117:8901/v2/api-docs/"
            heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
           
          />
          </Container>
      <FooterComponent />
    </>
  );
}
