import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function DeviceHubFeatures() {
  const benefits = [
    {
      img: img1,
      title: "Device Metadata Management ",
      bodycopy: "Device Metadata management, a feature provided by DeviceHub, stores and manages essential information such as device type, model, and related details, facilitating efficient organization and accessibility of device-specific data within the system."    },
    {
      img: img2,
      title: "Key Management",
      bodycopy: "Key management, a feature provided by DeviceHub, enables the creation of certificates necessary for secure connections to brokers, ensuring robust authentication and encryption mechanisms for enhanced communication security within the IoT ecosystem.",
    },
    {
      img: img3,
      title: "Rule Management",
      bodycopy:
        "Rule management, a feature provided by DeviceHub, specifies topics for devices, applications, partners, and agribusiness to connect, publish, and subscribe to the MQTT broker, enabling efficient communication and data exchange within the IoT ecosystem.",
    },
    {
        img: img3,
        title: "Control & Command Management",
        bodycopy:
          "Control & Command Management, a feature provided by DeviceHub, enables centralized control and management of connected devices, allowing users to remotely monitor, configure, and execute commands across the IoT network for streamlined operation.",
      },
      {
        img: img3,
        title: "Modular Design",
        bodycopy:
          "Modular design, a feature provided by DeviceHub, allows for flexible customization and scalability, enabling seamless integration of additional functionalities and easy adaptation to evolving IoT requirements within the agricultural sector.",
      },
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
