import React from 'react';
import HeaderComponent from '../../WebSite/Header/HeaderComponent';
import FooterComponent from '../../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../../UpdatesBanner/UpdatesBanner';
import HomeSubSection from '../../WebSite/HomeSubSection/HomeSubSection';
import "./FinancialService.css";




export default function FinancialService(props) {
  const customPropValue = props.location?.state?.customProp ;

  const commonProps = {
    className1: "aboutBgGrey",
  };
  
  const ffSection = (

    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="    GBR extends a diverse range of financial services to agri-storage providers, empowering them to optimize operations and achieve financial sustainability in the agricultural ecosystem.
  "
  
  />





  );
  
  const ffSection1 = (
   
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR  offers a specialized loan service designed to support agri-retailers, facilitating access to necessary funding. This service aims to enhance financial stability and growth opportunities within the agricultural retail sector."
  
  />
  );
  const CollectivesSection = (
   
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR  extends its expertise in financial support by providing a specialized loan service tailored for collectives. Through this service, GBR Care facilitates access to crucial funding for collectives, enabling them to pursue their agricultural ventures with confidence. Whether it's for expanding operations, investing in equipment, or enhancing infrastructure, GBR Care's loan service empowers collectives to achieve their goals and drive sustainable growth."
  
  />
  );
  const growerSection = (
    <>
    <HomeSubSection
    className3="loan-sevice"
    className1="aboutBgGrey"
    className="rowReverseAbout--mod borderedCards"
    descriptionTitle="
    Loan"
    // description="GBR facilitates loan services to agri-producers, empowering them with financial support to enhance their farming operations. Through tailored loan products and accessible lending processes, GBR assists agri-producers in securing capital for purchasing seeds, fertilizers, equipment, and other inputs necessary for their agricultural activities. By providing financial assistance, GBR contributes to the growth and sustainability of agricultural ventures, ultimately fostering the development of rural communities and promoting food security.
    // "
  description="GBR offers a comprehensive suite of financial services, including asset loans tailored for agricultural fields and processing assets. Their lending solutions empower farmers to acquire vital resources for their operations, whether expanding agricultural holdings or investing in processing equipment. With accessible lending processes and tailored loan products, GBR facilitates agri-producers' access to capital for purchasing seeds, fertilizers, equipment, and other essential inputs. This support fosters agricultural growth, sustainability, and community development while promoting food security."
   
  />


<HomeSubSection
    className3="insurance-sevice"
    className1="aboutBgGrey"
    className="rowReverseAbout borderedCards"
    descriptionTitle="
    Insurance"
    // description="GBR facilitates loan services to agri-producers, empowering them with financial support to enhance their farming operations. Through tailored loan products and accessible lending processes, GBR assists agri-producers in securing capital for purchasing seeds, fertilizers, equipment, and other inputs necessary for their agricultural activities. By providing financial assistance, GBR contributes to the growth and sustainability of agricultural ventures, ultimately fostering the development of rural communities and promoting food security.
    // "
  description="GBR  extends its services to include comprehensive insurance solutions, covering both livestock and farms. By facilitating insurance coverage for livestock and agricultural assets, GBR Care offers peace of mind to farmers, safeguarding their investments against unforeseen risks such as disease outbreaks, natural disasters, or accidents. With tailored insurance packages, GBR Care ensures that farmers have the protection they need to mitigate financial losses and sustain their operations in the face of adversity."
   
  />
  </>
  );
  
  const PMSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="GBR offers dedicated loan services to agri-contract manufacturers, providing crucial financial support for machinery acquisition and operational optimization in the agricultural industry.
  "
  
  />
  );
  







  
  const LPSection = (
    <HomeSubSection
    className="rowReverseAbout borderedCards borderedCards--mod"
    className3="fieldSupportService5"
    descriptionTitle="
  Loan"
  description="  GBR enables agri-transporters to access tailored loan services, providing essential financial support for optimizing their transportation operations within the agricultural sector.

  "
  
  />
  );
  return (
    
    <>

    <HeaderComponent/>
    <UpdatesBanner
        className="financialSupportServiceBanner"
        bannerText={["Financial Services"]}
      />
      <div className="subSectionWrapper" >
      {/* <HomeSubSection
        className="rowReverseAbout borderedCards borderedCards--mod"
        className3="fieldSupportService5"
        descriptionTitle="
      Loan"
      description="GBR Farming facilitates a comprehensive range of financial services, including asset loans for agricultural fields and processing assets. Our loan services are designed to provide essential financial support to Agri-Entrepreneur, enabling them to acquire the necessary assets for their operations. Whether you're looking to expand your agricultural holdings or invest in processing equipment, GBR Farming is here to help you secure the capital you need for a prosperous and sustainable future in agriculture."

      /> */}

{customPropValue === "farmers" && growerSection }
      {customPropValue === "storage" ? ffSection : null}
      {customPropValue === "processing" && PMSection}
      {customPropValue === "selling" ? ffSection1 : null}
      {customPropValue === "transport" ? LPSection : null}
      {customPropValue === "collectives" ? CollectivesSection : null}
      
      </div>
    <FooterComponent/>
    </>
  )
}
