import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function OTAStackFeatureStack() {
  const benefits = [
    {
      img: img1,
      title: "Application update",
      bodycopy: "Application update is a feature provided by OTA (Over-The-Air) management, facilitating remote and seamless updating of applications to ensure they remain current and optimized with the latest features and security enhancements."    },
    {
      img: img2,
      title: "Core update",
      bodycopy: "Core update is a feature provided by OTA (Over-The-Air) management, allowing for the remote and efficient updating of core system components, firmware, or software to ensure the overall stability, performance, and security of the device.",
    },
    {
      img: img3,
      title: "configuration update",
      bodycopy:
        "Configuration update is a feature provided by OTA (Over-The-Air) management, enabling remote modification and adjustment of device settings, parameters, or configurations to optimize performance, functionality, and compatibility without the need for physical intervention.",
    },

     
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
