import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "./SDH.css";
import SDHBenefits from "./SDHBenefits";
import SDHFeatures from "./SDHFeatures";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import { Row,Col } from "react-bootstrap";
export default function SDH() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["SolutionDevice Hub"]}
        bannerbodycopy="Drive digital connectivity and Farm Insights
        "
      />
   
      <SolutionEcosystem title="Overview" type="SDH" />
      <Container>
        <Row>
            <Col md ={6}>
                <ul>
                    <li>
                        Security Gateway : To provide secure communication channel
                    </li>
                    <li>
                        SEP (Service End Point) : To integrate with third party applications/ cloud apps
                    </li> 
                       <li>
                        DEP (Service End Point) : To integrate with third party devices/assets
                    </li>
                    <li>
                        AEP (Application End Point) : Provide APIs for third party farm solution developer
                    </li>
                    
                </ul>
            </Col>
            <Col md ={6}>

            <ul>
                    <li>
                        Device Management  : To manage devices from third party device OEM's
                    </li>
                    <li>
                        Device Hub : Enables integration and interoperability with third party hardware and software
                    </li> 
                       <li>
                        Data Hub : It act as Farm Management Information System (FMIS) to store data from different farms of differenr agribusiness                    </li>
                    {/* <li>
                        AEP (Application End Point) : Provide APIs for third party farm solution developer
                    </li> */}
                    
                </ul>
            </Col>
        </Row>
      </Container>
      <SDHFeatures />

      <SDHBenefits />

     
      <FooterComponent />
    </>
  );
}
