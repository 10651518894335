import React from "react";
import Grid from "@material-ui/core/Grid";
import prod1 from "../../images/community.svg";
import prod2 from "../../images/app3.png";
import prod4 from "../../images/app6.png";
// import { NavLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
// import "./ExploreProductBanner.css";

export default function ExoploreNetworkBanner() {
  return (
    <div>
      <div
        maxwidth={12}
        className="serviceContainer"
        id="ExploreBannerContainer ExploreBannerContainer--mod"
      >
        <div maxWidth="lg" className="gridSection gridSection--mmod">

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            direction="row"
            
          >
<Grid
              id="complementaryServices_link"
              className="hoverEffect"
              item
              md={3}
              sm={4}
              xs={6}
            >
              <NavLink to="/GBR-Network">
                <img src={prod2} alt="" />
                <h4>GBR Network</h4>
              </NavLink>
            </Grid>

            <Grid
              id="complementaryServices_link"
              className="hoverEffect"
              item
              md={3}
              sm={4}
              xs={6}
            >
              <NavLink to="/ABN">
                <img src={prod2} alt="" />
                <h4>ABN Network</h4>
              </NavLink>
            </Grid>

            <Grid
              id="complementaryServices_link"
              className="hoverEffect"
              item
              md={3}
              sm={4}
              xs={6}
            >
              <NavLink to="/CBNNetwork">
                <img src={prod1} alt="" />
                <h4>CBN Network</h4>
              </NavLink>
            </Grid>


            <Grid
              id="complementaryServices_link"
              className="hoverEffect"
              item
              md={3}
              sm={4}
              xs={6}
            >
              <NavLink to="/DTNNetwork">
                <img src={prod4} alt="" />
                <h4>DTN Network</h4>
              </NavLink>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
