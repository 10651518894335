import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";
export default function CBNAPPFeatures() {
  const benefits = [
    {
      img: img2,
      title: "Node Capabilities Services",
      bodycopy:
        "Node Capabilities allow users to manage nodes by creating, updating, and deleting them as needed within the system.",
    },
    {
      img: img3,
      title: "Node Services",
      bodycopy:
        "Node Services facilitate users to request various services based on the node's status, including buying inputs, selling, growing, loan services, advisory, storage, and arranging veterinary visits.",
    },


      {
        img: img1,
        title: "Generic Services",
        bodycopy:
        "Generic Services offer users the ability to access farm details and market prices for assets, providing valuable information for decision-making and planning purposes."
      },
  ];
  return (
    <div className="main-wrapper">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">Features</h2>
        <Row>
          {benefits.map((e,index) => {
            return (
              <Col md={4} key={index} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
