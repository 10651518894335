// import React from "react";
// import Toast from "light-toast";
// import { Container } from "@material-ui/core";
// import "react-awesome-slider/dist/styles.css";
// import "./App.css";
// import HeaderComponent from "../WebSite/Header/HeaderComponent";
// import FooterComponent from "../WebSite/Footer/FooterComponent";
// import "./App.css";
// import ChallengesSection from "./ChallengesSection/ChallengesSection";
// import SolutionEcosystem from "./SolutionEcosystem/SolutionEcosystem";

// import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
// import CTA from "../CTA/CTA";

// import ForFarmerSection3 from "../ForFarmerSection3/ForFarmerSection3";

// import "../BusinessPartner/BusinessPartner.css"
// export default class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       anchorEl: null,
//       selectedSlide: 0,
//       phoneNumber: "",
//     };
//   }
//   // componentDidMount = () => {
//   //   const id = localStorage.getItem("Log");
//   // };
//   sendAppLink() {
//     Toast.loading("Loading", () => {
//       this.handleClose.call(this, -1);
//       Toast.success("App link send to " + this.state.phoneNumber, 2000);
//     });
//     setTimeout(Toast.hide, 2000);
//   }
//   componentDidMount() {}

//   onTransitionEnd(value) {
//     if (this.state.selectedSlide === value.currentIndex) return;
//     this.setState({ selectedSlide: value.currentIndex });
//   }
//   showSliderContent = () => {
//     this.sliderContent.scrollIntoView({
//       behavior: "smooth",
//       block: "start",
//     });
//   };
//   handleChange(evt) {
//     this.setState({ [evt.target.name]: evt.target.value });
//   }

//   render() {
//     return (
//       <>
//         <HeaderComponent />
//         <UpdatesBanner
//         DlBtn ="true"
//         dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.cbnapp"
//         DownloadText="Download App"
//           className="farmUpdateBanner"
//           bannerTitle ="Experience the power of community"
//           bannerbodycopy1={[
// "Join clubs of your choice to access seamless trade opportunities and empower your farms"          ]}
//           // bannerbodycopy="Brand Recognization | Increased Income | Job Creations | Market Access | Value Addition"
//           href="/cbo-clubs"
//           buttonText="Explore Clubs"
//         />
//         <div className="main-wrapper">
//           <ChallengesSection />
//         </div>

//         <SolutionEcosystem type = "AB" bodycopy = "System and Process for farmers to join CBN Network/Club of his own choice to do the hyper-local trade with CBO and Agro-Dealers."/>
//         <div className="main-wrapper">

//         <ForFarmerSection3 />
//         </div>

//         <Container>
//           {" "}
//           {/* <AgriEntrepenurBenefits /> */}
//           <div className="subSectionWrapper">
//           <CTA
//           // secondBtn= "true"
//           // href3="/agri-business"
//           // btnText2= "Explore Agri Business"
//            data="present"
//             href="/cbo-clubs"
//             heading="Unlock hassle-free local trading opportunities by joining community clubs tailored for farmers"
//             btnText="Explore Clubs"
//           />
//         </div>
//         </Container>

//         <FooterComponent />
//       </>
//     );
//   }
// }
import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import "./App.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "./App.css";
import ChallengesSection from "./ChallengesSection/ChallengesSection";
import SolutionEcosystem from "./SolutionEcosystem/SolutionEcosystem";
// import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
// import CTA from "../CTA/CTA";
// import CBNNetwork from "./CBNNetork/CBNNetwork";
// import CBN from "../../images/gbrHome/CBN.gif";
// import HomeSubSection from "./HomeSubSection/HomeSubSection";
// import CBNBenefits from "./Benefits/CBNBenefits";
// import InvestmentROIComponent from "./InvestmentROI/InvestmentROIComponent";
// import FArmerCTATab from "../FarmerCTATab/FArmerCTATab";
// import ForFarmerSection3 from "../ForFarmerSection3/ForFarmerSection3";
// import FieldSupportServiceSection1 from "../FieldSupportService/FieldSupportServiceSection1";
// import TitleDescription from "../TitleDescription/TitleDescription";
// import AgriEntrepenurBenefits from "../AgriEntrepenurBenefits/AgriEntrepenurBenefits";
// import FarmerFranchiseJourney from "../ForFarmerFranchise/FarmerFranchiseJourney";
// import MoneyMakingAssets from "../ForFarmers/MoneyMakingAssets";
import AgribusinessStack from "../AgribusinessStack/AgribusinessStack";

import "react-awesome-slider/dist/styles.css";
import "./App.css";
import "./App.css";
import "../BusinessPartner/BusinessPartner.css";
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedSlide: 0,
      phoneNumber: "",
      customPropValue: props.customPropValue,
    };
  }

  sendAppLink() {
    Toast.loading("Loading", () => {
      this.handleClose.call(this, -1);
      Toast.success("App link send to " + this.state.phoneNumber, 2000);
    });
    setTimeout(Toast.hide, 2000);
  }
  componentDidMount() {}

  onTransitionEnd(value) {
    if (this.state.selectedSlide === value.currentIndex) return;
    this.setState({ selectedSlide: value.currentIndex });
  }
  showSliderContent = () => {
    this.sliderContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  render() {
    return (
      <>
        <HeaderComponent />
       
       <AgribusinessStack
        classname="agri-storage-entreprenur"
        level1="System Integrator"
        level2="Agribusiness"
        level3="Agri-Storage Entrepreneur"
      />

        <SolutionEcosystem
          title="DTN Deployment for Agri-Storage Entrepreneur"
          type="ASE"
          bodycopy=" ASN is a type of DTN network where agri-Storage entrepreneur can join to monetize unutilized storing capacity."
        />
       


        {/* <Container>
          {" "}
          <div className="subSectionWrapper">
            <CTA
              onClick={() => {
                localStorage.setItem("userType", "FF");
              }}
              href="/auth/3/signup"
              heading="Turn your small storage business into Agri-storage Entrepreneurship "
              btnText="Register Now"
            />
          </div>
        </Container> */}

        <FooterComponent />
      </>
    );
  }
}
