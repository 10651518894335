import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import "./InstitutionalInvestors.css";
import ClubMainPage from "../Club/ClubMainPage";
import IaaMarketNavbar from '../IaaMarketNavbar/IaaMarketNavbar';
import GBRFarmingClub from '../Club/GBRFarmingClub';
export default function InstitutionalInvestors() {
   
  const userTypes = ["AssetDeveloper"];

  return (
    
    <>
    <HeaderComponent className="noShadow"/>
    <IaaMarketNavbar/>
    <UpdatesBanner
        className="IAAMarketplaceBanner text-white"
        bannerText="Start exploring Agri-business who are looking for investment or Business loan "
    
      />

        
      <div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>Agri-Business</h2>

        <ClubMainPage type={userTypes}  explore = "true"/>
      </div>

      <div className="subSectionWrapper pb-0">
        <h2 className='container-title mb-md-5 text-center'>Agri-Entrepreneurs</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="Grower"  explore = "true"/>
      </div>   
      <div className="subSectionWrapper pb-0">
        <h2 className='container-title mb-md-5 text-center'>Agri-Storage Entrepreneurs</h2>
        <ClubMainPage type={userTypes} userTypeRole="FF"  explore = "true"/>
      </div>
      
      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Agri-food Contract Manufacturers</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="Production-Manager"  explore = "true"/>
      </div>

      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Agri-Retailers</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="FF"  explore = "true"/>
      </div>

      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Agri-Transporters</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="Driver"  explore = "true"/>
      </div>

      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Farmer Collectives</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="CBO"  explore = "true"/>
      </div>
      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Agri-Retailers</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="AgriRetailer"  explore = "true"/>
      </div>
      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Traders</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="Trader"  explore = "true"/>
      </div>
        <FooterComponent/>

    </>
  )
}
