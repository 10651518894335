import React from "react";
import Icon4 from "../../images/farmer.svg";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import "./AgriBusinessCard.css";
import { Row, Col } from "react-bootstrap";

export default function AgriBusinessCard() {
  const benefits = [
//  {
//       image: Icon4,
//       href : "/explore-farmer",
//       title: "Farmer",
//       bodycopy:
//         "Farmers are individuals who cultivate crops and raise livestock for food, fiber, and other agricultural products. They play a vital role in feeding populations and sustaining economies worldwide. Often working long hours and facing various challenges, farmers contribute significantly to global food security and rural development.",
//     },
    {
      image: Icon4,
      href : "/explore-fpo",
      title: "FPO",
      bodycopy:
        "FPOs are collective groups formed by farmers to improve their bargaining power, access resources, and market their produce together. They empower small farmers by pooling resources, sharing knowledge, and facilitating access to credit and markets, thereby enhancing livelihoods and promoting sustainable agriculture in rural areas.",
    },


    

    {
      image: Icon4,
      href : "/explore-shg",
      title: "SHG",
      bodycopy:
        "SHGs (Self-Help Groups) are community-based organizations formed by farmers to pool resources, share knowledge, and collectively engage in agricultural activities. These groups empower farmers, particularly women, by providing access to credit, training, and market linkages, thus enhancing their livelihoods and promoting socio-economic development in rural areas.",
    },

    {
      image: Icon4,
      href : "/explore-cs",
      title: "CS",
      bodycopy:
        "CS are farmer-led organizations that enable collective action to address agricultural challenges and improve economic conditions. They help farmers pool resources, access markets, and obtain inputs at lower costs, while providing essential services like credit and technical assistance, fostering rural development.",
    },


    {
      image: Icon4,
      href : "/explore-ngo",
      title: "NGO",
      bodycopy:
        "NGOs are non-profit organizations focused on supporting agricultural development and rural communities. They offer services like education, training, and technical assistance to farmers, helping them adopt sustainable practices and access markets, ultimately promoting food security and rural development.",
    },






  ];
  return (
      <div className="for-farmer-section3 subSectionWrapper pb-0">
        {/* <h2 className="title text-center mb-5 mb-lg-5 container-title">Benefits</h2> */}
        <Row>
          {benefits.map((e,index) => {
            return (
              <Col key={index} md={6} className="d-flex">
                <div className="benefitsCardContainer benefitsCardContainer--mod mb-lg-0 mb-5">
                  <img
                    src={e.image}
                    alt="icon"
                    className="mb-4 cursor-initial"
                  />

                  <h3>{e.title}</h3>
                  <p className="mb-0"> {e.bodycopy} </p>
                  <NavLink to={e.href}  className="text-white">
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-3 "
                    >
                     Explore
                    </Button>
                  </NavLink>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
  );
}
