import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";

import SocialIAAMarketplaceFeatures from "./SocialIAAMarketplaceFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import SocialIAAMarketplaceBenefits from "./SocialIAAMarketplaceBenefits";

export default function SocialIAAMarketplace() {
  const data1 = ["Farming Machinery Investment: Social IAAMarketplace provides farming machinery investment opportunities as a feature for investors, enabling them to participate in the agricultural sector by funding the acquisition and utilization of farming equipment.",
  "Processing Machinery Investment: Social IAAMarketplace offers processing machinery investment as a feature, allowing investors to participate in the agricultural sector by funding the acquisition and utilization of machinery for processing agricultural products."]
  const data2 = ["Agri Asset Investment: Agri Asset Investment is offered as a feature by  Social IAAMarketplace, enabling investors to participate in the agricultural sector by funding various agricultural assets such as land, livestock, equipment, and infrastructure."]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["Social IAAMarketplace"]}
        
      />
      <SolutionEcosystem title="Overview" type="SIM" />
      <Container>
<OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <SocialIAAMarketplaceFeatures/>
      <SocialIAAMarketplaceBenefits/>
    
      <FooterComponent />
    </>
  );
}

