import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function AgriTechStackBenefits() {
  const benefits = [
    {
        img: img1,
        title: "Accelerated Time-to-Market ",
        bodycopy: " Stack  provide a suite of tools and capabilities that streamline the application development process, allowing organizations to rapidly prototype, develop, and deploy agrifood tech applications. This accelerated time-to-market enables organizations to capitalize on market opportunities more quickly and stay ahead of competitors."    },
      {
        img: img2,
        title: "Reduced Development Costs",
        bodycopy: " By providing pre-built modules, libraries, and development frameworks, stack help reduce development costs and time-to-market for agritech  applications. Organizations can leverage existing components and tools to accelerate development and minimize custom development efforts.",
      },
      {
        img: img3,
        title: "Interoperability and Integration",
        bodycopy:
          " Stack support interoperability and integration with a wide range of devices, protocols, and systems, enabling organizations to connect and integrate disparate farms, hardware, software ,  devices and data sources. This interoperability facilitates data sharing, collaboration, and innovation across the agrifood  ecosystem.",
      },
      {
          img: img3,
          title: "Support for Digital Transformation",
          bodycopy:
            " Stack play a crucial role in supporting digital transformation initiatives by enabling organizations to leverage IoB, IoT, blockchin technologies to transform business processes, create new revenue streams, and gain a competitive advantage in the digital economy. By providing the tools, infrastructure, and capabilities needed to develop and deploy modern agrifood  applications, stack empower organizations to embrace digital innovation and drive business growth.",
        },
      
  ];
  return (
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
        Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
  );
}
