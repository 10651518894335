import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";

import CTA from "../CTA/CTA";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import BlockChainBenefits from "./BlockChainBenefits";
import BlockChainFeatures from "./BlockChainFeatures";
export default function BlockChainStack() {

  const data1 =[
    " Blockchain Proxy API: The Blockchain Proxy API acts as an intermediary, receiving and validating requests before interacting with both the blockchain client service and the Blockchain activation server to process them.",
    "Blockchain Client: The Blockchain Client is tasked with creating and managing blockchain networks, handling node management, and managing wallets. It interacts specifically with private blockchain networks for these operations.",
    "Blockchain Activation Server:The Blockchain Activation Server oversees data related to blockchain networks, nodes, and user accounts (wallets) within the GBR blockchain system, ensuring efficient management and coordination of blockchain-related resources."
  ]

  const data2 = [
    "Core Blockchain-network: A blockchain network is a decentralized, distributed ledger technology that records transactions or transaction data is stored and secured across multiple computers/Nodes, ensuring transparency and immutability. It facilitates trustless transactions without the need for intermediaries",
    "DB: The GBR blockchain system utilizes a MySQL server to store and manage data concerning blockchain networks, nodes, wallets, and machine pools within its database."
  ]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["Blockchain Transactions Experience"]}
        bannerbodycopy="Provenance | Immutability | Decentralization


      "
      />
      <SolutionEcosystem title="Overview" type="Blockchain" />
      <Container>
  <OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <BlockChainFeatures/>
      <BlockChainBenefits/>
 
      <Container className="subSectionWrapper">
<CTA
            href4="http://bc.gbrservice.com/v2/api-docs/"
            heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
           
          />
          </Container>
      <FooterComponent />
    </>
  );
}
