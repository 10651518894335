import React from 'react';


import Icon3 from "../../images/ServiceIcons/advisor.svg";
import Icon4 from "../../images/ServiceIcons/investment.svg";
import Icon5 from "../../images/ServiceIcons/government.svg";
import Icon6 from "../../images/ServiceIcons/SellOnline.svg";
import Icon7 from "../../images/ServiceIcons/Export.svg";
import OurServiceCardContainer from './OurServiceCardContainer';




export default function ABNServices() {
    const ABNServices = [
   

        {
          image: Icon3,
          title: "ABN-Advisory ",
          bodycopy:
            "         Browse the Advisors for legal organic food certificates. ",
            // href:"ABN-Advisory"
        },
     


        {
            image: Icon4,
            title: "  ABN-Investment ",
            bodycopy:
              "Facilitate finance to agribusiness and agri-producers.",
              // href:"#ABN-Investor"
          },
          {
            


            image: Icon5,
            title: " ABN-Govt ",
            bodycopy:
              "Facilitate government schemes like asset distribution to producers through CBO.",
              // href:"#ABN-Govt"
          },
         


          {
            image: Icon6,
            title: " ABN-Sell Online ",
            bodycopy:
              "Sell domestic in national market through India Mart, ONDC, or own GTN.",
            // href:"#ABN-Tender"
          },
          


          {
            image: Icon7,
            title: "ABN-Export Online ",
            bodycopy:
              "Export Internationally through 3rd party platform or through GTN.",
              // href:"#ABN-Tender"
          },


          {
            image: Icon7,
            title: "Supply Chain Management ",
            bodycopy:
              "Streamline the movement of products from production to distribution, ensuring timely delivery and reducing wastage. ",
          },
          {
              image: Icon7,
              title: "Quality Control",
              bodycopy:
                "Implement measures to ensure products meet regulatory standards and are safe for consumption or use.",
            },
      
            {
              image: Icon7,
              title: "Plant and Animal Health Monitoring",
              bodycopy:
                "Track growth metrics, diseases, treatments, and vaccinations to monitor the health of plants and animals.",
            },
            {
              image: Icon7,
              title: "Compliance Management",
              bodycopy:
                "Ensure compliance with regulations related to product safety, labeling, and environmental sustainability.",
            },
            {
              image: Icon7,
              title: "Customer Relationship Management (CRM)",
              bodycopy:
                "Provide customers with access to product information, orders, deliveries, and address inquiries promptly.",
            },
            {
              image: Icon7,
              title: "Product Differentiation",
              bodycopy:
                "Highlight unique features like lab certification, sustainable farming practices, or nutritional value to attract customers.",
            },
            
      
        
      
      
      
      
            {
              image: Icon7,
              title: "Asset Information",
              bodycopy:
                "  Access detailed information about each asset, including specifications, maintenance requirements, and usage details.",
            },
            {
              image: Icon7,
              title: " Real-Time Location Monitoring",
              bodycopy:
                " Monitor the live location of assets in real-time, ensuring better visibility and control over their movements.",
            },
              {
              image: Icon7,
              title: "Environmental Monitoring",
              bodycopy:
                "  Gain insights into the environment surrounding each asset, facilitating proactive measures to optimize conditions for better performance.",
            },
           {
              image: Icon7,
              title: "Environmental Monitoring",
              bodycopy:
                "   Monitor environmental conditions at each site in real-time, ensuring optimal conditions for agricultural activities.",
            },{
              image: Icon7,
              title: "Issue Tracking",
              bodycopy:
                "   Track and manage issues encountered at each site, facilitating prompt resolution and minimizing disruptions to operations.",
            },{
              image: Icon7,
              title: " Site Operations",
              bodycopy:
                "   Perform various operations on sites, including updating site information, deleting outdated sites, providing feedback, and reporting issues for resolution.",
            },{
              image: Icon7,
              title: " Daily Reports",
              bodycopy:
                "  Generate daily reports summarizing site activities, performance metrics, and any notable events, facilitating data-driven decision-making.",
            },{
              image: Icon7,
              title: "Site Alarms",
              bodycopy:
                "   Set up alarms and notifications for critical events or deviations from desired conditions, allowing for timely interventions and preventive measures.",
            },{
              image: Icon7,
              title: "Group Nutrition",
              bodycopy:
                "   Manage nutrition plans for groups of sites, ensuring consistent and optimized nutrition for crops or livestock across multiple locations.",
            },
            {
              image: Icon7,
              title: "Site Attendance",
              bodycopy:
                "    Track attendance and activities at each site, providing insights into workforce productivity and resource utilization.",
            },{
              image: Icon7,
              title: "Farm Updates",
              bodycopy:
                "    Keep stakeholders informed with updates on farm activities, developments, and any other relevant news or announcements.",
            },
      ];
  return (
    <OurServiceCardContainer services={ABNServices}  title=" Features"/>
  )
}


