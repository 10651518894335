import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
// import "./AgriBusiness.css"
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import { Container } from "@mui/material";

// import "./ExploreProducts.css";

export default function DeviceMarketplace() {
  const userType = ["FOOD-PROCESSOR"]


  return (
    <>
      <HeaderComponent />
      <UpdatesBanner bannerTitle="Solution Marketplace" className="ExploreBannerContainer"/>
  

      <div className="explore-product-wrapper">
        <Container>
          {/* {deviceHandler && (
            <div id="tendersContainer" className="container-fluid">
              <div className="d-flex justify-content-between align-items-baseline"><h3 id="DeviceMarketplace" className="container-title">
                {" "}
                Devices
              </h3>

           <a href="http://gbrfarming.in/PartnerPortal/register.html?src=registerReq" target="_blank" rel="noreferrer noopener"> <button className="mb-3 subcategoryButton ">Publish</button></a>  </div>
              
              <ProcessedProducts
                quote="true"
                type="DEVICE"
                userType={userType}
              />
            </div>
          )} */}

            <div id="tendersContainer" className="container-fluid">
                          <h3 id="DeviceMarketplace" className="container-title">
                {" "}
                Solutions
              </h3>

              <ProcessedProducts
                quote="true"
                type="SOLUTIONS"
                userType={userType}
              />
            </div>
   
                  </Container>

          {/* {certificatesHandler && (
            <div id="tendersContainer" className="container-fluid">
              <h3 id="Certifications" className="container-title">
                Certifications
              </h3>

    <HomeSubSection
        className3="app-store-access-sevice"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
         Certifications"
        description="GBR Network extends its expertise through device and solution certification services offered to partners, ensuring seamless integration of their devices and solutions with GBR's network. Through this service, GBR Network equips partners with the necessary processes and tools to certify their products, validating their compatibility and functionality within GBR's network ecosystem. Upon successful certification, GBR Network facilitates the publication of these certified solutions in a dedicated store, providing partners with a platform to showcase their offerings to a wider audience. This comprehensive approach not only ensures interoperability but also enhances visibility and accessibility for certified partner solutions within the GBR ecosystem."
        href5="http://gbrfarming.in/PartnerPortal/register.html?src=registerReq"
        buttoncont2="Explore Certification Tool"
        // buttoncont1="Explore"
        // href1="/ComingSoon"
       
      />
      </div>

          )} */}
      </div>
      <FooterComponent />
    </>
  );
}
