import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import CTA from "../CTA/CTA";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import OTAStackFeatureStack from "./OTAStackFeatureStack";
import OTAStackBenefit from "./OTAStackBenefit";
export default function OTAStack() {
  const data1 = [
    "OTA Proxy & Storage Proxy Gateway API: The OTA Proxy & Storage Proxy Gateway API validates tokens and routes requests to appropriate services, facilitating secure and efficient communication within the OTA and storage proxy infrastructure.",
    "    OTA Adapter Handler: The OTA Adapter Handler updates firmware devices according to predefined configurations, serving as a flexible tool for managing firmware updates across various device types and configurations.",

    "Firmware Meta Storage: Firmware Meta Storage manages and stores firmware details along with associated firmware image object links, enabling efficient organization and retrieval of firmware information within a system.",
    " Firmware Meta Notify: Firmware Meta Notify notifies users of the firmware update status, including stages such as idle, downloading, downloaded, updated, restarted, or failed, providing comprehensive feedback throughout the update process.",
    " Broker Service: The Broker Service facilitates the transfer of device firmware status across devices and services using topics, enabling seamless communication and coordination within a firmware management ecosystem."
    ]
    
    const data2 = [
      "3rd Party OEM OTA Service: The 3rd Party OEM OTA Service refers to external services integrated with devices to execute over-the-air (OTA) operations, handling firmware updates and transmitting OTA status updates back to the system.",
        "S3: S3 is utilized to store firmware image objects, providing a reliable storage solution, and subsequently generates and distributes image object links for access and retrieval as needed.",
        " IAM: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.",
        "DB: The databasestores the status of firmware details, OEM configuration details, and device notification status details, serving as a centralized repository for managing and retrieving critical information related to firmware updates and device configurations.",
    ]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["OTA Device Stack"]}
        bannerbodycopy="Investor App View | Grower App View | Buyer App View |
        Transporter App View | BusinessPartner App View"
      />
      <SolutionEcosystem title="Overview" type="OTA" />
      <Container>
       <OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <OTAStackFeatureStack/>
      <OTAStackBenefit/>
 
      <Container className="subSectionWrapper">
        <CTA
          href4="http://172.16.1.119:8910/v2/api-docs/"
          heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
        />
      </Container>
      <FooterComponent />
    </>
  );
}
