import React from "react";
import ProductDiversifiaction from "../../images/social-onmarketplace-icons/diversification.svg";
import Tracking from "../../images/social-onmarketplace-icons/tracking.svg";
import Traceability from "../../images/onMarketplace-benefits-icon/traceability-provenance.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function SocialOnMarketplaceBenefit() {
  const benefits = [

   
      {
      img: ProductDiversifiaction,
      title: "Value-Added Product Diversification",
      bodycopy: "Expand your agribusiness by diversifying into value-added products. Utilize surplus produce or explore niche markets to develop innovative food products that cater to evolving consumer preferences.",
    },

    {
        img: Tracking,
        title: "Order Tracking",
        bodycopy: "Social OnMarketplace offers timely updates on contract manufacturing and farming orders, streamlining operations for efficient tracking",
      },

      {
        img: Traceability,
        title: "Traceability and Provenance",
        bodycopy: "Social OnMarketplace ensures traceability and provenance, providing transparency in product sourcing, fostering trust among users.",
      },
    // {
    //   img: img3,
    //   title: "Peer-to-Peer Networking",
    //   bodycopy:
    //   "Investors can connect with other members of the Social IAA Marketplace community, share insights, exchange investment ideas, and collaborate on potential deals.",
    // },
    // {
    //     img: img1,
    //     title: "Easy Product Listing",
    //     bodycopy: "Provide a simple interface for sellers to add their products with detailed descriptions, images, pricing, and certification details."    },
     
  ];
  return (
    <div className="">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Benefits
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
