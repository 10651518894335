import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
// import "./AgriBusiness.css"
import ProcessedProducts from '../ExploreProducts/ProcessedProducts';
import { Container } from '@mui/material';

export default function AppMarketplace() {

const userType = ["FOOD-PROCESSOR"]
 
  return (
    <>
    <HeaderComponent />
    <UpdatesBanner 
         className="socialMarketplace text-white"
         bannerText={[" App Marketplace"]}

        /> 

<div className="explore-product-wrapper">
        <Container><div id="tendersContainer" className="container-fluid">
              <h3 id="Semi-Processed-Products" className="container-title">
                {" "}
                Devices
              </h3>
              <ProcessedProducts quote="true"  type="DEVICE"  userType =  {userType}/>
            </div>
            </Container>

    </div>
           <FooterComponent/>
    </>
  )
}
