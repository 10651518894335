import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function RegistryGatweayFeatures() {
  const benefits = [
    {
      img: img1,
      title: "Participant Registration ",
      bodycopy: "he registry allows agri-investors , sellers, buyers, brands , logistic providers ,   service providers, and other stakeholders to register and create profiles within the ABN ecosystem. This registration process collects essential information such as business details, contact information, digital identities, and authentication credentials."    },
    {
      img: img2,
      title: "Identity Verification",
      bodycopy: " The registry facilitates identity verification and authentication of participants to ensure trust and security within the ABN ecosystem. This may involve integration with digital identity verification systems and adherence to regulatory guidelines for Know Your Customer (KYC) and Anti-Money Laundering (AML) compliance.",
    },
    {
      img: img3,
      title: "Rating and Review System",
      bodycopy:
        " The registry also incorporate a rating and review system that allows buyers and /or agri-investors  to provide feedback and ratings for products, asset developers , sellers, and service providers. This user-generated content helps build trust and credibility within the ABN network and assists other buyers and investors  in making informed purchasing  and investing decisions.",
    },
    {
        img: img3,
        title: "Integration with Blockchain Network",
        bodycopy:
          " The registry integrates with blockchain infrastructure to facilitate secure and seamless  transactions and identities  within the ABN network. This integration enables  digital wallets and identity integration with correct set of public and private keys",
      },
      {
        img: img3,
        title: "Search and Discovery",
        bodycopy:
          "The registry includes search and discovery functionalities that enable buyers, brands and investors  to discover actors who provide contract farming , contract manufacturing , food processing, agri asset management services  within the ABN network.",
      },

      {
        img: img3,
        title: "Authorization Services",
        bodycopy:
          " Stack provides centralized authorization services for enforcing access controls and permissions across applications and APIs. It supports fine-grained authorization policies based on user attributes, roles, and scopes.",
      },
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
