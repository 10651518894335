import React from 'react'
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection'
export default function TPNBeneficaries() {
  return (
    <div className="network-wrapper">
    <h2 className="container-title text-center mt-4">
       BENEFICIARIES OF GBR NETWORK
    </h2>

    <HomeSubSection
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="system-integrator-img"
      description="GBR Network is proud to partner with Agri system integrators, forging alliances that drive innovation and efficiency in agricultural technology solutions. Together, we combine our expertise to deliver comprehensive systems tailored to the needs of modern farmers. Through collaboration with Agri system integrators, we enable seamless integration of advanced technologies, empowering farmers to optimize productivity, sustainability, and profitability in their operations.
      "
      descriptionTitle="
          System Integrators"
          href4="https://partner.gbrfarming.in/login.html?src=0"
          buttoncont1="Sign In"
          href5 ="https://partner.gbrfarming.in/register.html?src=registerReq"
          buttoncont2="Register"
    />



    <HomeSubSection
      className="rowReverseAbout--mod borderedCards borderedCards--mod"
   
      descriptionTitle="
          Device Makers "
          className3="device-maker-img"
          description="Intelligent device makers are valued partners of GBR Network, uniting to pioneer groundbreaking solutions that redefine connectivity and efficiency across industries. Through our collaborative efforts, we harness cutting-edge technology to develop intelligent devices that empower businesses and individuals alike. Together, we drive innovation, unlock new possibilities, and shape the future of smart technology, creating transformative experiences for our clients and users worldwide.
                  "
                  href4="https://partner.gbrfarming.in/login.html?src=0"
                  buttoncont1="Sign In"
                  href5 ="https://partner.gbrfarming.in/register.html?src=registerReq"
                  buttoncont2="Register"
    />
    <HomeSubSection
      className="rowReverseAbout borderedCards borderedCards--mod"
      className3="system-integrator-img"
      description="GBR Network is proud to partner with Agri system integrators, forging alliances that drive innovation and efficiency in agricultural technology solutions. Together, we combine our expertise to deliver comprehensive systems tailored to the needs of modern farmers. Through collaboration with Agri system integrators, we enable seamless integration of advanced technologies, empowering farmers to optimize productivity, sustainability, and profitability in their operations.
              "      descriptionTitle="
          Solution Providers"
          href4="https://partner.gbrfarming.in/login.html?src=0"
          buttoncont1="Sign In"
          href5 ="https://partner.gbrfarming.in/register.html?src=registerReq"
          buttoncont2="Register"
    />

 



</div>
  )
}
