
import React from "react";
import Community from "../../images/transparent-plastic-bottles-filled-with-yellow-substance (4).jpg";
import { NavLink } from "react-router-dom";
import Advertisment from "../../images/people-using-ads-digital-marketing-concept-online-advertisement-ad-website-social-media-customer-min.jpg";
import { Row, Col } from "react-bootstrap";

export default function ManufacturerServices() {
  const services = [
    {
      title: "Manufacturing onDemand (MoD)",
      image: Community,
      href: "/MoD",
    },
    {
         title: "Advertisement",
         image: Advertisment,
         href: "/advertisement",
       },
    // {
    //     title: "onMarketplace",
    //     image: HoneyAsset,
    //     href: "/onMarketplaceSell",
    //   },

    //   {
    //     title: "Social onMarketplace",
    //     image: FieldSupport,
    //     href: "/socialonMarketplace",
    //   },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0 pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={4}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
