import React from "react";
import { Container } from "@material-ui/core";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import "./ExplorePlantCare.css"
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default function ExplorePlantCare() {
    const userType = ["AIC"]
  return (
    <>
    <HeaderComponent/>
      <UpdatesBanner   className="explore-plant-care-bg"
    bannerText={[
      "Plant Health Care",
    ]}/>

      <Container className="mt-5 update-container-wrapper">
      <h3 class="container-title text-center mb-4">Plant Health Products</h3>

        <ProcessedProducts type="PHP" userType={userType} cbn="true" />
      </Container>

      <FooterComponent/>
    </>
  );
}
