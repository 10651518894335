
import React from "react";
import HoneyAsset from "../../images/socialmarketplace.jpg";
import App from "../../images/access-to-appstore-sevice.jpg";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

export default function SolutionProviderServices() {
  const services = [
    {
        title: "SolutionDevice Hub",
        image: App,
        href: "/device-marketplace",
      },

      {
        title: "API & Integration",
        image: HoneyAsset,
        href: "/ExploreAPIs",
      },
  ];

  return (
    <div className="container for-farmer-section2 subSectionWrapper px-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={4}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
