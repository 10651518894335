import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgribusinessStack from "../AgribusinessStack/AgribusinessStack";

export default function Collective() {
  return (
    <>
      <HeaderComponent />
      <AgribusinessStack
        classname="agriretailer-bg "
        level1="System Integrator"
        level2="Agribusiness"
        level3="Collectives"
      />

      <SolutionEcosystem
        title="CBN Deployment for Collectives"
        type="LTN"
        bodycopy="LTN is a type of CBN Network for collectives to do local trade & local distribution with farmers."
      />

      <FooterComponent />
    </>
  );
}
