import React from "react";
import { Container } from "@material-ui/core";
import ABNNET from "../../../images/Agri-Entrepreneur Infographic Desktop - GBR Tech - 24-05-2024 C.gif";
import ABNNET1 from "../../../images/Agri-Entrepreneur Infographic Desktop - GBR Tech - 24-05-2024 C.gif";
import AD from "../../../images/Agro-dealer infographic desktop 22-01-2024 C (1).gif";
import AD1 from "../../../images/Agro-Dealer infographic  mobile 22-02-2024 C.gif";
import AF from "../../../images/Agri-Food Manufacturer Desktop 23-02-2024 C .gif";
import AF1 from "../../../images/Input Manufacturer - GBR care Infographic Mobile 05-03-2024 .gif";
import CBO from "../../../images/CBO - Community based organization desktop 22-02-2024 C.gif";
import CBO1 from "../../../images/CBO - Community based organization Mobile 22-02-2024 C.gif";
import NO from "../../../images/Network Operator Infographic - GBR Network - DesktopMobile 28-03-2024 C.gif";
import SDH from "../../../images/Solution Devise Hub Infographic - GBR Tech - DesktopMobile 03-04-2024 C.gif";
import TPN from "../../../images/Technology Provider Network - GBR Tech - Desktop_Mobile 01-04-2024.gif";
import "./SolutionEcosystem.css";
import TitleDescription from "../../TitleDescription/TitleDescription";
import AgriBusinessCard from "../../AgriBusinessCard/AgriBusinessCard";
import DTN from "../../../images/dtn-app-overview.gif"
import ABN from "../../../images/ABN-Overview.gif"
import CBN from "../../../images/CBN-Overview.gif"
import AM from "../../../images/agriminer-overview.gif"
import ONM from "../../../images/onmarketplace-overview.gif"
import SM from "../../../images/social-onmarketplace-overview.gif"
import SIM from "../../../images/socialiaa-overview.gif"
import AGM from "../../../images/Agrocare-marketplac-overview.gif"
import TT from "../../../images/Track-trace-overview.png"
import DeviceHB from "../../../images/Device-hub-overview.png"
import DataHB from "../../../images/datahub-overview.png"
import OTA from "../../../images/OTA-overview.png"
import Agrifood from "../../../images/Agrifood-overview.png"
import Cloud from "../../../images/cloud-gateway.png"
import Blockchain from "../../../images/Blockchain-overview.png"
import TradeHub from "../../../images/Tradehub-overview.png"
import RegistryGateway from "../../../images/Registry-overview.png"
import AT from "../../../images/Agri-Transporter Infographic Desktop - GBR Tech - 24-05-204 C.gif";
import AT1 from "../../../images/Agri-Transporter Infographic Desktop - GBR Tech - 24-05-204 C.gif";
import ASE from "../../../images/Agri-Storage Entrepreneur Infographic Desktop - GBR Tech - 24-05-2024 C.gif";
import ASE1 from "../../../images/Agri-Storage Entrepreneur Infographic Desktop - GBR Tech - 24-05-2024 C.gif";
import CM from "../../../images/Agri Contract Manufacturers Infographic desktop - GBR Tech - 24-05-2024 C.gif";
import CM1 from "../../../images/Agri Contract Manufacturers Infographic desktop - GBR Tech - 24-05-2024 C.gif";
import OurServices from "../../OurServices/OurServices";
import AB from "../../../images/GBR Tech Farmers Infographic desktop - GBR Tech - 14-05-2024 N.gif";
import AB1 from "../../../images/GBR Tech Farmers Infographic desktop - GBR Tech - 14-05-2024 N.gif";
import FarmersServices from "../../AgriProducers/Farmer/FarmersServices";
import AR from "../../../images/Agri-Retailer Infographic desktop - GBR Tech - 16-05-2024 N (1).gif";
import AR1 from "../../../images/Agri-Retailer Infographic desktop - GBR Tech - 16-05-2024 N (1).gif";
import LTN from "../../../images/GBR Tech Collective Infographic Desktop - GBR Tech -  24-05-2024 C.gif";
import LTN1 from "../../../images/GBR Tech Collective Infographic Desktop - GBR Tech -  24-05-2024 C.gif";

export default function SolutionEcosystem({ type, bodycopy, title }) {
  return (
    <>
      <Container
        className="solutionEcosystem-container  subSectionWrapper"
        id="ExploreABN"
      >
        <h2 className="container-title text-center ">{title ? title : "Our Solution"}</h2>
{  bodycopy &&      <p className="container-para text-center mx-0 mx-md-4">{bodycopy}</p>
} 
{
          (type === "AR" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={AR}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={AR1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "LTN"   && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={LTN}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={LTN1}
                alt="Infographics"
              />
            </>
          ))
        }
        
{type === "AB" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={AB}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={AB1}
              alt="Infographics"
            />
            {/* <div className=" subSectionWrapper mb-0 ">
              <TitleDescription
                titleClass="text-start"
                classname2="text-start"
                classname="mb-0 mt-0"
                title="Who are an Agri-Producers"
                description={[
                  "Agri-producers, also known as agricultural producers or farmers, are individuals or entities engaged in the cultivation and production of crops, livestock, and other agricultural products. These individuals or organizations play a crucial role in sustaining food systems, providing essential commodities for human consumption, and contributing to the economy. Agri-producers employ various techniques and technologies to maximize yields while often striving to minimize environmental impact. They face numerous challenges such as fluctuating market prices, weather uncertainties, and evolving regulatory landscapes. Despite these challenges, agri-producers are essential contributors to global food security and rural livelihoods, with their efforts shaping the agricultural landscape and influencing broader socio-economic dynamics."
                  // 'An agri-entrepreneur can be an aspiring agri-producer  or an organization that can get into different levels of entrepreneurships in the agri-food sector by leveraging  digital  Transformation in agri-food industry. As an Agri-Entrepreneur partner of GBR farming, the aspiring agri-producer or  organizations can truly become a "Farm-to-Factory digital enterprise"',
                  // <br className="d-block pb-4"></br>,

                  // "GBR will provide digital technology and tools for Agri-Entrepreneurs to manage and operate their farms or / and other agri-producers’ farms. GBR will also facilitate the Agri entrepreneurs with financial assistance for installing micro-processing units to process the farm outputs and training to ensure the effective usage of the processing units.",
                ]}
              />

              <AgriBusinessCard />
            </div> */}
            {/* <Container> */}
            {/* <GBRFarmerService/> */}
            {/* <OurServices/> */}
            {/* <FarmersServices/> */}
              {/* <MoneyMakingAssets /> */}
            {/* </Container> */}
          </>
        )}

        {type === "ADealer" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={AD}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={AD1}
              alt="Infographics"
            />
          </>
        )}
        {type === "CBO" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={CBO}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={CBO1}
              alt="Infographics"
            />
                   <div className=" subSectionWrapper mb-0 ">
              <TitleDescription
                titleClass="text-start"
                classname2="text-start"
                classname="mb-0 mt-0"
                title="Who are Community Based Organisations
                "
                description={[
                  "Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues in farming, thereby promoting resilient and inclusive agricultural systems at the local level.                  "
                  // 'An agri-entrepreneur can be an aspiring agri-producer  or an organization that can get into different levels of entrepreneurships in the agri-food sector by leveraging  digital  Transformation in agri-food industry. As an Agri-Entrepreneur partner of GBR farming, the aspiring agri-producer or  organizations can truly become a "Farm-to-Factory digital enterprise"',
                  // <br className="d-block pb-4"></br>,

                  // "GBR will provide digital technology and tools for Agri-Entrepreneurs to manage and operate their farms or / and other agri-producers’ farms. GBR will also facilitate the Agri entrepreneurs with financial assistance for installing micro-processing units to process the farm outputs and training to ensure the effective usage of the processing units.",
                ]}
              />

              <AgriBusinessCard />
            </div>
          </>
        )}

        {type === "AF" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={AF}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={AF1}
              alt="Infographics"
            />
          </>
        )}


{type === "NO" && (
          <>
            {" "}
            <img
              className="mob-vis2 web-img cursor-initial"
              src={NO}
              alt="Infographics"
            />
            <img
              className="mob-vis2 mob-img cursor-initial"
              src={NO}
              alt="Infographics"
            />
          </>
        )}


{type === "SDH" && (
          <>
            {" "}
            <img
              className="mob-vis2 web-img cursor-initial"
              src={SDH}
              alt="Infographics"
            />
            <img
              className="mob-vis2 mob-img cursor-initial"
              src={SDH}
              alt="Infographics"
            />
          </>
        )}


{type === "TPN" && (
          <>
            {" "}
            <img
              className="mob-vis2 web-img cursor-initial"
              src={TPN}
              alt="Infographics"
            />
            <img
              className="mob-vis2 mob-img cursor-initial"
              src={TPN}
              alt="Infographics"
            />
          </>
        )}


{type === "DTN" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={DTN}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={DTN}
              alt="Infographics"
            />
          </>
        )}


{type === "ABN" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={ABN}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={ABN}
              alt="Infographics"
            />
          </>
        )}

{type === "CBN" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={CBN}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={CBN}
              alt="Infographics"
            />
          </>
        )}


{type === "AM" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={AM}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={AM}
              alt="Infographics"
            />
          </>
        )}

        
{type === "SM" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={SM}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={SM}
              alt="Infographics"
            />
          </>
        )}


{type === "SIM" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={SIM}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={SIM}
              alt="Infographics"
            />
          </>
        )}
 {type === "ONM" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={ONM}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={ONM}
              alt="Infographics"
            />
          </>
        )}

{/* 
{type === "SIM" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={SIM}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={SIM}
              alt="Infographics"
            />
          </>
        )} */}

{type === "AGM" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={AGM}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={AGM}
              alt="Infographics"
            />
          </>
        )}
        

        {type === "TT" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={TT}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={TT}
              alt="Infographics"
            />
          </>
        )} 
{type === "DeviceHB" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={DeviceHB}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={DeviceHB}
              alt="Infographics"
            />
          </>
        )} 


{type === "DataHB" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={DataHB}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={DataHB}
              alt="Infographics"
            />
          </>
        )} 


{type === "OTA" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={OTA}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={OTA}
              alt="Infographics"
            />
          </>
        )} 

{type === "Agrifood" && (
          <>
            {" "}
            <img
              className="mob-vis1 web-img cursor-initial"
              src={Agrifood}
              alt="Infographics"
            />
            <img
              className="mob-vis1 mob-img cursor-initial"
              src={Agrifood}
              alt="Infographics"
            />
          </>
        )} 

{type === "Cloud" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={Cloud}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={Cloud}
              alt="Infographics"
            />
          </>
        )} 

{type === "Blockchain" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={Blockchain}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={Blockchain}
              alt="Infographics"
            />
          </>
        )} 

{type === "TradeHub" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={TradeHub}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={TradeHub}
              alt="Infographics"
            />
          </>
        )} 


{type === "RegistryGateway" && (
          <>
            {" "}
            <img
              className="mob-vis3 web-img cursor-initial"
              src={RegistryGateway}
              alt="Infographics"
            />
            <img
              className="mob-vis3 mob-img cursor-initial"
              src={RegistryGateway}
              alt="Infographics"
            />
          </>
        )} 

{
          (type === "AT" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={AT}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={AT1}
                alt="Infographics"
              />
            </>
          ))
        }

        
{
          (type === "ASE"   && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={ASE}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={ASE1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "CM" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={CM}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={CM1}
                alt="Infographics"
              />
            </>
          ))
        }

{
          (type === "AE" && (
            <>
              {" "}
              <img
                className="mob-vis1 web-img cursor-initial"
                src={ABNNET}
                alt="Infographics"
              />
              <img
                className="mob-vis1 mob-img cursor-initial"
                src={ABNNET1}
                alt="Infographics"
              />
            </>
          ))
        }
        
      </Container>
    </>
  );
}
