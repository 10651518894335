import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";

import CTA from "../CTA/CTA";
import RegistryGatewayBenefits from "./RegistryGatewayBenefits";
import RegistryGatweayFeatures from "./RegistryGatweayFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
export default function RegistryGateway() {
  const data1 = [
    "Register Gateway: Track and Trace API offers Agri-asset Live Monitoring, enabling real-time tracking and surveillance of agricultural assets for enhanced security and management. ",
    "OTP Plug-in: Track and Trace API includes Agri-Asset Geofence, allowing users to set virtual boundaries for agricultural assets, enhancing security and monitoring capabilities.",
    "Registry (User/Solutions): Track and Trace API offers Order Tracking, providing real-time visibility into the status and location of agricultural orders for streamlined logistics management.",
    "Solution Service: Track and Trace API offers allows for the tracking and documentation of the origin, journey, and authenticity of products or assets throughout their lifecycle.",
  ];
  const data2 = [
    "Auth Backend Service: Track and Trace API includes Trip Management, allowing for efficient planning, monitoring, and optimization of agricultural transportation routes and schedules.",
    "              OpUser Service: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.",
    "             Client & User Identity Management: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.",

    "                DB: The database (db) stores configurations, device metadata, topics, and commands, serving as a central repository for managing crucial system information and facilitating efficient data retrieval and manipulation.",
  ];
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["Registry Experience"]}
        bannerbodycopy="Agribusiness Registry | Partner Registry | Solution Activation | Auth Stack | Lookup


      "
      />
      <SolutionEcosystem title="Overview" type="RegistryGateway" />
      <Container>
      <OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <RegistryGatweayFeatures />
      <RegistryGatewayBenefits />
      {/* <ServiceTypes
              href="http://35.92.73.23:8081/v2/api-docs/"
              alt="Icon"
        id="systemView"
        src={systemViewImg}
        title="DeviceHub Stack Experience"
        description2="Integrate | Connect | Manage | Operate"
        target="true"
      /> */}
      <Container className="subSectionWrapper">
        <CTA
          href4="http://35.92.73.23:8081/v2/api-docs/"
          heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
        />
      </Container>
      <FooterComponent />
    </>
  );
}
