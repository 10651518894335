import React from 'react';
import Icon3 from "../../images/ServiceIcons/veterinarian.svg";
import Icon4 from "../../images/ServiceIcons/service.svg";
import Icon5 from "../../images/ServiceIcons/ecology.svg";
import Icon6 from "../../images/ServiceIcons/supply-chain-management.svg";
import Icon1 from "../../images/ServiceIcons/bee-box.svg";
import Icon2 from "../../images/ServiceIcons/tractor (1).svg";
import OurServiceCardContainer from './OurServiceCardContainer';



export default function CBNServices() {
    const CBNServices = [
   
   
      {
        image: Icon1,
        title: " Agri-Asset Distribution ",
        bodycopy:
          "Facilitate Agri asset distribution service for producers .",
      },
     


      {
        image: Icon2,
        title: " Agri-Machinery Distribution ",
        bodycopy:
          "Facilitate machinery asset distribution service for producers.",
      },


      {
        image: Icon3,
        title: "AH/Agri-Clinic ",
        bodycopy:
          " Facilitate animal and plant care through farmer advisors and nutrition distributors. "
      },
   


      {
          image: Icon4,
          title: "GBR Care Club",
          bodycopy:
            "Create farmers club for Organic certification and local trade (buy & sell)."
        },

        {
          image: Icon5,
          title: "Organic Input",
          bodycopy:
            "Easy access for farmers to buy organic feed from the approved organic feed providers."
        },
        {
          image: Icon6,
          title: "Hyperlocal Trade",
          bodycopy:
            "Most efficient value chain service to buy from farmers and traders to create differentiated product."
        },
        {
          image: Icon6,
          title: "FFS Service",
          bodycopy:
            "CBN offers Farmer Facilitation Service (FFS) to streamline agricultural processes and support farmers, enhancing efficiency and productivity in farming practices."
        },  {
          image: Icon6,
          title: "Asset Market Services",
          bodycopy:
            "CBN delivers Asset Market Services, facilitating efficient transactions and market operations for various assets, optimizing investment opportunities and liquidity."
        }, 
        {
          image: Icon6,
          title: "Access to Advisory",
          bodycopy:
            "CBN Network provides farmers with access to advisory feature, offering expert guidance to enhance agricultural practices and productivity."
        },

      ];
  return (
    <div className=''>
    <OurServiceCardContainer services={CBNServices}  title="Features"/>
    </div>
  )
}
