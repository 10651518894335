import React from "react";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import LandingPageBanner from "../LandingPageBanner/LandingPageBanner";
import LandingPageSection from "../LandingPageSection/LandingPageSection";

export default function LandingPage() {
  return (
    <>
    <HeaderComponent />
    <LandingPageBanner />
    {/* <LandingPageSection/> */}
    <FooterComponent />
  </>
  )
}
