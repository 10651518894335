import { Container } from "@material-ui/core";
import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FoodProcessorClub from "../Club/FoodProcessorClub";
import DeviceMakerServices from "./DeviceMakerServices";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default function DeviceMakerClub() {
  const userTypes = ["AssetDeveloper"];

  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        bannerTitle="Device Makers"
        className="device-maker-bg"
      />
            <DeviceMakerServices/>
            {/* <div className='subSectionWrapper  main-wrapper'>
           <Container className="">
           <DeviceMakersBenefits/>
        </Container>
        </div> */}
      <Container className="subSectionWrapper">
        <div className=" pb-0">
          <h2 className="container-title mb-md-5 text-center">
            Agri-Solution Providers
          </h2>
          <FoodProcessorClub
            type={userTypes}
            userTypeRole="
            SystemIntegrator"
            explore="true"
          />
        </div>{" "}
      </Container>
      <FooterComponent />
    </>
  );
}
