import React from "react";
import Buy from "../../images/onMarketplace-features/buy.svg";
import Sell from "../../images/onMarketplace-features/sell.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function OnMarketplaceFeatures() {
  const benefits = [

   
      {
      img: Buy,
      title: "Buy",
      bodycopy: "Buy feature enables buyers to browse, select, and purchase organic products from the marketplace.",
    },
    {
      img: Sell,
      title: "Sell",
      bodycopy:
      "Sell feature empowers sellers to list, manage, and sell their organic products on the marketplace.",
    },
     
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row className="justify-content-center">
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
