import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function TPNServices() {
  const benefits = [
    {
      img: img1,
      title: "Aggregation Service",
      bodycopy: "GBR Network offers an Aggregation Service, bringing together different agribusinesses to work together more efficiently.",
    },
    {
      img: img2,
      title: "App Development ",
      bodycopy: "App Development is a feature offered by GBR Network, enabling businesses to create customized applications tailored to their specific needs and objectives.",
    },
   
    {
      img: img1,
      title: "Marketplace",
      bodycopy: "GBR Network's Marketplace offers a convenient platform for users to buy a range of tailored solutions to meet their needs and requirements.",
    },
    {
      img: img2,
      title: "ABN ",
      bodycopy: "ABN, offered by GBR, enables users to build, manage, and operate their own agribusiness networks, providing comprehensive support for effective agricultural operations.",
    },
    {
      img: img2,
      title: "Management of Agri-Sectors & Categories ",
      bodycopy: "GBR Network provides a service for managing agri-sectors and categories, facilitating efficient organization and optimization within the agricultural industry.",
    },
  ];
  return (
    <div className="main-wrapper">
    <div className="subSectionWrapper">
        <Container>
      <h2 className="text-center container-title mb-4">
         Services
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} className="d-flex" key={index}>
              <BenefitsCard  src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
    </div>
  );
}
