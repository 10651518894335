import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import ExploreMarketplaceBanner from "./ExploreMarketplaceBanner";
// import "./ExploreTechnology.css";

export default function ExploreMarketplace() {
  return (
    <>
      <HeaderComponent />
      <ExploreMarketplaceBanner />
      
      <FooterComponent />
    </>
  );
}
