import React from "react";
import Security from "../../images/blockchain-icons/blockchain-management.svg";
import Transparency from "../../images/blockchain-icons/node-management.svg";
import Efficency from "../../images/blockchain-icons/user-management.svg";
import Automation from "../../images/blockchain-icons/VPN.svg";



import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function BlockChainFeatures() {
  const benefits = [
    {
      img: Security,
      title: " Blockchain Network Management",
      bodycopy: "Blockchain Proxy API simplifies the creation and management of blockchain networks by providing an interface to interact with network operations via an API, abstracting away the complexities of infrastructure management."    },
    {
      img: Transparency,
      title: "Node Management     ",
      bodycopy: "    Blockchain Proxy API facilitates node management for blockchain networks, offering tools to control and monitor nodes, streamline network operations, and ensure optimal performance, all through a simplified API interface.",
    },
    {
      img: Efficency,
      title: "User Management ",
      bodycopy:
        "User management in blockchain ensures secure interactions by controlling access, managing identities, permissions, and roles, while maintaining data integrity. ",
    },
    {
        img: Automation,
        title: "VPC Creation ",
        bodycopy:
          "Blockchain Proxy APIs offer the feature of creating dedicated Virtual Private Clouds (VPCs) for each blockchain network, ensuring secure and efficient resource management.",
      },


      
  ];
  return (
    <div className="main-wrapper">

          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
        Features
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
      </div>
  );
}
