var baseUrl_login = window.location.protocol+'//'+window.location.hostname+':3001/rest/v1/';
var baseUrl_signup = window.location.protocol+'//'+window.location.hostname+':3002/rest/v1/';
var baseUrl_roleaccess = window.location.protocol+'//'+window.location.hostname+':3003/rest/v1/';



// if(process.env.REACT_APP_DEVELOPMENT_ENV === "production"){
//   window.newBaseUrlOEM = "https://content.gbrservice.com/";
//   window.newBaseUrl_oem = "https://content.gbrservice.com/";
//   window.proxyUrl = "https://content.gbrservice.com/";
//   window.gbrServiceUrl = "https://iam.gbrservice.com/";
//   window.newBaseUrl = "https://iam.gbrservice.com/";
//   window.sendRequestUrl = "http://15.206.178.137:8080/"

//   ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION;
//   AssetDeveloper =process.env.REACT_APP_ASSET_DEVELOPER_PRODUCTION;
// }else if (process.env.REACT_APP_DEVELOPMENT_ENV === "staging"){
//   window.newBaseUrlOEM = "https://proxystaging.gbrservice.com/";
//   window.newBaseUrl_oem = "https://proxystaging.gbrservice.com/";
//   window.proxyUrl = "https://proxystaging.gbrservice.com/";
//   window.gbrServiceUrl = "https://iamstaging.gbrservice.com/";
//   window.newBaseUrl = 'https://iamstaging.gbrservice.com/';
//   window.sendRequestUrl = 'https://registrystaging.gbrservice.com/';

// ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_STAGING;
// AssetDeveloper =process.env.REACT_APP_ASSET_DEVELOPER_STAGING;

// }


// window.liveMonitorUrl = "http://54.185.221.117:8902/";

// window.newServerContent = "https://content.gbrservice.com/"
// window.liveMonitorUrl = 'https://trackstaging.gbrservice.com/';
// window.socketUrl =
//   'http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001';
// window.assetCurrentLocationUrl = 'https://trackstaging.gbrservice.com/';
// window.liveEnvUrl = 'https://datastaging.gbrservice.com/';
// window.invoiceBaseURL = 'https://billstaging.gbrservice.com/';
window.otpLead = "https://otpauth.gbrapp.com/";
// window.tradingServer = "https://proxystaging.gbrservice.com/";
// window.qrcodeBaseUrl = "https://qrstaging.gbrservice.com/";
// window.liveMonitorUrl = "https://trackstaging.gbrservice.com/";
// window.assetCurrentLocationUrl = "https://trackstaging.gbrservice.com/";
// window.liveEnvUrl = "https://datastaging.gbrservice.com/";



// ABN BASE URLS

let ConsumerAdmin;
let AssetDeveloper;

let newBaseUrlOEM,
  newBaseUrl_oem,
  proxyUrl,
  gbrServiceUrl,
  newBaseUrl,
  SocketIOURL,
  baseUrl,
  OpUserServiceUrl,
  AgriBusinessRegistryServiceUrl,
  ApplicationSpecificGwUrl ,
  tradingServer;

if (process.env.REACT_APP_DEVELOPMENT_ENV === 'production') {
  baseUrl="https://registry.gbrservice.com/";
  newBaseUrlOEM = 'https://content.gbrservice.com/';
  newBaseUrl_oem = 'https://content.gbrservice.com/';
  proxyUrl = 'https://content.gbrservice.com/';
  OpUserServiceUrl = 'https://iam.gbrservice.com/';
  // newBaseUrl = 'https://iam.gbrservice.com/';
  ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION;
  AssetDeveloper = process.env.REACT_APP_ASSET_DEVELOPER_PRODUCTION;

  // WEBSITE PRODUCTION URLS
  window.newBaseUrlOEM = "https://content.gbrservice.com/";
  window.newBaseUrl_oem = "https://content.gbrservice.com/";
  window.proxyUrl = "https://content.gbrservice.com/";
  // window.gbrServiceUrl = "https://iam.gbrservice.com/";
  // window.newBaseUrl = "https://iam.gbrservice.com/";
  window.AgriBusinessRegistryServiceUrl = "http://15.206.178.137:8080/"
  // WEBSITE PRODUCTION URL END
} else if (process.env.REACT_APP_DEVELOPMENT_ENV === 'staging') {
  baseUrl = "https://registrystaging.gbrservice.com/";
  newBaseUrlOEM = 'https://proxystaging.gbrservice.com/';
  newBaseUrl_oem = 'https://proxystaging.gbrservice.com/';
  proxyUrl = 'https://proxystaging.gbrservice.com/';
  // gbrServiceUrl = 'http://35.153.176.60:8080/';
  // newBaseUrl = 'http://35.153.176.60:8080/';
  tradingServer = 'https://proxystaging.gbrservice.com';

  ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_STAGING;
  AssetDeveloper = process.env.REACT_APP_ASSET_DEVELOPER_STAGING;
  OpUserServiceUrl = 'https://iamstaging.gbrservice.com/';
  AgriBusinessRegistryServiceUrl = 'https://registrystaging.gbrservice.com/';
  ApplicationSpecificGwUrl = 'http://54.167.97.129:8080/';
  // WEBSITE STAGING BASE URL
  window.newBaseUrlOEM = "https://proxystaging.gbrservice.com/";
  window.newBaseUrl_oem = "https://proxystaging.gbrservice.com/";
  window.proxyUrl = "https://proxystaging.gbrservice.com/";
  // window.gbrServiceUrl = "http://35.153.176.60:8080/";
  // window.newBaseUrl = 'http://35.153.176.60:8080/';
  // window.sendRequestUrl = 'http://18.205.21.225:8080/';
  // WEBSITE PRODUCTION END
}

//let tradingServer = 'http://35.154.32.232:8080/';
let liveMonitorUrl = 'https://trackstaging.gbrservice.com/';
window.liveEnvUrl = 'https://devicestaging.gbrservice.com/';
let assetCurrentLocationUrl = 'https://trackstaging.gbrservice.com/';

let trip_planner_instance = 'http://35.89.191.242:8077/';
let qrcodeBaseUrl = 'https://qrstaging.gbrservice.com/';
//invoice server
let invoiceBaseURL = 'https://billstaging.gbrservice.com/';
let devicesURL = 'https://devicestaging.gbrservice.com/';
let tempBaseUrl = 'http://gendoc.gbrapp.com/';

//device cloud server
let deviceCouldServerUrl = 'https://proxystaging.gbrservice.com/';

//local host server
let localHostUrl = 'http://order.gbrapp.com/';
//rfq url
let tradeHubProxy = 'http://order.gbrapp.com/';
window.socketUrl =
  'http://ec2-34-217-29-64.us-west-2.compute.amazonaws.com:9001';

let otaConfigUrl = 'http://otastatus.gbrapp.com/';
let orderUrl = 'https://order.gbrapp.com/tradeHubProxy/';

let  topicManagementUrl = 'http://172.16.1.157:8904/';
let devicemetadataProxy = 'http://54.185.221.117:8900/';
let otaSerevrProxyUrl = 'http://172.16.1.119:8910/';
// END OF ABN BASE URLS

window.appConfig = {
  /**********************************ABN URLS************************* */
 //orders apis
 updateOrderStatus:orderUrl+"update-order-status?id=",
 getOrderInfo:orderUrl+"get-request-details?id=",
 //new firmware api's
 updatefirmwareAppConfigModel:otaSerevrProxyUrl +'updateAppConfig',
 updatefirmwareModel:otaSerevrProxyUrl +'updateModel',
 getAppConfigfirmwareJsonList: otaSerevrProxyUrl +'otaserver/api/v1/getAppConfigFirmwareList/',
 deleteFirmwareJson:otaSerevrProxyUrl +'otaserver/api/v1/deleteFirmware',
 addFirmwareJsonMetadata: otaSerevrProxyUrl +'otaserver/api/v1/addFirmware',
 getfirmwareJsonList: otaSerevrProxyUrl +'otaserver/api/v1/getFirmwareList/',
 deleteModel:newBaseUrl_oem+"EAP/deleteModel/",
 createModel: newBaseUrl_oem + 'EAP/addModel/',
 getAgentRequests:AgriBusinessRegistryServiceUrl+'AgriBusinessRegistryService/registeredUserlist/',
 updateRegistredDeviceStatus: devicemetadataProxy + 'admin/deviceprofile',
 getDeviceList: devicemetadataProxy + 'admin/deviceprofile',
 registerDevice: topicManagementUrl + 'emqxrule/api/v1/rules',
 deRegisterDevice:topicManagementUrl + 'emqxrule/api/v1/rules',
//topicmanagement api
updateTopic: topicManagementUrl + 'topicmanagement/api/v1/topics',
 deleteTopic: topicManagementUrl + 'topicmanagement/api/v1/topics',
 addTopics: topicManagementUrl + 'topicmanagement/api/v1/topics',
 getAllTopics: topicManagementUrl + 'topicmanagement/api/v1/topics',
 updateUserJourney: `${OpUserServiceUrl}OpUserService/updateUserJourney/`,
 /*Content GW Api's */
 updateContentStatus: proxyUrl + 'ContentGw/update/',
 getSubCategoriesBasedonCategoryIDandParentId: proxyUrl + 'ContentGw/',
 mapContentWithSite: proxyUrl + 'ContentGw/sitecontent/',
 getCategoryContentsBasedonCategoryid: proxyUrl + 'ContentGw/contentlist/',
 deleteContent: newBaseUrl_oem + 'ContentGw/content/',
 getUnsignedAgreements: proxyUrl + 'ContentGw/contentList/',
 deleteContentSubCategory: proxyUrl + 'ContentGw/deleteContentSubCategory/',
 getListOfCategorys: proxyUrl + 'ContentGw/getCategories/',
 getCategoryRelatedSubCategories:
 proxyUrl + 'ContentGw/getAllContentSubCategories/',
 getCategory: proxyUrl + 'ContentGw/getCategoryId/',
 getSubCategoriesForAD: proxyUrl + 'ContentGw/getAllContentSubCategories/',
 getAllContentsList: proxyUrl + 'ContentGw/contentList/',
 getTendersByDistricts: proxyUrl + 'ContentGw/getSiteContent/',
 getTendersByOrganization: proxyUrl + 'ContentGw/contentList/',
 createContentSubCategory: proxyUrl + 'ContentGw/contentsubcategory/',
 createContent: proxyUrl + 'ContentGw/content/',
 getFarmUpdates: proxyUrl + 'ContentGw/contentlist/',
 getFarmUpdatesFromAll: proxyUrl + 'ContentGw/contentlist/',
 getCategoriesBasedOnOEM: proxyUrl + 'ContentGw/getAllCategories/',
 getSubCategoriesForOEM: proxyUrl + 'ContentGw/getAllContentSubCategories/',
 getApps: newBaseUrl_oem + 'ContentGw/getapps/',
 likeCount: newBaseUrl_oem + 'ContentGw/updatelikecount/',
 /*End Content GW Api's */

 /* get feedbacks */
 getSiteFeedbacks: newBaseUrl_oem + 'QMS-Gw/siteFeedbacks/',
 getModelFeedbacks: newBaseUrl_oem + 'QMS-Gw/modelFeedbacks/',

 /* end of feedbacks */
 getUsersBasedOnUserType: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getVerifiedAssets: newBaseUrlOEM + 'EAP/siteVerifiedPrematureAssets/',
 getVerifiedSites: newBaseUrl_oem + 'AnalyticsProxy/getsite/',

 //quote
 rfqdoclinks: tradeHubProxy + 'tradeHubProxy/get-doc-links?id=',
 getrequestdetails: tradeHubProxy + 'tradeHubProxy/get-request-details?id=',
 getallquotesbasedonid: tradeHubProxy + 'tradeHubProxy/get-all-quotes?id=',
 updaterfqandorderstatus: tradeHubProxy + 'tradeHubProxy/',
 getallrequestidsbasedonverticalsandcategory:
   tradeHubProxy + 'tradeHubProxy/get-all-request-ids?',
 procurerequest: tradeHubProxy + 'tradeHubProxy/procure-request?request-type=',
 //end quote
 getGrandParent: OpUserServiceUrl + 'OpUserService/getParent/',
 updateWhitelabelId: proxyUrl + 'EAP/updateWhitelabelId/',

 // invoice related
 documentRfqRequest: `${tradeHubProxy}tradeHubProxy`,

 //OTA Server Config
 destroyStatusNotification: otaConfigUrl + 'FOTA/',
 modelStatusMessage: otaConfigUrl + 'FOTA/status/',
 deviceStatusMessage: otaConfigUrl + 'FOTA/status/',
 solutionDeviceStatusMessage: otaConfigUrl + 'FOTA/allstatus/',
 initStatusNotification: otaConfigUrl + 'FOTA/',
 createOTAServer:
   deviceCouldServerUrl + 'Data-Management-Gw/otaconfiguration/',
 getOTAServerList:
   deviceCouldServerUrl + 'Data-Management-Gw/otaconfiguration/',
 requestStatusBasedonRequestid:AgriBusinessRegistryServiceUrl + 'AgriBusinessRegistryService/getRegisterRequestStatus/',
 getUsersRoleRequests: AgriBusinessRegistryServiceUrl + 'AgriBusinessRegistryService/registeredUserlist/',
 productlistbasedoncategorysubcategory:
   newBaseUrl_oem + 'AgriProductProxy/getproductlist/',
 productlistbasedonusernamecategory:
   newBaseUrl_oem + 'AgriProductProxy/productlist/',
 requestInfoBasedonRequestid:
 AgriBusinessRegistryServiceUrl + 'AgriBusinessRegistryService/getRegisterRequest/',
 upgradeRoleRequest: AgriBusinessRegistryServiceUrl + 'AgriBusinessRegistryService/register/',
 updateRegistrationRequestStatus:
 AgriBusinessRegistryServiceUrl + 'AgriBusinessRegistryService/updateRegistrationRequestStatus/',
 SocketIOURL: 'http://54.185.221.117:8900',
   // SocketIOURL: 'http://localhost:8905',
 OtaConfigStatusURL : `172.16.0.192:3111`,
 //device cloud server and firmware apis
 updatefirmware: deviceCouldServerUrl + 'Data-Management-Gw/updateModel',
 updatefirmwareAppConfig: deviceCouldServerUrl + 'Data-Management-Gw/updateAppConfig',
 getfirmwareList:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/getFirmwareList',
   getAppConfigfirmwareList : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/getAppConfiglist',

   //vertical dev and vertical usecase dev apis
   addVerticals : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/publishVertical',
   getVerticalList : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/getVerticals',
   updateVerticalStatus : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/updateVerticalStatus',
   getVerticalUsecases : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/developerPublish',
   createVerticalUsecase : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/developerPublish',
   deleteVerticalUsecase : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/delCapability',
   deleteVertical : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/delVertical',
   updateVerticalUsecaseStatus : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/updateSolutionUseCaseStatus',
 deleteFirmware:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/deleteFirmware',
   deleteAppConfigFirmware : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/deleteAppConfig',
 deleteFirmwareFile:
   deviceCouldServerUrl + 'Cloud-Storage-Gw/deletePartnerFirmwareImageObject',
   deleteFirmwareAppConfigFile : deviceCouldServerUrl + 'Cloud-Storage-Gw/deleteSolutionAppConfigObject',
 addFirmwareFile:
   deviceCouldServerUrl + 'Cloud-Storage-Gw/uploadPartnerFirmwareImageObject',
 addFirmwareAppConfigFile : deviceCouldServerUrl + 'Cloud-Storage-Gw/uploadSolutionAppConfigObject',
 addFirmwareMetadata:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/addFirmwareMetadata',
   addFirmwareAppConfigMetadata : deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/addAppConfig',
 getfirmwareList:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/getFirmwareList',
 deleteFirmware:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/deleteFirmware',
 deleteFirmwareFile:
   deviceCouldServerUrl + 'Cloud-Storage-Gw/deletePartnerFirmwareImageObject',
 addFirmwareFile:
   deviceCouldServerUrl + 'Cloud-Storage-Gw/uploadPartnerFirmwareImageObject',
 addFirmwareMetadata:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/addFirmwareMetadata',
 getCloudServer: deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/dcslist',
 devicePublish:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/publishDCS',
 updateDeviceCloudStatus:
   deviceCouldServerUrl + 'OP-BackEnd-Gateway/opgateway/updateDCSStatus',
 deleteDeviceCloud:
   deviceCouldServerUrl +
   'OP-BackEnd-Gateway/opgateway/deleteDeviceCloudServer',
 assetPointDelete: proxyUrl + 'EAP/deletePoint/',
 updatePointName: proxyUrl + 'EAP/updatePointInfo/',
 createSiteType: proxyUrl + 'EAP/createSiteType/',
 deleteSiteType: proxyUrl + 'EAP/deleteSiteType/',
 /* Points in IAA Management */
 //"getSolutionDevices":newBaseUrl_oem+"DMBackEndGw/DMDevice/getSolutionDevices/",
 getSolutionDevices: devicesURL + 'admin/deviceprofile/',
 mapPointWithNode: newBaseUrl_oem + 'EAP/mapPointWithEndNode/',
 unmapPointWithNode: newBaseUrl_oem + 'EAP/unmapPointFromEndNode/',
 createPoint: newBaseUrl_oem + 'EAP/createPoint/',
 getPoints: newBaseUrl_oem + 'EAP/getpoints/',
 // asset distribution start
 createOrder: localHostUrl + 'orderProxy/createOrder',
 getAllRequestTypes: localHostUrl + 'orderProxy/getAllRequestTypes',
 getAllOrderIds: localHostUrl + 'orderProxy/getAllOrderIds',

 // end
 /* USerTypes */
 createUserType: OpUserServiceUrl + 'OpUserService/createUserType/',
 deleteUserType: OpUserServiceUrl + 'OpUserService/deleteUserType/',
 /* End of UserTypes */
 /* invoice genration code */
 saveOrGetListDocuments: `${tempBaseUrl}docs/v1/`,
 genrateDocument: `${tempBaseUrl}gendoc/gateway/v1/`,

 createQutation: `${invoiceBaseURL}gendocApi/v1/`,
 createPurchaseOrder: `${invoiceBaseURL}gendocApi/v1/`,
 createProformaInvoice: `${invoiceBaseURL}gendocApi/v1/`,
 createTaxInvoice: `${invoiceBaseURL}gendocApi/v1/`,
 createDeliveryChallan: `${invoiceBaseURL}gendocApi/v1/`,
 createDeliveryReceipt: `${invoiceBaseURL}gendocApi/v1/`,
 getVehicleTripDetails: liveMonitorUrl + 'tripplanner/',
 updateDocumentStatus: baseUrl + 'ContractGw/updateDocumentStatus/',
 getSignedAgreement: baseUrl + 'ContractGw/getSignedAgreement/',
 getRegisterAccountRequests:
 AgriBusinessRegistryServiceUrl + 'AgriBusinessRegistryService/registeredUsers/',
 getOrderStatus: newBaseUrl_oem + 'BookingProxy/order/',
 buyurlproduct: newBaseUrl_oem + 'AgriProductProxy/updateBuyUrl/',
 updateproductinfodata: newBaseUrl_oem + 'AgriProductProxy/updateInfoUrl/',
 productitemstatus: newBaseUrl_oem + 'AgriProductProxy/updateItemStatus/',
 unmapproductitemwithsite: newBaseUrl_oem + 'AgriProductProxy/unmapSite/',
 mapproductitemwithsite: newBaseUrl_oem + 'AgriProductProxy/mapSite/',
 /*product */
 getBookingRequests: proxyUrl + 'BookingProxy/bookingRequest/',
 sendBookingRequest: proxyUrl + 'BookingProxy/bookingRequest/',
 getParturationInfo: proxyUrl + 'EAP/getParturitionInfo/',
 getNutritionInfo: proxyUrl + 'EAP/getNutritionInfo/',
 getAssetFeedback: newBaseUrl_oem + 'QMS-Gw/assetFeedbacks/',
 getAssetHealthInfo: proxyUrl + 'EAP/getHealthRecordInfo/',
 createSiteIssue: newBaseUrl_oem + 'QMS-Gw/createIssue/',
 /* invoice genration code */
 createQutation: `${invoiceBaseURL}gendocApi/v1/`,
 createPurchaseOrder: `${invoiceBaseURL}gendocApi/v1/`,
 createProformaInvoice: `${invoiceBaseURL}gendocApi/v1/`,
 createTaxInvoice: `${invoiceBaseURL}gendocApi/v1/`,
 createDeliveryChallan: `${invoiceBaseURL}gendocApi/v1/`,
 createDeliveryReceipt: `${invoiceBaseURL}gendocApi/v1/`,


   /* ..........................Start oF LOGISTIC-PROVIDER API'S(Biplob)....................................... */
 getDepotOwnerCount: OpUserServiceUrl + "OpUserService/getUsersCount/",
 getDepotManagerCount: OpUserServiceUrl + "OpUserService/getUsersCount/",
 getDepotOperatorCount: OpUserServiceUrl + "OpUserService/getUsersCount/",
 getAgentCount: OpUserServiceUrl + "OpUserService/getUsersCount/",
 getTransportUserCount: OpUserServiceUrl + "OpUserService/getUsersCount/",
 getAllBookingsDetails:
   newBaseUrl_oem + "BookingProxy/getAllBooking/",
 getAssetsCountInfo: newBaseUrl_oem + "EAP/getAssetsCount/",
 getDeviceCountInfo:
   newBaseUrl_oem + "DMBackEndGw/DMDevice/getDevicesCount/",
 getDriversInfo: OpUserServiceUrl + "OpUserService/getUsersCount/",
 getTripPlannerInfo: liveMonitorUrl + "tripplanner/",
 getLocationInfo: liveMonitorUrl + "tripplanner/",
 getRouteInfo: liveMonitorUrl + "tripplanner/",
 getTripInfo: liveMonitorUrl + "tripplanner/",
 addLocationData: liveMonitorUrl + "tripplanner/",
 getOrderListInfo: proxyUrl + "BookingProxy/allBookings/consumer2/",
 getVehicleList: proxyUrl + "EAP/getSolutionAssets/",
 getDrivers: OpUserServiceUrl + "OpUserService/getAllConsumerUsers/",
 getRoute: liveMonitorUrl + "tripplanner/",
 createBooking: proxyUrl + "BookingProxy/createBooking/consumer2/",
 addRouteApi: liveMonitorUrl + "tripplanner/",
 addTripApi: liveMonitorUrl + "tripplanner/",
 getVehicleLocation: liveMonitorUrl + "livemonitoring/data/live/",
 getVehicleTrip: liveMonitorUrl + "tripplanner/",
 geofenceDetails: liveMonitorUrl + "geofence/",
 createGeofence: liveMonitorUrl + "geofence/",
 delLocation: liveMonitorUrl + "tripplanner/",
 getMonthlyTrips: liveMonitorUrl + "tripplanner/",
 delRoute: liveMonitorUrl + "tripplanner/",
 delTrip: liveMonitorUrl + "tripplanner/",
 transportManagerlist:
   OpUserServiceUrl + "OpUserService/getAllConsumerUsers/",
 getAllUsers: OpUserServiceUrl + "OpUserService/getAllConsumerUsers/",
 getLogisticManagerCount:
 OpUserServiceUrl + "OpUserService/getUsersCount/",
 getBookingReq: proxyUrl + "BookingProxy/bookingRequest/",
 mapWithTrip: proxyUrl + "BookingProxy/mapBookingIdWithTrip/consumer2/",
 postTripMapRequest:
   newBaseUrl_oem + "BookingProxy/mapBookingIdWithTrip/",
 postTripUnmapReques:
   newBaseUrl_oem + "BookingProxy/unmapBookingIdFromTrip/",
 postDriverMapRequest:
   newBaseUrl_oem + 'BookingProxy/mapBookingIdWithTransportUser/',
 postDriverUnmapRequest:
   newBaseUrl_oem + 'BookingProxy/unmapBookingIdFromTransportUser/',
 getBookingDetails: newBaseUrl_oem + 'BookingProxy/getBookingDetails/',
 getOrderStatus: newBaseUrl_oem + 'BookingProxy/order/',
 updateLocationTag: liveMonitorUrl + 'tripplanner/',
 updateTripDetails: liveMonitorUrl + 'tripplanner/',
 getDetailsOfTripId: liveMonitorUrl + 'tripplanner/',
 getDetailsOfLocId: liveMonitorUrl + 'tripplanner/',
 getDetailsofRouteId: liveMonitorUrl + 'tripplanner/',
 updateRouteName: liveMonitorUrl + 'tripplanner/',
 vehicleStatus: liveMonitorUrl + 'livemonitoring/data/live/',
 locationSearch: liveMonitorUrl + 'tripplanner/',
 tripSearch: liveMonitorUrl + 'tripplanner/',
 endNode: proxyUrl + 'EAP/getpoints/',
 getOrdersOfTrip: newBaseUrl_oem + 'BookingProxy/getOrders/consumer2/',
 getOrderLocation: newBaseUrl_oem + 'BookingProxy/getOrderLocation/consumer2/',
 lastLocationDetails: liveMonitorUrl + 'livemonitoring/data/live/',
 allLastLocDetails: liveMonitorUrl + 'livemonitoring/data/live/',
 updateGeofenceOfAssets: newBaseUrl_oem + 'EAP/updateAssetGeofence/',
 getSiteInformation: newBaseUrl_oem + 'EAP/siteInformation/',
 getConsumerInfo: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 getVehicleSchedule:liveMonitorUrl + 'tripplanner/',
 updateBookingReqStatus: proxyUrl+ 'BookingProxy/updateBookingRequestStatus/',
 updateTripStatus:  liveMonitorUrl + 'tripplanner/',
 geofenceDetailOfAllVehicle: liveMonitorUrl+ 'geofence/',
 delGeofence: liveMonitorUrl+ 'geofence/',
 updateGeofenceDetails: liveMonitorUrl +'geofence/',
              /*END OF Logistic provider API'S*/
                   /*start of TRANSPORTUSER*/
 getTripDetailsDriver: liveMonitorUrl + 'tripplanner/consumer2/',
 getMyBooking: proxyUrl + 'BookingProxy/assignedBookings/consumer2/',
 getDriverSchedule: liveMonitorUrl + 'tripplanner/',
 getDriverOrder: newBaseUrl_oem + `BookingProxy/getOrders/consumer2/`,
 getAllOrderCount: newBaseUrl_oem + `BookingProxy/getOrdersCount/consumer2/`,
 getMyorderDetails:
   newBaseUrl_oem + `TradingGw/getBuyRequestStatus/consumer2/`,
 PostMyOrderStatus:
   newBaseUrl_oem + `TradingGw/updateLegacyAssetOrderStatus/consumer2/`,
 getMyRoute: liveMonitorUrl + `tripplanner/consumer2/`,
 getUserType: OpUserServiceUrl + `OpUserService/getUserType/`,
 getWeeklyDriverSchedule: liveMonitorUrl + 'tripplanner/consumer2/',

                      /*End of TRANSPORTUSER*/

 getSitesBasedOnEmail: newBaseUrl_oem + 'EAP/sitelist/',
 AgriProductLikeCount: newBaseUrl_oem + 'AgriProductProxy/updateLikeCount/',
 getRoleNameofOperator: OpUserServiceUrl + 'OpUserService/getRole/',
 updateNode: newBaseUrl_oem + 'EAP/update/node/',
 deleteNode: newBaseUrl_oem + 'EAP/delete/node/',
 getSiteEnvInfo: window.liveEnvUrl + 'ems/livedata/',
 getAssetEnvInfo: window.liveEnvUrl + 'ems/livedata/',
 getMortalityInfo: proxyUrl + 'EAP/getMortalityInfo/',
 getRoleResourceAssets: newBaseUrl_oem + 'EAP/siteAssets/',
 addSuperRole: OpUserServiceUrl + 'OpUserService/addRole/',
 getSuperRoles: OpUserServiceUrl + 'OpUserService/getRoleList/',
 mapSuperRole: OpUserServiceUrl + 'OpUserService/mapRoleKeycloak/',
 unmapSuperRole: OpUserServiceUrl + 'OpUserService/unmapRoleKeycloak/',
 updateSuperRole: OpUserServiceUrl + 'OpUserService/updateRoleDesc/',
 deleteSuperRole: OpUserServiceUrl + 'OpUserService/deleteRoleId/',
 updateAssetInfo: proxyUrl + 'EAP/updateAssetInfo/',
 assetReports: newBaseUrl_oem + 'QMS-Gw/assetReports/',
 getCompliances: proxyUrl + 'QMS-Gw/getAllCompliances/',
 communityUsersInfo: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 getProductsBasedonCategoriId: proxyUrl + 'AgriProductProxy/product/',
 viewListProductionFarmers:
 OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getAllUserTypes: OpUserServiceUrl + 'OpUserService/getUserTypes/',
 getConsumerUserTypeRoleProfile: OpUserServiceUrl + 'OpUserService/getRole/',
 getConsumerUserTypeRole: OpUserServiceUrl + 'OpUserService/getRoleKeycloak/',
 assetComplaints: newBaseUrl_oem + 'QMS-Gw/assetComplaints',
 siteCompliance: newBaseUrl_oem + 'QMS-Gw/complaints/',
 settingsInfo: newBaseUrl_oem + 'QMS-Gw/getSettingsInfo/',
 settingsInfoBasedonParent: newBaseUrl_oem + 'QMS-Gw/settings/',
 getHealthStatusBasedOnAssetId:
   newBaseUrl_oem + 'AnalyticsProxy/assetHealthStatus/',
 getAttendanceLocationInfo: newBaseUrl_oem + 'EAP/getAttendanceLocationInfo/',
 getSiteReports: newBaseUrl_oem + 'QMS-Gw/reports/',
 siteIssue: proxyUrl + 'QMS-Gw/issues/',
 getGlobalUsers: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getSHG: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 updateWhitelabelCost: newBaseUrl_oem + 'EAP/updateAssetStatus/',
 updateSellingCost: proxyUrl + 'EAP/addSellingCost/',
 unmapExistingResourceFromRole:
 OpUserServiceUrl + 'OpUserService/deleteResource/',
 getAllMSMEConsumerUsers: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 unmapUserRole: OpUserServiceUrl + 'OpUserService/unmapConsumerUserRole/',
 GetAgriProductProxeyDataBasedOnModel:
   newBaseUrl_oem + 'AgriProductProxy/allproducts/',
 getAllAssetsBasedonModel: proxyUrl + 'EAP/getSolutionAssets/',
 addSiteAlarm: newBaseUrl_oem + 'AdvisoryProxy/sitealarms/',
 getSiteAlarms: newBaseUrl_oem + 'AdvisoryProxy/sitealarms/',
 getAssetAlarms: newBaseUrl_oem + 'AdvisoryProxy/assetalarms/',
 getAssetsBasedonAll: newBaseUrl_oem + 'EAP/getReadyforWhitelabelAssets/',
 DeleteProductItem: newBaseUrl_oem + 'AgriProductProxy/productItem/',
 GetProductItems: newBaseUrl_oem + 'AgriProductProxy/productItems/',
 ProductVideoURL: newBaseUrl_oem + 'AgriProductProxy/updateProductVideoUrl/',
 UpdateLabCertificateURL: newBaseUrl_oem + 'AgriProductProxy/updateLabUrl/',
 UpdateHistoryURL: newBaseUrl_oem + 'AgriProductProxy/updateHistoryUrl/',
 DecrementItemCount: newBaseUrl_oem + 'AgriProductProxy/decrementItemCount/',
 IncrementItemCount: newBaseUrl_oem + 'AgriProductProxy/incrementItemCount/',
 UpdateItemCount: newBaseUrl_oem + 'AgriProductProxy/updateItemCount/',
 UpdateProductRating: newBaseUrl_oem + 'AgriProductProxy/updateRatings/',
 UpdateProductCost: newBaseUrl_oem + 'AgriProductProxy/updateProductCost/',
 UpdateProductDiscount:
   newBaseUrl_oem + 'AgriProductProxy/updateDiscountPrice/',
 UpdateProductStatus: newBaseUrl_oem + 'AgriProductProxy/updateStatus/',
 UpdateProductQrURL: newBaseUrl_oem + 'AgriProductProxy/updateProductQRCode/',
 UpdateProductCertificateURL: newBaseUrl_oem + 'AgriProductProxy/updateQAUrl/',
 UpdateProductImageURL:
   newBaseUrl_oem + 'AgriProductProxy/updateProductImageUrl/',
 UploadProductCertificate:
   newBaseUrl_oem + 'AgriProductProxy/uploadProductCertificate/',
 UploadProductContent:
   newBaseUrl_oem + 'AgriProductProxy/uploadProductContent/',
 getRoleResources: OpUserServiceUrl + 'OpUserService/getResources/',
 getQrCodeForModel: newBaseUrl_oem + 'QR-Code-Proxy/modelqrcode/',
 getQrCodeForAsset: newBaseUrl_oem + 'QR-Code-Proxy/assetqrcode/',
 getQrCodeForSite: newBaseUrl_oem + 'QR-Code-Proxy/siteqrcode/',
 getProcessHealthStatusExe:
   newBaseUrl_oem + 'AnalyticsProxy/getProcessHealthStatus/',
 getAssetHealthStatusExe:
   newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetsHealthStatus/',
 getAssetStatisticsExe:
   newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetStatistics/',
 AgriProductProxey: newBaseUrl_oem + 'AgriProductProxy/productInfo/',
 AgriProductProxeyItem: newBaseUrl_oem + 'AgriProductProxy/productItem/',
 AgriProductProxeyDelete: newBaseUrl_oem + 'AgriProductProxy/product/',
 GetAgriProductProxeyData: newBaseUrl_oem + 'AgriProductProxy/products/',
 GenerateProductQRCode: newBaseUrl_oem + 'QR-Code-Proxy/generateProduct/',
 getAssetStatisticsExe:
   newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetStatistics/',
 assetCurrentLocation: liveMonitorUrl + 'livemonitoring/data/live/',
 addAssetFeedback: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
 getAssetIssues: newBaseUrl_oem + 'QMS-Gw/assetIssues/',
 createAssetIssue: newBaseUrl_oem + 'QMS-Gw/createIssue/',
 liveMonitoringData: window.socketUrl + 'livemonitoring/data/live/',
 getProcessHealthStatusEx:
   newBaseUrl_oem + 'AnalyticsProxy/getProcessHealthStatus/',
 getAssetHealthStatusEx:
   newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetsHealthStatus/',
 getAssetStatisticsEx:
   newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetStatistics/',
 getDashboardDataEx: newBaseUrl_oem + 'AnalyticsProxy/getDashboardData/',
 getModels: newBaseUrl_oem + 'EAP/getModel/',
 getModelInfo: proxyUrl + 'EAP/modeldetails/',
 generateQrCode: qrcodeBaseUrl + 'generate',
 generateSiteQrCode: newBaseUrl_oem + 'QR-Code-Proxy/generateSite/consumer2/',
 generateModelQrCode:
   newBaseUrl_oem + 'QR-Code-Proxy/generateModel/consumer2/',
 generateQrCodeWithId:
   newBaseUrl_oem + 'QR-Code-Proxy/generateWithId/consumer2/',
 generateBulkQrCodes: qrcodeBaseUrl + 'generateBulk',
 downloadQrCode: qrcodeBaseUrl + 'download?file=',
 getAccessToken: OpUserServiceUrl + 'AuthBackEndGw/refresh_token/getaccessToken/',
 createAccessToken: OpUserServiceUrl + 'AuthBackEndGw/token/',
 getToken: OpUserServiceUrl + 'AuthBackEndGw/getUserToken/',
 resetUserPassword: OpUserServiceUrl + 'AuthBackEndGw/resetPassword/',
 forgetUsername: OpUserServiceUrl + 'AuthBackEndGw/forgetUsername/',
 forgetPassword: OpUserServiceUrl + 'AuthBackEndGw/forgetPassword/',
 registerUser: AgriBusinessRegistryServiceUrl + 'RegisterGateway/registerUser/',
 consumerRequestInfo: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 'getUserType:': OpUserServiceUrl + 'OpUserService/getUserType/',
 getConsumerUserType: OpUserServiceUrl + 'OpUserService/getUserType/',
 getStepperCounter: OpUserServiceUrl + 'OpUserService/userJourneyDetails/',
 getAllAssetsOfAssetDeveloper: proxyUrl + 'EAP/getSolutionAssets/',
 getAllRoles: OpUserServiceUrl + 'OpUserService/roles/',
 getAllConsumerUsers: OpUserServiceUrl + 'OpUserService/consumerUser/',
 getMappedRoles: OpUserServiceUrl + 'OpUserService/getUsersRoles/',
 viewAllSites: proxyUrl + 'EAP/allsites/',
 createMapping: newBaseUrl_oem + 'EAP/maplegacyassettosite/',
 createUnMapping: newBaseUrl_oem + 'EAP/unmapSiteAsset/',
 assetStatusUpdate: proxyUrl + 'EAP/updateAssetStatus/',
 assetDelete: proxyUrl + 'EAP/deleteAsset/',
 getAllSiteTypes: proxyUrl + 'EAP/getSiteTypes/',
 viewAllTenderSites: proxyUrl + 'EAP/allsites/',
 mappedTenderLegacyAssets: newBaseUrl_oem + 'EAP/siteAssets/',
 getAllTenderAssetsData: proxyUrl + 'EAP/getSolutionAssets/',
 assetMapingtoTenderSite: newBaseUrl_oem + 'EAP/maplegacyassettosite/',
 getAllTenderSiteTypes: proxyUrl + 'EAP/getSiteTypes/',
 createTenderSite: proxyUrl + 'OP-BackEnd-Gateway/opgateway/createsite/',
 createSiteWithNodeId:
   proxyUrl + 'OP-BackEnd-Gateway/opgateway/sitecreationinfo/',
 viewListOfOperators: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getRole: OpUserServiceUrl + 'OpUserService/getRole/',
 getRoleResourceId: OpUserServiceUrl + 'OpUserService/getResources/',
 getAssetsOfSite: proxyUrl + 'EAP/siteAssets/',
 viewListOfDeveloperQA: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getDevRole: OpUserServiceUrl + 'OpUserService/getRole/',
 getDevRoleResourceId: OpUserServiceUrl + 'OpUserService/getResources/',
 getDevAssetsOfSite: proxyUrl + 'EAP/siteAssets/',
 viewListOfExecutives: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getConsumerInfoRequest: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 'Production-Team': OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getTeamRole: OpUserServiceUrl + 'OpUserService/getRole/',
 getTeamRoleResourceId: OpUserServiceUrl + 'OpUserService/getResources/',
 viewListProductionManagers:
 OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 viewListOfProductionTeams:
 OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getProductManagerRole: OpUserServiceUrl + 'OpUserService/getRole/',
 getRoleProductionManagersResourceId:
 OpUserServiceUrl + 'OpUserService/getResources/',
 viewListOfFarmers: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getFarmerNodes: proxyUrl + 'EAP/nodes/',
 allApprovalRequests: proxyUrl + 'FarmerProxy/allapprovalrequest/',
 allApprovalRequestsByNode: proxyUrl + 'FarmerProxy/approvalrequests/',
 allGrowRequests: proxyUrl + 'FarmerProxy/allgrowrequest/',
 allGrowRequestsByNode: proxyUrl + 'FarmerProxy/growrequests/',
 allSellRequests: proxyUrl + 'FarmerProxy/allsellrequest/',
 allSellRequestsByNode: proxyUrl + 'FarmerProxy/sellrequests/',
 allLoanRequests: proxyUrl + 'FarmerProxy/allloanrequest/',
 allLoanRequestsByNode: proxyUrl + 'FarmerProxy/loanrequests/',
 allVetVisitRequests: proxyUrl + 'FarmerProxy/allvetvisitrequest/',
 allVetVisitRequestsByNode: proxyUrl + 'FarmerProxy/vetvisitrequests/',
 allBuyInputRequests: proxyUrl + 'FarmerProxy/allbuyinputrequest/',
 allBuyInputRequestsByNode: proxyUrl + 'FarmerProxy/buyinputrequests/',
 allStoreRequests: proxyUrl + 'FarmerProxy/allstorerequest/',
 allStoreRequestsByNode: proxyUrl + 'FarmerProxy/storerequests/',
 allAdvisoryRequests: proxyUrl + 'FarmerProxy/alladvisoryrequest/',
 allAdvisoryRequestsByNode: proxyUrl + 'FarmerProxy/advisoryrequests/',
 setNodeStatus: proxyUrl + 'EAP/updateNodeStatus/',
 viewFarmerInfo: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 approvalReqStatus: proxyUrl + 'FarmerProxy/updateApprovalRequest/',
 growReqStatus: proxyUrl + 'FarmerProxy/updateGrowRequest/',
 sellReqStatus: proxyUrl + 'FarmerProxy/updateSellRequest/',
 LoanReqStatus: proxyUrl + 'FarmerProxy/updateLoanRequest/',
 VetReqStatus: proxyUrl + 'FarmerProxy/updateVetVisitRequest/',
 BuyReqStatus: proxyUrl + 'FarmerProxy/updateBuyInputRequest/',
 StoreReqStatus: proxyUrl + 'FarmerProxy/updateStoreRequest/',
 viewSettingsInfo: newBaseUrl_oem + 'QMS-Gw/getSettingsInfo/',
 mappedLegacyAssets: newBaseUrl_oem + 'EAP/siteAssets/',
 getAllAssetsData: proxyUrl + 'EAP/getSolutionAssets/',
 assetMapingtoSite: newBaseUrl_oem + 'EAP/maplegacyassettosite/',
 siteDeleteHandler: proxyUrl + 'OP-BackEnd-Gateway/opgateway/deletesite/',
 siteRating: newBaseUrl_oem + 'QMS-Gw/ratings/',
 createSite: proxyUrl + 'OP-BackEnd-Gateway/opgateway/sitecreation/',
 addSiteRating: newBaseUrl_oem + 'QMS-Gw/ratings/',
 siteInfo: newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/siteInfo/',
 updateSiteInfo: proxyUrl + 'EAP/updateSiteInfo/',
 viewAllFarms: proxyUrl + 'EAP/getallsites/',
 viewAllFarmRelatedAssets: newBaseUrl_oem + 'EAP/siteAssets/',
 viewFarmMapRelatedAssets: newBaseUrl_oem + 'EAP/siteAssets/',
 farmsDropDownList: proxyUrl + 'EAP/getallsites/',
 getConsumerNodeInfo: proxyUrl + 'EAP/nodeSpecifications/',
 updateConsumerNodeStatus: proxyUrl + 'EAP/updateNodeStatus/',
 viewNodeStatus: proxyUrl + 'EAP/getNodeStatus/',
 createConsumerUserOfIndividual: AgriBusinessRegistryServiceUrl + 'RegisterGateway/userCreation/',
 deleteConsumerUserIndividual: AgriBusinessRegistryServiceUrl + 'RegisterGateway/userDeletion/',
 cancelGrowRequest: proxyUrl + 'FarmerProxy/cancelRequest/',
 cancelSellRequest: proxyUrl + 'FarmerProxy/cancelRequest/',
 cancelLoanRequest: proxyUrl + 'FarmerProxy/cancelRequest/',
 cancelVetRequest: proxyUrl + 'FarmerProxy/cancelRequest/',
 resetConsumerUserPasswordIndividual:
   OpUserServiceUrl + 'AuthBackEndGw/createCredentials/',
 updateConsumerUserInfoIndividual:
 OpUserServiceUrl + 'OpUserService/updateConsumerUserInfo/',
 ViewAssetOnboardings: newBaseUrl_oem + 'EAP/getReadyForOnboardAssets/',
 updateReadyForOnboardAssetStatus: newBaseUrl_oem + 'EAP/updateAssetStatus/',
 AttendanceLocationHistory: newBaseUrl_oem + 'EAP/getAttendanceLocations/',
 NewAttendanceLocation: newBaseUrl_oem + 'EAP/addAttendanceLocation/',
 DeleteNewAttendanceLocation: newBaseUrl_oem + 'EAP/deleteAttendanceLocation/',
 UpdateAttendanceLocation: newBaseUrl_oem + 'EAP/updateAttendanceLocation/',
 getBirthDetails: newBaseUrl_oem + 'EAP/getBirthInfos/',
 getBirthInfo: newBaseUrl_oem + 'EAP/getBirthInfo/',
 addBirthInfo: newBaseUrl_oem + 'EAP/addBirthInfo/',
 deleteBirthInfo: newBaseUrl_oem + 'EAP/deleteBirthInfo/',
 updateBirthInfo: newBaseUrl_oem + 'EAP/updateBirthInfo/',
 getHealthRecords: newBaseUrl_oem + 'EAP/getHealthRecord/',
 addHealthRecords: newBaseUrl_oem + 'EAP/addHealthRecord/',
 deleteHealthRecord: newBaseUrl_oem + 'EAP/deleteHealthRecord/',
 updateHealthRecord: newBaseUrl_oem + 'EAP/updateHealthRecord/',
 getNutrition: newBaseUrl_oem + 'EAP/getNutritions/',
 addNutrition: newBaseUrl_oem + 'EAP/addNutrition/',
 deleteNutrition: newBaseUrl_oem + 'EAP/deleteNutrition/',
 updateNutrition: newBaseUrl_oem + 'EAP/updateNutritionInfo/',
 getWeighingDetails: newBaseUrl_oem + 'EAP/getWeighingDetails/',
 addWeighingDetails: newBaseUrl_oem + 'EAP/addWeighingDetails/',
 deleteWeighingDetails: newBaseUrl_oem + 'EAP/deleteWeighingDetails/',
 updateWeighingDetails: newBaseUrl_oem + 'EAP/updateWeighingDetails/',
 getParturitionDetails: newBaseUrl_oem + 'EAP/getParturitionDetails/',
 addParturitionDetails: newBaseUrl_oem + 'EAP/addParturitionDetails/',
 deleteParturitionDetails: newBaseUrl_oem + 'EAP/deleteParturitionDetails/',
 updateParturitionDetails: newBaseUrl_oem + 'EAP/updateParturitionInfo/',
 getMortalityDetails: newBaseUrl_oem + 'EAP/getMortalityDetails/',
 addMortalityDetails: newBaseUrl_oem + 'EAP/addMortalityDetails/',
 deleteMortalityDetails: newBaseUrl_oem + 'EAP/deleteMortalityDetails/',
 updateMortalityDetails: newBaseUrl_oem + 'EAP/updateMortalityInfo/',
 getAssetOptionalStatusDetails:
   newBaseUrl_oem + 'EAP/getAssetOperationalStatus/',
 deleteAssetOperationalStatusDetails:
   newBaseUrl_oem + 'EAP/deleteAssetOperationalStatus/',
 updateAssetOperationalStatusDetails:
   newBaseUrl_oem + 'EAP/updateAssetOperationalStatusInfo/',
 getLegacyAssetInfo: newBaseUrl_oem + 'EAP/solutionAssetInfo/',
 getSellingCostDetails: proxyUrl + 'EAP/getSellingCostDetails/',
 getSitePatrollingDetails: proxyUrl + 'EAP/getPatrollingInfo/',
 deleteSitePatrolingDetails: newBaseUrl_oem + 'EAP/deletePatrollingDetails/',
 updateSitePatrolingDetails: newBaseUrl_oem + 'EAP/updatePatrollingInfo/',
 getAssetPatrollingDetails: proxyUrl + 'EAP/getPatrollingInfo/',
 deleteAssetPatrolingDetails: newBaseUrl_oem + 'EAP/deletePatrollingDetails/',
 updateAssetPatrolingDetails: newBaseUrl_oem + 'EAP/updatePatrollingInfo/',
 siteFeedbackDetails: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
 assetFeedbackDetails: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
 siteIssueCreate: proxyUrl + 'QMS-Gw/createIssue/',
 getSiteIssueData: proxyUrl + 'QMS-Gw/issues/',
 dailyReportDetails: newBaseUrl_oem + 'EAP/dailyReportList/',
 createDailyReport: newBaseUrl_oem + 'EAP/dailyReport/',
 getGroupNutritionData: proxyUrl + 'EAP/getNutritions/',
 addNutritionData: proxyUrl + 'EAP/addNutrition/',
 deleteGroupNutrition: proxyUrl + 'EAP/deleteNutrition/',
 updateGroupNutrition: proxyUrl + 'EAP/updateNutritionInfo/',
 siteAttendanceLocationHistory: newBaseUrl_oem + 'EAP/getAttendanceLocations/',
 siteNewAttendanceLocation: newBaseUrl_oem + 'EAP/addAttendanceLocation/',
 siteDeleteNewAttendanceLocation:
   newBaseUrl_oem + 'EAP/deleteAttendanceLocation/',
 siteUpdateAttendanceLocation:
   newBaseUrl_oem + 'EAP/updateAttendanceLocation/',
 siteGrowRequests: proxyUrl + 'FarmerProxy/growrequest/',
 addGrowRequest: proxyUrl + 'FarmerProxy/growRequest/',
 siteGrowReqStatus: proxyUrl + 'FarmerProxy/updateGrowRequest/',
 siteCancelGrowReqStatus: proxyUrl + 'FarmerProxy/cancelRequest/',
 siteSellRequests: proxyUrl + 'FarmerProxy/sellrequest/',
 siteSellReqStatus: proxyUrl + 'FarmerProxy/updateSellRequest/',
 siteCancelSellReqStatus: proxyUrl + 'FarmerProxy/cancelRequest/',
 siteLoanRequests: proxyUrl + 'FarmerProxy/loanrequest/',
 siteVetVisitRequests: proxyUrl + 'FarmerProxy/vetvisitrequest/',
 siteLoanReqStatus: proxyUrl + 'FarmerProxy/updateLoanRequest/',
 siteCancelLoanReqStatus: proxyUrl + 'FarmerProxy/cancelRequest/',
 siteVetVisitReqStatus: proxyUrl + 'FarmerProxy/updateLoanRequest/',
 siteCancelVetVisitReqStatus: proxyUrl + 'FarmerProxy/cancelRequest/',
 assetIssueCreate: proxyUrl + 'QMS-Gw/createIssue/',
 getAssetIssueData: proxyUrl + 'QMS-Gw/assetIssues/',
 getNodeInfoBasedOnNodeId: proxyUrl + 'EAP/getNodeInfo/',

 getConsumerUserInfoForAD: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 getSellerInfo: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 getAllSites: proxyUrl + 'EAP/allsites/',
 getWhiteLabelAssetsForAD: proxyUrl + 'EAP/getSolutionAssets/',
 createLegacyAsset: proxyUrl + 'EAP/createAsset/',
 createAssetOEM: AgriBusinessRegistryServiceUrl + 'RegisterGateway/registerUser/',
 createConsumerUser: AgriBusinessRegistryServiceUrl + 'RegisterGateway/userCreation/',
 resetConsumerUserPassword: OpUserServiceUrl + 'AuthBackEndGw/createUserCredential/',
 updateConsumerUserInfo: OpUserServiceUrl + 'OpUserService/updateConsumerUserInfo/',
 deleteConsumerUser: AgriBusinessRegistryServiceUrl + 'RegisterGateway/userDeletion/',
 getParentId: OpUserServiceUrl + 'OpUserService/getParentId/',
 getAllDistricts: proxyUrl + 'EAP/allsites/',
 uploadKYC: newBaseUrl_oem + 'Contract-Upload-Gw/uploadDocument/',
 sendEmail: proxyUrl + 'NotificationUA/sendEmail/',
 sendSMS: proxyUrl + 'NotificationUA/sendSms/',
 updateWhiteLabelStatus: proxyUrl + 'TradingGw/updatewhitelabelRequest/',
 updateNodeStatus: proxyUrl + 'EAP/updateNodeStatus/',
 addNodeToFarmer: proxyUrl + 'EAP/nodeSpecification/',
 addGrowerRequest: proxyUrl + 'FarmerProxy/growRequest/',
 addSellRequest: proxyUrl + 'FarmerProxy/sellRequest/',
 addLoanRequest: proxyUrl + 'FarmerProxy/loanRequest/',
 addVetRequest: proxyUrl + 'FarmerProxy/vetvisitRequest/',
 getAssetOEMSofConsumerAdmin:
 OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 customerSupport: newBaseUrl_oem + 'QMS-Gw/settings/',
 getLogisticProviders: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getAssetDistributors: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getAssetSellers: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getAllAssetRequests: proxyUrl + 'TradingGw/getbuyrequests/',
 getClinicAdmins: OpUserServiceUrl + 'OpUserService/getAllConsumerUsers/',
 getAllWhiteLabelRequests: newBaseUrl_oem + 'TradingGw/getwhitelabelrequests/',
 getAssetOEMInfo: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 getLogisticProviderInfo: OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
 getAssetHealthStatus: newBaseUrl_oem + 'AnalyticsProxy/assetHealthStatus/',
 getProcessHealthStatus:
   newBaseUrl_oem + 'AnalyticsProxy/processHealthStatus/',
 getComplaints: newBaseUrl_oem + 'QMS-Gw/getComplaint/',
 getKYCDocuments: proxyUrl + 'ContentGw/contentlist/',
 getAllUnsignedAgreementsForAD: baseUrl + 'ContractGw/getDocument/',
 getAllSignedAgreementsForAD: baseUrl + 'ContractGw/getDocument/',
 trackTheOrder: proxyUrl + 'TradingGw/getbuyrequests/',
 getNodeInfo: proxyUrl + 'EAP/nodeSpecifications/',
 getFarmerNodeInfo: AgriBusinessRegistryServiceUrl + 'AgriBusinessRegistryService/consumerUserRequestInfo/',
 viewNodesOfFarmer: proxyUrl + 'EAP/nodeSpecifications/',
 getRoleProfileBasedOnRoleName: OpUserServiceUrl + 'OpUserService/getResources/',
 createCustomerCare: newBaseUrl_oem + 'QMS-Gw/addSettingsInfo/',
 updateAssetRequestStatusForAd: proxyUrl + 'TradingGw/updateBuyRequest/',
 createRole: OpUserServiceUrl + 'OpUserService/roleCreation/',
 updateCustomerCare: newBaseUrl_oem + 'QMS-Gw/updateSettingsInfo/',
 deleteCustomerCare: newBaseUrl_oem + 'QMS-Gw/deleteSettingsInfo/',
 deleteRole: OpUserServiceUrl + 'OpUserService/deleteConsumerRole/',
 deleteTenderSite: proxyUrl + 'OP-BackEnd-Gateway/opgateway/deletesite/',
 updateRole: OpUserServiceUrl + 'OpUserService/roleUpdate/',
 updateRoleOfUser: OpUserServiceUrl + 'OpUserService/updateMappedConsumerRole/',
 mapRole: OpUserServiceUrl + 'OpUserService/mapUserRole/',
 uploadAgreementForAD: newBaseUrl_oem + 'Contract-Upload-Gw/updDocument/',
 uploadAgreement: proxyUrl + 'Cloud-Storage-Gw/content/',
 getUserJourney: OpUserServiceUrl + 'OpUserService/userJourneyDetails/',
 addAssetOparationalStatusDetails:
   newBaseUrl_oem + 'EAP/addOperationalStatus/',
 addSellingCostDetails: proxyUrl + 'EAP/addSellingCost/',
 addSitePatrolingDetails: newBaseUrl_oem + 'EAP/addPatrollingDetails/',
 addAssetPatrolingDetails: newBaseUrl_oem + 'EAP/addPatrollingDetails/',

 //"getWhiteLabelStatus": newBaseUrl_oem+"TradingGw/whitelabelstatus/",

 getSolutionsIdList:
   newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/solutions/',
 getAssetVerticalsIdList: newBaseUrl_oem + 'EAP/getAssetVerticals/',
 getModelsList: newBaseUrl_oem + 'EAP/models/',
 getCategoryTypesList: newBaseUrl_oem + 'EAP/categoryType/',
 getAssetTypesList: newBaseUrl_oem + 'EAP/assetType/',
 // "getAssetTypes": newBaseUrl_oem+"EAP/assetTypes/", //done by srini
 // "getSitesList": newBaseUrl_oem+"AnalyticsProxy/getsites/",
 getSitesList: newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/sites/',
 getSitesAssetsList: newBaseUrl_oem + 'EAP/siteAssets/',
 getAssetInfo: newBaseUrl_oem + 'EAP/solutionAssetInfo/',
 sendWhitelabelRequest: newBaseUrl_oem + 'TradingGw/whitelabelRequest/',
 getSolutionAssets: newBaseUrl_oem + 'EAP/getSolutionAssets/',
 getSolutionFeedbacks: newBaseUrl_oem + 'QMS-Gw/feedbacks/',
 getSiteComplaints: newBaseUrl_oem + 'QMS-Gw/complaints/',
 getDashboardData: newBaseUrl_oem + 'AnalyticsProxy/getAssetOEMDashboardData/',
 getAssetsCount: newBaseUrl_oem + 'AnalyticsProxy/getAssetsCount/',
 //"getWhiteLabelAsserts": newBaseUrl_oem+"AnalyticsProxy/getWhitelabelAssets/",
 getAssetOEMData: newBaseUrl_oem + 'AnalyticsProxy/getAssetOEMData/',
 getProfitSummary: newBaseUrl_oem + 'AnalyticsProxy/getProfitSummary/',
 getStatementSummary: newBaseUrl_oem + 'AnalyticsProxy/getStatementSummary/',
 getProfitStatementSummary:
   newBaseUrl_oem + 'AnalyticsProxy/getProfitStatementSummary/',
 getAllSolutionsSite:
   newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/solutionSites/',
 getAllAssociatedSolutionSites: newBaseUrl_oem + 'EAP/associatedSites/',
 getWalletBalance: newBaseUrl_oem + 'Wallet-Payment-Gw/walletBalance/',
 getAllSolutions:
   OpUserServiceUrl + 'ActivationService/solutions/',
 getAllAssociatedSolutions: newBaseUrl_oem + 'EAP/associatedAssetDevelopers/',
 getBankDetails: newBaseUrl_oem + 'Wallet-Payment-Gw/bankDetails/',
 // "getKycDocument": newBaseUrl_oem+"ContractGw/getKycDocument/",
 getKycDocument: baseUrl + 'ContractGw/getKycDocument/',
 addBankDetails: newBaseUrl_oem + 'Wallet-Payment-Gw/bankdetails/',

 paymentHistory: newBaseUrl_oem + 'Wallet-Payment-Gw/paymentHistory/',
 registerConsumerUser: baseUrl + 'RegisterGateway/registerConsumerUser/',
 // Done by Srini
 myAssetsList: newBaseUrl_oem + 'EAP/OEMAssets/',
 getSitesDetails:
   newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/getSiteDetails/',
 getAssetDetails: newBaseUrl_oem + 'EAP/assetDetails/',
 getWhiteLabelAssets: newBaseUrl_oem + 'AnalyticsProxy/getsites/', //These are sites only
 getGrowerDetails: ApplicationSpecificGwUrl + 'ApplicationSpecificGw/assetdeveloperDetails/',
 getModelDetails: newBaseUrl_oem + 'AnalyticsProxy/getModelDetails/',
 whiteLabelAssetsBasedonSite:
   newBaseUrl_oem + 'EAP/getReadyforWhitelabelAsset/',
 whiteLabelAssetsBasedonAssetDeveloper:
   newBaseUrl_oem + 'EAP/getReadyforWhitelabelAssets/',
 whiteLabelAssetsBasedonModel:
   newBaseUrl_oem + 'EAP/readyforWhitelabelAssets/',
 addToCart: newBaseUrl_oem + 'TradingGw/investmentCart/',
 findCartItems: newBaseUrl_oem + 'TradingGw/investmentCart/',
 deleteAssetFromCart: newBaseUrl_oem + 'TradingGw/investmentCart/',
 deleteModelFromCart: newBaseUrl_oem + 'TradingGw/investmentCart/',
 userFeedback: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
 siteFeedback: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
 assetFeedback: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
 modelFeedback: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
 schemeFeedback: newBaseUrl_oem + 'QMS-Gw/recordFeedback/',

 uploadKyc: newBaseUrl_oem + 'Contract-Upload-Gw/uploadDocument/',
 uploadAgreement: newBaseUrl_oem + 'Contract-Upload-Gw/updDocument/',
 deleteNominee: newBaseUrl + 'OpUserServer/userserver/deleteNominee/',
 getNomineeList: OpUserServiceUrl + 'OpUserService/nomineeDetails/',
 updateNominee: OpUserServiceUrl + 'OpUserService/updateNomineeDetails/',
 addNominee: OpUserServiceUrl + 'OpUserService/addNominee/',
 updateUserInfo: OpUserServiceUrl + 'OpUserService/updateConsumerUser/',
 updateBankDetails: newBaseUrl_oem + 'Wallet-Payment-Gw/updateBankdetails/',
 getUserImag: OpUserServiceUrl + 'OpUserService/getUserImage/',
 uploadUSerImage: OpUserServiceUrl + 'OpUserService/uploadImage/',
 getInvestedModels: newBaseUrl_oem + 'EAP/investedmodels/',
 addMoneyToWallet: newBaseUrl_oem + 'Wallet-Payment-Gw/moneyToWallet/',
 withdrawMoneyFromWallet:
   newBaseUrl_oem + 'Wallet-Payment-Gw/withdrawMoneyFromWallet/',
 getUnsignedAgreements: baseUrl + 'ContractGw/getDocument/',
 getSignedAgreements: baseUrl + 'ContractGw/getDocument/',
 getOrderList: newBaseUrl_oem + 'TradingGw/oemwhitelabelstatus/',

 preMatureSale: newBaseUrl_oem + 'EAP/updateAssetStatus/',
 createPaymentLink: newBaseUrl_oem + 'Wallet-Payment-Gw/payment_links/',
 makePayment: newBaseUrl_oem + 'TradingGw/schemeWhitelabelRequest/',
 bookNowPayment: newBaseUrl_oem + 'TradingGw/whitelabelRequest/',
 bankStatus: newBaseUrl_oem + 'Wallet-Payment-Gw/getBankStatus/',


/****************************************END OF ABN URLS/ 

    /************************  Website URL's **************************************/
"productBasedonBrand": window.newBaseUrlOEM + 'AgriProductProxy/getproducts/' + ConsumerAdmin,
    "productList":window.newBaseUrlOEM + 'AgriProductProxy/getproductlist/' ,
    "getMobileNumber": OpUserServiceUrl + 'OpUserService/consumerReqInfo/',
    "weatherUpdate": window.proxyUrl+"EAP/getallsites/",
    "getOtp" : window.otpLead+"otp/lead",
    "validateOtp" : window.otpLead+"validateOtp",
    "resendOTP" : window.otpLead+"resendOtp",
    "getsubCategoriesBasedonAdminandCategory" : window.proxyUrl+"ContentGw/getAllContentSubCategories/",
    "PublicAccessToken" : OpUserServiceUrl+"AuthBackEndGw/token/",
    "TokenRelam" : ConsumerAdmin,
    "Relam" : ConsumerAdmin+'/',
    "AssetDeveloperValue" :  AssetDeveloper,
    "getProductsBasedonAdminUser" : window.newBaseUrlOEM+"AgriProductProxy/allproducts/",
    "getProducts" : window.newBaseUrlOEM+"AgriProductProxy/product/",
    "allConsumerUsreswithAssetType" : OpUserServiceUrl+"OpUserService/getAllConsumerUsers/",
    "allConsumerUsres" : OpUserServiceUrl+"OpUserService/getAllConsumerUsers/",
    "getAssetVerticals" : window.newBaseUrlOEM+"EAP/getAssetVerticals/",
    "getCategories" : window.newBaseUrlOEM+"EAP/categoryType/",
    "getAssetType" : window.newBaseUrlOEM+"EAP/assetType/",
    "getModel" :  window.newBaseUrlOEM+"EAP/models/",
    "productLikeCount" : window.newBaseUrlOEM+"AgriProductProxy/updateLikeCount/",
    "getContentCategories" : window.proxyUrl+"ContentGw/getCategories/",
    "getContentList" :  window.proxyUrl+"ContentGw/contentlist/",
    "getAllContentOwnersBasedOnConsumerUser" :  window.proxyUrl+"ContentGw/getAllContentOwners/",
    "getContentListBasedOnConsumerUserandContentOwner" : window.proxyUrl+"ContentGw/contentlist/",
    "getContentBasedOnSiteId" :  window.proxyUrl+"ContentGw/getContent/",
    "getAllSitesBasedOnConsumerUser" : window.proxyUrl+"ContentGw/getAllSites/",
    "getSiteContent" : window.proxyUrl+"ContentGw/getSiteContent/",
    "getContentSubcategories" : window.proxyUrl+"ContentGw/getAllContentSubCategories/",
    "getFarmUpdates": window.proxyUrl+"ContentGw/contentsList/",
    "getProductsBasedonCategoryId" : window.newBaseUrlOEM+"AgriProductProxy/product/",
    // "getProductsBasedonCategoryId" : window.newServerContent+"AgriProductProxy/product/",

    "getFarmUpdatesLists" : window.proxyUrl+"ContentGw/contentsList/",
    "getAssetTypeBasedOnAssetDeveleper" : window.newBaseUrl_oem+"EAP/assetTypes/",
    "getAllContentOwnersBasedOnCategory" : window.proxyUrl+"ContentGw/consumer2/contentowners/",
    "getSubCategoriesBasedonCategoryID" : window.proxyUrl+"ContentGw/",

    "userInfo": OpUserServiceUrl+"OpUserService/consumerReqInfo",
    
    "contentLikeCount" : window.newBaseUrlOEM+"ContentGw/updatelikecount/",
    "getContentCreator" : window.proxyUrl+ "ContentGw/",
    "getContentonContentCreator" : window.proxyUrl+ "ContentGw/contentlist/",
    "sendRequest": AgriBusinessRegistryServiceUrl+"AgriBusinessRegistryService/register/",

"getRoleType" : OpUserServiceUrl + "OpUserService/getRole/",
"getRoleTypeResource" : OpUserServiceUrl + "OpUserService/getResources/",

/************************END OF WEBSITE URL***************************** */



// getGrandParent: window.gbrServiceUrl + 'AuthBackEndGw/getParent/',
// settingsInfoBasedonParent: window.newBaseUrl_oem + 'QMS-Gw/settings/',
// /* invoice genration code */
// createQutation: `${window.invoiceBaseURL}gendocApi/v1/`,
// createPurchaseOrder: `${window.invoiceBaseURL}gendocApi/v1/`,
// createProformaInvoice: `${window.invoiceBaseURL}gendocApi/v1/`,
// createTaxInvoice: `${window.invoiceBaseURL}gendocApi/v1/`,
// /* Market apis */
// DeleteProductItem: window.newBaseUrl_oem + 'AgriProductProxy/productItem/',
// GetProductItems: window.newBaseUrl_oem + 'AgriProductProxy/productItems/',
// ProductVideoURL:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateProductVideoUrl/',
// UpdateLabCertificateURL:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateLabUrl/',
// UpdateHistoryURL:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateHistoryUrl/',
// DecrementItemCount:
//   window.newBaseUrl_oem + 'AgriProductProxy/decrementItemCount/',
// IncrementItemCount:
//   window.newBaseUrl_oem + 'AgriProductProxy/incrementItemCount/',
// UpdateItemCount: window.newBaseUrl_oem + 'AgriProductProxy/updateItemCount/',
// UpdateProductRating:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateRatings/',
// UpdateProductCost:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateProductCost/',
// UpdateProductDiscount:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateDiscountPrice/',
// UpdateProductStatus: window.newBaseUrl_oem + 'AgriProductProxy/updateStatus/',
// UpdateProductQrURL:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateProductQRCode/',
// UpdateProductCertificateURL:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateQAUrl/',
// UpdateProductImageURL:
//   window.newBaseUrl_oem + 'AgriProductProxy/updateProductImageUrl/',
// UploadProductCertificate:
//   window.newBaseUrl_oem + 'AgriProductProxy/uploadProductCertificate/',
// UploadProductContent:
//   window.newBaseUrl_oem + 'AgriProductProxy/uploadProductContent/',
// /* End of market apis */

// getSitesBasedOnEmail: window.newBaseUrl_oem + 'EAP/sitelist/',
// /*Start of logistic-Manager:*/
// getAssetsCountInfo: window.newBaseUrl_oem + 'EAP/getAssetsCount/',
// getDeviceCountInfo:
//   window.newBaseUrl_oem + 'DMBackEndGw/DMDevice/getDevicesCount/',
// getDriversInfo: window.newBaseUrl + 'AuthBackEndGw/getUsersCount/',
// getTripPlannerInfo: window.liveMonitorUrl + 'tripplanner/',
// getLocationInfo: window.liveMonitorUrl + 'tripplanner/',
// getRouteInfo: window.liveMonitorUrl + 'tripplanner/',
// getTripInfo: window.liveMonitorUrl + 'tripplanner/',
// addLocationData: window.liveMonitorUrl + 'tripplanner/',
// getOrderListInfo: window.proxyUrl + 'BookingProxy/allBookings/consumer2/',
// getVehicleList: window.proxyUrl + 'EAP/getSolutionAssets/',
// getDrivers: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getRoute: window.liveMonitorUrl + 'tripplanner/',
// createBooking: window.proxyUrl + 'BookingProxy/createBooking/consumer2/',
// addRouteApi: window.liveMonitorUrl + 'tripplanner/',
// addTripApi: window.liveMonitorUrl + 'tripplanner/',
// getVehicleLocation: window.liveMonitorUrl + 'livemonitoring/data/live/',
// getVehicleTrip: window.liveMonitorUrl + 'tripplanner/',
// geofenceDetails: window.liveMonitorUrl + 'geofence/',
// createGeofence: window.liveMonitorUrl + 'geofence/',
// delLocation: window.liveMonitorUrl + 'tripplanner/',
// getMonthlyTrips: window.liveMonitorUrl + 'tripplanner/',
// delRoute: window.liveMonitorUrl + 'tripplanner/',
// delTrip: window.liveMonitorUrl + 'tripplanner/',
// transportManagerlist:
//   window.newBaseUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getAllUsers: window.newBaseUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getLogisticManagerCount:
//   window.gbrServiceUrl + 'AuthBackEndGw/getUsersCount/',
// getBookingReq: window.proxyUrl + 'BookingProxy/bookingRequest/',
// mapWithTrip: window.proxyUrl + 'BookingProxy/mapBookingIdWithTrip/consumer2/',

// postTripMapRequest:
//   window.window.newBaseUrl_oem + 'BookingProxy/mapBookingIdWithTrip/',
// postTripUnmapReques:
//   window.window.newBaseUrl_oem + 'BookingProxy/unmapBookingIdFromTrip/',
// postDriverMapRequest:
//   window.window.newBaseUrl_oem +
//   'BookingProxy/mapBookingIdWithTransportUser/',
// postDriverUnmapRequest:
//   window.window.newBaseUrl_oem +
//   'BookingProxy/unmapBookingIdFromTransportUser/',
// getBookingDetails:
//   window.window.newBaseUrl_oem + 'BookingProxy/getBookingDetails/',
// /*End of Logistic-Manager    */

// /*start of transportuser*/

// getTripDetailsDriver: window.liveMonitorUrl + 'tripplanner/consumer2/',
// getMyBooking: window.proxyUrl + 'BookingProxy/assignedBookings/consumer2/',
// getDriverSchedule: window.liveMonitorUrl + 'tripplanner/consumer2/',
// getDriverOrder: window.newBaseUrl_oem + `BookingProxy/getOrders/consumer2/`,
// getAllOrderCount:
//   window.newBaseUrl_oem + `BookingProxy/getOrdersCount/consumer2/`,
// getMyorderDetails:
//   window.newBaseUrl_oem + `TradingGw/getBuyRequestStatus/consumer2/`,
// PostMyOrderStatus:
//   window.newBaseUrl_oem + `TradingGw/updateLegacyAssetOrderStatus/consumer2/`,
// getMyRoute: window.liveMonitorUrl + `tripplanner/consumer2/`,
// getUserType: window.newBaseUrl + `AuthBackEndGw/getUserType/`,
// getWeeklyDriverSchedule: window.liveMonitorUrl + 'tripplanner/consumer2/',
// /*end of transportuser*/
// generateSiteQrCode:
//   window.newBaseUrl_oem + 'QR-Code-Proxy/generateSite/consumer2/',
// /* Start of product apis */
// AgriProductProxey: window.newBaseUrl_oem + 'AgriProductProxy/productInfo/',
// AgriProductProxeyItem:
//   window.newBaseUrl_oem + 'AgriProductProxy/productItem/',
// AgriProductProxeyDelete: window.newBaseUrl_oem + 'AgriProductProxy/product/',
// GetAgriProductProxeyData:
//   window.newBaseUrl_oem + 'AgriProductProxy/products/',
// getAllAssetsBasedonModel: window.proxyUrl + 'EAP/getSolutionAssets/',
// GetAgriProductProxeyDataBasedOnModel:
//   window.newBaseUrl_oem + 'AgriProductProxy/allproducts/',
// /* end of product apis */
// siteVetVisitRequests: window.proxyUrl + 'FarmerProxy/vetvisitrequest/',
// siteEnv: window.proxyUrl + 'IOB-Proxy/api/v1/consumer2/solution/',
// operatorOption: window.proxyUrl + 'IOB-Proxy/api/v1/consumer2/solution/',
// marketOwners: window.proxyUrl + 'EAP/getallsites/',
// marketPriceRequests: window.proxyUrl + 'ContentGw/contentlist/',
// AssetCertifiedConsultant:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// contentInfoBasedOnRequestId:
//   window.proxyUrl + 'ContentGw/contentInformation/',
// updateNode: window.newBaseUrl_oem + 'EAP/update/node/',
// deleteNode: window.newBaseUrl_oem + 'EAP/delete/node/',
// assetReports: window.newBaseUrl_oem + 'QMS-Gw/assetReports/',
// getHealthStatusBasedOnAssetId:
//   window.newBaseUrl_oem + 'AnalyticsProxy/assetHealthStatus/',
// getAttendanceLocationInfo:
//   window.newBaseUrl_oem + 'EAP/getAttendanceLocationInfo/',
// assetFeedbacks: window.newBaseUrl_oem + 'QMS-Gw/assetFeedbacks/',
// getMortalityInfo: window.proxyUrl + 'EAP/getMortalityInfo/',
// getSiteEnvInfo: window.liveEnvUrl + 'ems/livedata/',
// getAssetEnvInfo: window.liveEnvUrl + 'ems/livedata/',
// unmapExistingResourceFromRole:
//   window.gbrServiceUrl + 'AuthBackEndGw/deleteResource/',
// getSHG: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// communityUsersInfo: window.newBaseUrl + 'RegisterGateway/userInfo/',
// assetCurrentLocation:
//   window.assetCurrentLocationUrl + 'livemonitoring/data/live/',
// getGlobalUsers: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',

// recordReport: window.newBaseUrl_oem + 'QMS-Gw/recordReport/',
// recordCompliance: window.proxyUrl + 'QMS-Gw/recordCompliance/',
// addAssetMortality: window.proxyUrl + 'EAP/addMortalityDetails/',
// getAssetMortality: window.proxyUrl + 'EAP/getMortalityDetails/',
// settingsInfo: window.newBaseUrl_oem + 'QMS-Gw/getSettingsInfo/',

// getAllUserTypes: window.gbrServiceUrl + 'AuthBackEndGw/getConsumerUserTypes/',
// // getConsumerUserTypeRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
// getConsumerUserTypeRoleProfile:
//   window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
// getConsumerUserTypeRole:
//   window.gbrServiceUrl + 'AuthBackEndGw/getRoleKeycloak/',
// addAssetAlarm: window.newBaseUrl_oem + 'AdvisoryProxy/assetalarms/',
// getAssetAlarms: window.newBaseUrl_oem + 'AdvisoryProxy/assetalarms/',
// addSiteAlarm: window.newBaseUrl_oem + 'AdvisoryProxy/sitealarms/',
// getSiteAlarms: window.newBaseUrl_oem + 'AdvisoryProxy/sitealarms/',
// getQrCodeForAsset: window.newBaseUrl_oem + 'QR-Code-Proxy/assetqrcode/',
// addAssetFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
// dailyReport: window.newBaseUrl_oem + 'EAP/dailyReport/',
// addPatrol: window.proxyUrl + 'EAP/addPatrollingDetails/',
// addAssetReport: window.newBaseUrl_oem + 'EAP/dailyReport/',
// addAssetHealth: window.proxyUrl + 'EAP/updateHealthRecord/',
// createAssetIssue: window.newBaseUrl_oem + 'QMS-Gw/createIssue/',
// getAssetIssues: window.newBaseUrl_oem + 'QMS-Gw/assetIssues/',
// addAttendanceLocation: window.proxyUrl + 'EAP/addAttendanceLocation/',
// getExecutiveDashboardData:
//   window.newBaseUrl_oem + 'AnalyticsProxy/getDashboardData/',
// getProcessHealthStatusExe:
//   window.newBaseUrl_oem + 'AnalyticsProxy/getProcessHealthStatus/',
// getAssetHealthStatusExe:
//   window.newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetsHealthStatus/',
// getAssetStatisticsExe:
//   window.newBaseUrl_oem + 'AnalyticsProxy/getExecutiveAssetStatistics/',

// getSiteReports: window.newBaseUrl_oem + 'QMS-Gw/reports/',
// createSiteIssue: window.newBaseUrl_oem + 'QMS-Gw/createIssue/',
// siteIssue: window.proxyUrl + 'QMS-Gw/issues/',
// getRoleResourceAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
// getRoleResources: window.newBaseUrl + 'AuthBackEndGw/getResources/',
// getRoleNameofOperator: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
// updateOperatorProfileInfo:
//   window.gbrServiceUrl + 'AuthBackEndGw/updateConsumerUser/',
// GenerateProductQRCode:
//   window.newBaseUrl_oem + 'QR-Code-Proxy/generateProduct/',
// generateQrCode: window.qrcodeBaseUrl + 'generate',
// generateQrCodeWithId: window.qrcodeBaseUrl + 'generateWithId',
// generateBulkQrCodes: window.qrcodeBaseUrl + 'generateBulk',
// downloadQrCode: window.qrcodeBaseUrl + 'download?file=',
// createAccessToken: window.gbrServiceUrl + 'AuthBackEndGw/token/',
// getToken: window.newBaseUrl + 'AuthBackEndGw/getUserToken/',
// resetUserPassword: window.newBaseUrl + 'AuthBackEndGw/resetPassword/',
// forgetUsername: window.newBaseUrl + 'AuthBackEndGw/forgetUsername/',
// forgetPassword: window.newBaseUrl + 'AuthBackEndGw/forgetPassword/',
// registerUser: window.newBaseUrl + 'RegisterGateway/registerUser/',
// consumerRequestInfo:
//   window.sendRequestUrl + 'RegisterGateway/consumerRequestInfo/',
// 'getUserType:': window.newBaseUrl + 'AuthBackEndGw/getUserType/',
// getConsumerUserType: window.newBaseUrl + 'AuthBackEndGw/getUserType/',
// getStepperCounter:
//   window.gbrServiceUrl + 'RegisterGateway/userJourneyDetails/',
// getAllAssetsOfAssetDeveloper: window.proxyUrl + 'EAP/getSolutionAssets/',
// getAllRoles: window.gbrServiceUrl + 'AuthBackEndGw/roles/',
// getAllConsumerUsers: window.gbrServiceUrl + 'AuthBackEndGw/consumerUser/',
// getMappedRoles: window.gbrServiceUrl + 'AuthBackEndGw/getUsersRoles/',
// viewAllSites: window.proxyUrl + 'EAP/allsites/',
// deleteContent: window.newBaseUrl_oem + 'ContentGw/content/',
// createMapping: window.newBaseUrl_oem + 'EAP/maplegacyassettosite/',
// createUnMapping: window.newBaseUrl_oem + 'EAP/unmapSiteAsset/',
// assetStatusUpdate: window.proxyUrl + 'EAP/updateAssetStatus/',
// assetDelete: window.proxyUrl + 'EAP/deleteAsset/',
// getAllSiteTypes: window.proxyUrl + 'EAP/getSiteTypes/',
// getUnsignedAgreements: window.proxyUrl + 'ContentGw/contentList/',
// viewAllTenderSites: window.proxyUrl + 'EAP/allsites/',
// mappedTenderLegacyAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
// getAllTenderAssetsData: window.proxyUrl + 'EAP/getSolutionAssets/',
// assetMapingtoTenderSite: window.newBaseUrl_oem + 'EAP/maplegacyassettosite/',
// getAllTenderSiteTypes: window.proxyUrl + 'EAP/getSiteTypes/',
// createTenderSite:
//   window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/createsite/',
// createSiteWithNodeId:
//   window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/sitecreationinfo/',
// viewListOfOperators:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
// getRoleResourceId: window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
// getAssetsOfSite: window.proxyUrl + 'EAP/siteAssets/',
// viewListOfDeveloperQA:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getDevRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
// getDevRoleResourceId: window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
// getDevAssetsOfSite: window.proxyUrl + 'EAP/siteAssets/',
// viewListOfExecutives:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getConsumerInfoRequest: window.newBaseUrl + 'RegisterGateway/userInfo/',
// 'Production-Team':
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getTeamRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
// getTeamRoleResourceId: window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
// viewListProductionManagers:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// viewListOfProductionTeams:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getProductManagerRole: window.gbrServiceUrl + 'AuthBackEndGw/getRole/',
// getRoleProductionManagersResourceId:
//   window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
// viewListOfFarmers:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getFarmerNodes: window.proxyUrl + 'EAP/nodeSpecifications/',
// allApprovalRequests: window.proxyUrl + 'FarmerProxy/approvalrequest/',
// allGrowRequests: window.proxyUrl + 'FarmerProxy/growrequest/',
// allSellRequests: window.proxyUrl + 'FarmerProxy/sellrequest/',
// allLoanRequests: window.proxyUrl + 'FarmerProxy/loanrequest/',
// allVetVisitRequests: window.proxyUrl + 'FarmerProxy/vetvisitrequest/',
// allBuyInputRequests: window.proxyUrl + 'FarmerProxy/buyinputrequest/',
// allStoreRequests: window.proxyUrl + 'FarmerProxy/storerequest/',
// allAdvisoryRequests: window.proxyUrl + 'FarmerProxy/advisoryrequest/',
// setNodeStatus: window.proxyUrl + 'EAP/updateNodeStatus/',
// viewFarmerInfo:
//   window.newBaseUrl + 'RegisterGateway/consumerUserRequestInfo/',
// approvalReqStatus: window.proxyUrl + 'FarmerProxy/updateApprovalRequest/',
// deleteContentSubCategory:
//   window.proxyUrl + 'ContentGw/deleteContentSubCategory/',
// growReqStatus: window.proxyUrl + 'FarmerProxy/updateGrowRequest/',
// sellReqStatus: window.proxyUrl + 'FarmerProxy/updateSellRequest/',
// LoanReqStatus: window.proxyUrl + 'FarmerProxy/updateLoanRequest/',
// VetReqStatus: window.proxyUrl + 'FarmerProxy/updateVetVisitRequest/',
// BuyReqStatus: window.proxyUrl + 'FarmerProxy/updateBuyInputRequest/',
// StoreReqStatus: window.proxyUrl + 'FarmerProxy/updateStoreRequest/',
// viewSettingsInfo: window.newBaseUrl_oem + 'QMS-Gw/getSettingsInfo/',
// mappedLegacyAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
// getAllAssetsData: window.proxyUrl + 'EAP/getSolutionAssets/',
// assetMapingtoSite: window.newBaseUrl_oem + 'EAP/maplegacyassettosite/',
// siteDeleteHandler:
//   window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/deletesite/',
// siteRating: window.newBaseUrl_oem + 'QMS-Gw/ratings/',
// createSite: window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/sitecreation/',
// addSiteRating: window.newBaseUrl_oem + 'QMS-Gw/ratings/',
// siteInfo: window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/siteInfo/',
// updateSiteInfo: window.proxyUrl + 'EAP/updateSiteInfo/',
// viewAllFarms: window.proxyUrl + 'EAP/getallsites/',
// viewAllFarmRelatedAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
// viewFarmMapRelatedAssets: window.newBaseUrl_oem + 'EAP/siteAssets/',
// farmsDropDownList: window.proxyUrl + 'EAP/getallsites/',
// getConsumerNodeInfo: window.proxyUrl + 'EAP/nodeSpecifications/',
// updateConsumerNodeStatus: window.proxyUrl + 'EAP/updateNodeStatus/',
// viewNodeStatus: window.proxyUrl + 'EAP/getNodeStatus/',
// createConsumerUserOfIndividual:
//   window.gbrServiceUrl + 'AuthBackEndGw/userCreation/',
// deleteConsumerUserIndividual:
//   window.gbrServiceUrl + 'AuthBackEndGw/userDeletion/',
// cancelGrowRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// cancelSellRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// cancelLoanRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// cancelVetRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// cancelBuyInputRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// cancelStoreRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// cancelAdvisoryRequest: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// resetConsumerUserPasswordIndividual:
//   window.newBaseUrl + 'AuthBackEndGw/createCredential/',
// updateConsumerUserInfoIndividual:
//   window.gbrServiceUrl + 'AuthBackEndGw/updateConsumerUserInfo/',
// ViewAssetOnboardings: window.newBaseUrl_oem + 'EAP/getReadyForOnboardAssets/',
// updateReadyForOnboardAssetStatus:
//   window.newBaseUrl_oem + 'EAP/updateAssetStatus/',
// AttendanceLocationHistory:
//   window.newBaseUrl_oem + 'EAP/getAttendanceLocations/',
// NewAttendanceLocation: window.newBaseUrl_oem + 'EAP/addAttendanceLocation/',
// DeleteNewAttendanceLocation:
//   window.newBaseUrl_oem + 'EAP/deleteAttendanceLocation/',
// UpdateAttendanceLocation:
//   window.newBaseUrl_oem + 'EAP/updateAttendanceLocation/',
// getBirthDetails: window.newBaseUrl_oem + 'EAP/getBirthInfos/',
// addBirthInfo: window.newBaseUrl_oem + 'EAP/addBirthInfo/',
// deleteBirthInfo: window.newBaseUrl_oem + 'EAP/deleteBirthInfo/',
// updateBirthInfo: window.newBaseUrl_oem + 'EAP/updateBirthInfo/',
// getHealthRecords: window.newBaseUrl_oem + 'EAP/getHealthRecord/',
// addHealthRecords: window.newBaseUrl_oem + 'EAP/addHealthRecord/',
// deleteHealthRecord: window.newBaseUrl_oem + 'EAP/deleteHealthRecord/',
// updateHealthRecord: window.newBaseUrl_oem + 'EAP/updateHealthRecord/',
// getNutrition: window.newBaseUrl_oem + 'EAP/getNutritions/',
// addNutrition: window.newBaseUrl_oem + 'EAP/addNutrition/',
// deleteNutrition: window.newBaseUrl_oem + 'EAP/deleteNutrition/',
// updateNutrition: window.newBaseUrl_oem + 'EAP/updateNutritionInfo/',
// getWeighingDetails: window.newBaseUrl_oem + 'EAP/getWeighingDetails/',
// addWeighingDetails: window.newBaseUrl_oem + 'EAP/addWeighingDetails/',
// deleteWeighingDetails: window.newBaseUrl_oem + 'EAP/deleteWeighingDetails/',
// updateWeighingDetails: window.newBaseUrl_oem + 'EAP/updateWeighingDetails/',
// getParturitionDetails: window.newBaseUrl_oem + 'EAP/getParturitionDetails/',
// addParturitionDetails: window.newBaseUrl_oem + 'EAP/addParturitionDetails/',
// deleteParturitionDetails:
//   window.newBaseUrl_oem + 'EAP/deleteParturitionDetails/',
// updateParturitionDetails:
//   window.newBaseUrl_oem + 'EAP/updateParturitionInfo/',
// getMortalityDetails: window.newBaseUrl_oem + 'EAP/getMortalityDetails',
// addMortalityDetails: window.newBaseUrl_oem + 'EAP/addMortalityDetails',
// deleteMortalityDetails: window.newBaseUrl_oem + 'EAP/deleteMortalityDetails',
// updateMortalityDetails: window.newBaseUrl_oem + 'EAP/updateMortalityInfo',
// getAssetOptionalStatusDetails:
//   window.newBaseUrl_oem + 'EAP/getAssetOperationalStatus',
// deleteAssetOperationalStatusDetails:
//   window.newBaseUrl_oem + 'EAP/deleteAssetOperationalStatus',
// updateAssetOperationalStatusDetails:
//   window.newBaseUrl_oem + 'EAP/updateAssetOperationalStatusInfo',
// getLegacyAssetInfo: window.newBaseUrl_oem + 'EAP/solutionAssetInfo/',
// getSellingCostDetails: window.proxyUrl + 'EAP/getSellingCostDetails/',
// getSitePatrollingDetails: window.newBaseUrl_oem + 'EAP/getPatrollingDetails/',
// deleteSitePatrolingDetails:
//   window.newBaseUrl_oem + 'EAP/deletePatrollingDetails/',
// updateSitePatrolingDetails:
//   window.newBaseUrl_oem + 'EAP/updatePatrollingInfo/',
// getAssetPatrollingDetails:
//   window.newBaseUrl_oem + 'EAP/getPatrollingDetails/',
// deleteAssetPatrolingDetails:
//   window.newBaseUrl_oem + 'EAP/deletePatrollingDetails/',
// updateAssetPatrolingDetails:
//   window.newBaseUrl_oem + 'EAP/updatePatrollingInfo/',
// siteFeedbackDetails: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
// assetFeedbackDetails: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
// siteIssueCreate: window.proxyUrl + 'QMS-Gw/createIssue/',
// getSiteIssueData: window.proxyUrl + 'QMS-Gw/issues/',
// dailyReportDetails: window.newBaseUrl_oem + 'EAP/dailyReportList/',
// createDailyReport: window.newBaseUrl_oem + 'EAP/dailyReport/',
// getGroupNutritionData: window.proxyUrl + 'EAP/getNutritions/',
// addNutritionData: window.proxyUrl + 'EAP/addNutrition/',
// deleteGroupNutrition: window.proxyUrl + 'EAP/deleteNutrition/',
// updateGroupNutrition: window.proxyUrl + 'EAP/updateNutritionInfo/',
// siteAttendanceLocationHistory:
//   window.newBaseUrl_oem + 'EAP/getAttendanceLocations/',
// siteNewAttendanceLocation:
//   window.newBaseUrl_oem + 'EAP/addAttendanceLocation/',
// siteDeleteNewAttendanceLocation:
//   window.newBaseUrl_oem + 'EAP/deleteAttendanceLocation/',
// siteUpdateAttendanceLocation:
//   window.newBaseUrl_oem + 'EAP/updateAttendanceLocation/',
// siteGrowRequests: window.proxyUrl + 'FarmerProxy/growrequest/',
// addGrowRequest: window.proxyUrl + 'FarmerProxy/growRequest/',
// siteGrowReqStatus: window.proxyUrl + 'FarmerProxy/updateGrowRequest/',
// siteCancelGrowReqStatus: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// siteSellRequests: window.proxyUrl + 'FarmerProxy/sellrequest/',
// siteSellReqStatus: window.proxyUrl + 'FarmerProxy/updateSellRequest/',
// siteCancelSellReqStatus: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// siteLoanRequests: window.proxyUrl + 'FarmerProxy/loanrequest/',
// siteLoanReqStatus: window.proxyUrl + 'FarmerProxy/updateLoanRequest/',
// siteCancelLoanReqStatus: window.proxyUrl + 'FarmerProxy/cancelRequest/',
// assetIssueCreate: window.proxyUrl + 'QMS-Gw/createIssue/',
// getAssetIssueData: window.proxyUrl + 'QMS-Gw/assetIssues/',
// getNodeInfoBasedOnNodeId: window.proxyUrl + 'EAP/getNodeInfo/',
// getMyCategoryTender: window.proxyUrl + 'ContentGw/contentlist/',
// getListOfCategorys: window.proxyUrl + 'ContentGw/getCategories/',
// getCategoryRelatedSubCategories:
//   window.proxyUrl + 'ContentGw/getAllContentSubCategories/',

// getConsumerUserInfoForAD: window.newBaseUrl + 'RegisterGateway/userInfo/',
// getSellerInfo: window.newBaseUrl + 'RegisterGateway/userInfo/',
// getAllSites: window.proxyUrl + 'EAP/allsites/',
// getCategory: window.proxyUrl + 'ContentGw/getCategoryId/',
// getSubCategoriesForAD:
//   window.proxyUrl + 'ContentGw/getAllContentSubCategories/',
// getAllContentsList: window.proxyUrl + 'ContentGw/contentList/',
// getCategoryTender: window.proxyUrl + 'ContentGw/contentlist/',
// getTendersByDistricts: window.proxyUrl + 'ContentGw/getSiteContent/',
// getTendersByOrganization: window.proxyUrl + 'ContentGw/contentList/',
// getWhiteLabelAssetsForAD: window.proxyUrl + 'EAP/getSolutionAssets/',
// createLegacyAsset: window.proxyUrl + 'EAP/createAsset/',
// createContentSubCategory: window.proxyUrl + 'ContentGw/contentsubcategory/',
// createAssetOEM: window.newBaseUrl + 'RegisterGateway/registerUser/',
// createConsumerUser: window.gbrServiceUrl + 'AuthBackEndGw/userCreation/',
// resetConsumerUserPassword:
//   window.gbrServiceUrl + 'AuthBackEndGw/createUserCredential/',
// updateConsumerUserInfo:
//   window.newBaseUrl + 'AuthBackEndGw/updateConsumerUserInfo/',
// deleteConsumerUser: window.gbrServiceUrl + 'AuthBackEndGw/userDeletion/',
// getParentId: window.gbrServiceUrl + 'AuthBackEndGw/getParentId/',
// getAllDistricts: window.proxyUrl + 'EAP/allsites/',
// createContent: window.proxyUrl + 'ContentGw/content/',
// uploadKYC: window.newBaseUrl_oem + 'Contract-Upload-Gw/uploadDocument/',
// sendEmail: window.proxyUrl + 'NotificationUA/sendEmail/',
// sendSMS: window.proxyUrl + 'NotificationUA/sendSms/',
// updateWhiteLabelStatus:
//   window.proxyUrl + 'TradingGw/updatewhitelabelRequest/',
// updateNodeStatus: window.proxyUrl + 'EAP/updateNodeStatus/',
// addNodeToFarmer: window.proxyUrl + 'EAP/nodeSpecification/',
// addGrowerRequest: window.proxyUrl + 'FarmerProxy/growRequest/',
// addSellRequest: window.proxyUrl + 'FarmerProxy/sellRequest/',
// addLoanRequest: window.proxyUrl + 'FarmerProxy/loanRequest/',
// addVetRequest: window.proxyUrl + 'FarmerProxy/vetvisitRequest/',
// addBuyInputRequest: window.proxyUrl + 'FarmerProxy/buyinputRequest/',
// addStoreRequest: window.proxyUrl + 'FarmerProxy/storeRequest/',
// addAdvisoryRequest: window.proxyUrl + 'FarmerProxy/advisoryRequest/',
// getAssetOEMSofConsumerAdmin:
//   window.newBaseUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// customerSupport: window.newBaseUrl_oem + 'QMS-Gw/settings/',
// getLogisticProviders:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getAssetDistributors:
//   window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getAssetSellers: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getAllAssetRequests: window.proxyUrl + 'TradingGw/getbuyrequests/',
// getClinicAdmins: window.gbrServiceUrl + 'AuthBackEndGw/getAllConsumerUsers/',
// getAllWhiteLabelRequests:
//   window.newBaseUrl_oem + 'TradingGw/getwhitelabelrequests/',
// getAssetOEMInfo: window.newBaseUrl + 'RegisterGateway/consumerRequestInfo/',
// getLogisticProviderInfo: window.newBaseUrl + 'RegisterGateway/userInfo/',
// getAssetHealthStatus:
//   window.newBaseUrl_oem + 'AnalyticsProxy/assetHealthStatus/',
// getProcessHealthStatus:
//   window.newBaseUrl_oem + 'AnalyticsProxy/processHealthStatus/',
// getComplaints: window.newBaseUrl_oem + 'QMS-Gw/getComplaint/',
// getKYCDocuments: window.newBaseUrl_oem + 'ContractGw/getKycDocument/',
// getAllUnsignedAgreementsForAD:
//   window.newBaseUrl_oem + 'ContractGw/getDocument/',
// getAllSignedAgreementsForAD:
//   window.newBaseUrl_oem + 'ContractGw/getDocument/',
// trackTheOrder: window.proxyUrl + 'TradingGw/getbuyrequests/',
// getNodeInfo: window.proxyUrl + 'EAP/nodeSpecifications/',
// getFarmerNodeInfo:
//   window.newBaseUrl + 'RegisterGateway/consumerUserRequestInfo/',
// viewNodesOfFarmer: window.proxyUrl + 'EAP/getNodeSpecification/',
// getRoleProfileBasedOnRoleName:
//   window.gbrServiceUrl + 'AuthBackEndGw/getResources/',
// createCustomerCare: window.newBaseUrl_oem + 'QMS-Gw/addSettingsInfo/',
// updateAssetRequestStatusForAd:
//   window.proxyUrl + 'TradingGw/updateBuyRequest/',
// createRole: window.gbrServiceUrl + 'AuthBackEndGw/roleCreation/',
// updateCustomerCare: window.newBaseUrl_oem + 'QMS-Gw/updateSettingsInfo/',
// deleteCustomerCare: window.newBaseUrl_oem + 'QMS-Gw/deleteSettingsInfo/',
// deleteRole: window.gbrServiceUrl + 'AuthBackEndGw/deleteConsumerRole/',
// deleteTenderSite:
//   window.proxyUrl + 'OP-BackEnd-Gateway/opgateway/deletesite/',
// updateRole: window.gbrServiceUrl + 'AuthBackEndGw/roleUpdate/',
// updateRoleOfUser:
//   window.gbrServiceUrl + 'AuthBackEndGw/updateMappedConsumerRole/',
// mapRole: window.gbrServiceUrl + 'AuthBackEndGw/mapUserRole/',
// uploadAgreementForAD:
//   window.newBaseUrl_oem + 'Contract-Upload-Gw/updDocument/',
// uploadAgreement: window.proxyUrl + 'Cloud-Storage-Gw/content/',
// getFarmUpdates: window.proxyUrl + 'ContentGw/contentList/',
// getFarmUpdatesFromAll: window.proxyUrl + 'ContentGw/contentsList/',
// getCategoriesBasedOnOEM: window.proxyUrl + 'ContentGw/getAllCategories/',
// getSubCategoriesForOEM:
//   window.proxyUrl + 'ContentGw/getAllContentSubCategories/',
// getUserJourney: window.gbrServiceUrl + 'RegisterGateway/userJourneyDetails/',
// addAssetOparationalStatusDetails:
//   window.newBaseUrl_oem + 'EAP/addOperationalStatus/',
// addSellingCostDetails: window.proxyUrl + 'EAP/addSellingCost/',
// addSitePatrolingDetails: window.newBaseUrl_oem + 'EAP/addPatrollingDetails/',
// addAssetPatrolingDetails: window.newBaseUrl_oem + 'EAP/addPatrollingDetails/',

// //"getWhiteLabelStatus": window.newBaseUrl_oem+"TradingGw/whitelabelstatus/",

// getSolutionsIdList:
//   window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/solutions/',
// getAssetVerticalsIdList: window.newBaseUrl_oem + 'EAP/getAssetVerticals/',
// getModelsList: window.newBaseUrl_oem + 'EAP/models/',
// getCategoryTypesList: window.newBaseUrl_oem + 'EAP/categoryType/',
// getAssetTypesList: window.newBaseUrl_oem + 'EAP/assetType/',
// // "getAssetTypes": window.newBaseUrl_oem+"EAP/assetTypes/", //done by srini
// // "getSitesList": window.newBaseUrl_oem+"AnalyticsProxy/getsites/",
// getSitesList: window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/sites/',
// getSitesAssetsList: window.newBaseUrl_oem + 'EAP/siteAssets/',
// getAssetInfo: window.newBaseUrl_oem + 'EAP/solutionAssetInfo/',
// sendWhitelabelRequest: window.newBaseUrl_oem + 'TradingGw/whitelabelRequest/',
// getSolutionAssets: window.newBaseUrl_oem + 'EAP/getSolutionAssets/',
// getSolutionFeedbacks: window.newBaseUrl_oem + 'QMS-Gw/feedbacks/',
// getSiteComplaints: window.newBaseUrl_oem + 'QMS-Gw/complaints/',
// getDashboardData:
//   window.newBaseUrl_oem + 'AnalyticsProxy/getAssetOEMDashboardData/',
// getAssetsCount: window.newBaseUrl_oem + 'AnalyticsProxy/getAssetsCount/',
// //"getWhiteLabelAsserts": window.newBaseUrl_oem+"AnalyticsProxy/getWhitelabelAssets/",
// getAssetOEMData: window.newBaseUrl_oem + 'AnalyticsProxy/getAssetOEMData/',
// getProfitSummary: window.newBaseUrl_oem + 'AnalyticsProxy/getProfitSummary/',
// getStatementSummary:
//   window.newBaseUrl_oem + 'AnalyticsProxy/getStatementSummary/',
// getProfitStatementSummary:
//   window.newBaseUrl_oem + 'AnalyticsProxy/getProfitStatementSummary/',
// getAllSolutionsSite:
//   window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/solutionSites/',
// getAllAssociatedSolutionSites: window.newBaseUrl_oem + 'EAP/associatedSites/',
// getWalletBalance: window.newBaseUrl_oem + 'Wallet-Payment-Gw/walletBalance/',
// getAllSolutions:
//   window.newBaseUrl_oem +
//   'OP-BackEnd-Gateway/opgateway/enterprise/allSolutions/',
// getAllAssociatedSolutions:
//   window.newBaseUrl_oem + 'EAP/associatedAssetDevelopers/',
// getBankDetails: window.newBaseUrl_oem + 'Wallet-Payment-Gw/bankDetails/',
// // "getKycDocument": window.newBaseUrl_oem+"ContractGw/getKycDocument/",
// getKycDocument: window.newBaseUrl_oem + 'ContractGw/getKycDocument/',
// addBankDetails: window.newBaseUrl_oem + 'Wallet-Payment-Gw/bankdetails/',

// paymentHistory: window.newBaseUrl_oem + 'Wallet-Payment-Gw/paymentHistory/',
// // "getFarmUpdates": window.newBaseUrl_oem+"ContentGw/getapps/",
// registerConsumerUser:
//   window.newBaseUrl + 'RegisterGateway/registerConsumerUser/',
// // Done by Srini
// myAssetsList: window.newBaseUrl_oem + 'EAP/OEMAssets/',
// getSitesDetails:
//   window.newBaseUrl_oem + 'OP-BackEnd-Gateway/opgateway/getSiteDetails/',
// getAssetDetails: window.newBaseUrl_oem + 'EAP/assetDetails/',
// getWhiteLabelAssets: window.newBaseUrl_oem + 'AnalyticsProxy/getsites/', //These are sites only
// getGrowerDetails:
//   window.newBaseUrl + 'RegisterGateway/assetdeveloperDetails/',
// getModelDetails: window.newBaseUrl_oem + 'AnalyticsProxy/getModelDetails/',
// whiteLabelAssetsBasedonSite:
//   window.newBaseUrl_oem + 'EAP/getReadyforWhitelabelAsset/',
// whiteLabelAssetsBasedonAssetDeveloper:
//   window.newBaseUrl_oem + 'EAP/getReadyforWhitelabelAssets/',
// whiteLabelAssetsBasedonModel:
//   window.newBaseUrl_oem + 'EAP/readyforWhitelabelAssets/',
// addToCart: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
// findCartItems: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
// deleteAssetFromCart: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
// deleteModelFromCart: window.newBaseUrl_oem + 'TradingGw/investmentCart/',
// getApps: window.newBaseUrl_oem + 'ContentGw/getapps/',
// assetDeveloperFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
// siteFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
// assetFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
// modelFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',
// schemeFeedback: window.newBaseUrl_oem + 'QMS-Gw/recordFeedback/',

// uploadKyc: window.newBaseUrl_oem + 'Contract-Upload-Gw/uploadDocument/',
// uploadAgreement: window.newBaseUrl_oem + 'Contract-Upload-Gw/updDocument/',
// likeCount: window.newBaseUrl_oem + 'ContentGw/updatelikecount/',
// deleteNominee: window.newBaseUrl + 'OpUserServer/userserver/deleteNominee/',
// getNomineeList: window.newBaseUrl + 'AuthBackEndGw/nomineeDetails/',
// updateNominee: window.newBaseUrl + 'AuthBackEndGw/updateNomineeDetails/',
// addNominee: window.newBaseUrl + 'AuthBackEndGw/addNominee/',
// updateUserInfo: window.newBaseUrl + 'AuthBackEndGw/updateConsumerUser/',
// updateBankDetails:
//   window.newBaseUrl_oem + 'Wallet-Payment-Gw/updateBankdetails/',
// getUserImag: window.newBaseUrl + 'AuthBackEndGw/getUserImage/',
// uploadUSerImage: window.newBaseUrl + 'AuthBackEndGw/uploadImage/',
// getInvestedModels: window.newBaseUrl_oem + 'EAP/investedmodels/',
// addMoneyToWallet: window.newBaseUrl_oem + 'Wallet-Payment-Gw/moneyToWallet/',
// withdrawMoneyFromWallet:
//   window.newBaseUrl_oem + 'Wallet-Payment-Gw/withdrawMoneyFromWallet/',
// getUnsignedAgreements: window.newBaseUrl_oem + 'ContractGw/getDocument/',
// getSignedAgreements: window.newBaseUrl_oem + 'ContractGw/getDocument/',
// getOrderList: window.newBaseUrl_oem + 'TradingGw/oemwhitelabelstatus/',

// preMatureSale: window.newBaseUrl_oem + 'EAP/updateAssetStatus/',
// createPaymentLink: window.newBaseUrl_oem + 'Wallet-Payment-Gw/payment_links/',
// makePayment: window.newBaseUrl_oem + 'TradingGw/schemeWhitelabelRequest/',
// bookNowPayment: window.newBaseUrl_oem + 'TradingGw/whitelabelRequest/',
// bankStatus: window.newBaseUrl_oem + 'Wallet-Payment-Gw/getBankStatus/',
}

window.googleAnalytics = {
    accountId: 185833573,
    accountName: 'Unaas',
    propertyId: 256711934,
    propertyName : 'Test-Property'
}
