import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./AgribusinessMain.css";
import AgribusinessMainSection from "./AgribusinessMainSection";

export default function AgribusinessMain() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        bannerText="Agribusiness"
        className="agribusiness-banner-bg"
      />
      <div className="main-wrapper">
        <div className="subSectionWrapper">
          <h4 className="container-title text-center mx-md-4 ">
            Agribusinesses
          </h4>
          <AgribusinessMainSection msme="true" />
        </div>
      </div>
      <FooterComponent />
    </>
  );
}
