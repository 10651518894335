import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./AgriStackComponent.css";
import "react-awesome-slider/dist/styles.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import ecoSystemViewImg from "../../images/product.png";
import systemViewImg from "../../images/digital.png";
import dataDrivenViewImg from "../../images/monitor.png";
import iobDrivenViewImg from "../../images/cloud-storage.png";
import blockchainTransactionViewImg from "../../images/block.png";
import appDrivenViewImg from "../../images/mobile-app.png";
import TradeStack from "../../images/profit.png";
import AgriTechStack from "../../images/agriculture.png";
import { NavLink } from "react-router-dom";

export default class AgriStackComponent extends React.Component {
  
  componentDidMount() {} // Keval -> ToDo call function to show some statistics
  componentDidUpdate(prevProps) {
    if (prevProps.sliderRef === this.props.sliderRef) return;
    setTimeout(this.showSliderContent, 300);
    if (this.state.selectedSlide === this.props.selectedSlide) return;
    this.setState({ selectedSlide: this.props.selectedSlide });
  }
  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  onTransitionEnd(value) {
    if (this.state.selectedSlide === value.currentIndex) return;
    this.setState({ selectedSlide: value.currentIndex });
  }
  showSliderContent = () => {
    this.sliderContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  render() {
    return (
      <>
        <HeaderComponent />

        <div
          maxwidth={12}
          className="businessPartnerContainer explore-app-background"
        >
          <Container maxWidth="sm" className="gridSection ">
            <Grid
              container={true}
              alignItems="center"
              spacing={1}
              direction="row"
            >
              <Grid
                id="ecoSystemView_link"
                className="hoverEffect mb-3"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/track-trace-stack">
                  <img
                    src={ecoSystemViewImg}
                    alt="ecoSystem & Mobile Apps View "
                  />
                  <h4>Track And Trace Stack</h4>
                </NavLink>
              </Grid>


              <Grid
                id="appDrivenView_link"
                className="hoverEffect  "
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/ota-device-stack">
                  {" "}
                  <img src={AgriTechStack} alt=" Mobile apps" />
                  <h4>OTA Device Stack</h4>
                </NavLink>
              </Grid>
              <Grid
                id="systemView_link"
                className="hoverEffect mb-3"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/device-hub-stack">
                  <img src={systemViewImg} alt="Digital Twin" />
                  <h4>DeviceHub Stack</h4>
                </NavLink>
              </Grid>
              <Grid
                id="dataDrivenView_link"
                className="hoverEffect mb-3"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/data-hub-stack">
                  <img src={dataDrivenViewImg} alt="Data Driven" />
                  <h4>DataHub Stack</h4>
                </NavLink>
              </Grid>
              <Grid
                id="iobDrivenView_link"
                className="hoverEffect mb-md-0 mb-3 "
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/agrifood-stack">
                  <img src={iobDrivenViewImg} alt="IoT/IoB" />
                  <h4>Agrifood Stack</h4>
                </NavLink>
              </Grid>
              <Grid
                className="hoverEffect "
                id="blockchainTransactionView_link"
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/blockchain-stack">
                  <img src={blockchainTransactionViewImg} alt="Blockchain" />
                  <h4>Blockchain Stack</h4>
                </NavLink>
              </Grid>
              <Grid
                id="appDrivenView_link"
                className="hoverEffect  "
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/registry-stack">
                  {" "}
                  <img src={appDrivenViewImg} alt=" Mobile apps" />
                  <h4>Registry Stack</h4>
                </NavLink>
              </Grid>

              <Grid
                id="appDrivenView_link"
                className="hoverEffect  "
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/trade-hub-stack">
                  {" "}
                  <img src={TradeStack} alt=" Mobile apps" />
                  <h4>TradeHub Stack</h4>
                </NavLink>
              </Grid>

              <Grid
                id="appDrivenView_link"
                className="hoverEffect  "
                item
                md={4}
                sm={4}
                xs={6}
              >
                <NavLink to="/cloud-gateway-stack">
                  {" "}
                  <img src={AgriTechStack} alt=" Mobile apps" />
                  <h4>Cloud Gateway Stack</h4>
                </NavLink>
              </Grid>
            </Grid>
          </Container>
        </div>



        <FooterComponent />
      </>
    );
  }
}
