import React from "react";
import FarmerImg from "../../../images/gbrCBN/CBNImg3.webp";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import TransporterImg from "../../../images/gbrCBN/CBNImg7.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import HeaderComponent from "../Header/HeaderComponent";
import FooterComponent from "../Footer/FooterComponent";
import CBNServices from "../../OurServices/CBNServices";
import CBNBenefits from "../Benefits/CBNBenefits";
import CBN from "../../../images/GBR Tech (CBN Network) - GBR Tech - 14-05-2024 N.gif";
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";
import CBNManager from "./CBNManager";
import CBNMainServices from "./CBNMainServices";
import CBNNetworkBenefit from "./CBNNetworkBenefit";
import AdvisorImg from "../../../images/AnimalVet1.jpg";


export default function CBNNetwork() {
  return (
    <>
      <HeaderComponent />
      {/* <NetworkBanner
        bannerTitle='CBO & Agro Dealers can BUILD, OWN, MANAGE & OPERATE Community to help farmers with everything they need from seed to sale.'
        bodycopy='Local Trade | Quality Inputs | Insurance | Advisory | Capital'
        className='cbnbg'
      /> */}

      <UpdatesBanner
        className="cbnbg text-white"
        bannerTitle="Seed to Sale"
        bannerbodycopy1={[
          // "Introduce new line of business to manage assets and ",
          // <br className="d-lg-block d-none"></br>,
          // "Create your own network of Rural-Entrepreneur",

          "CBO & Agro Dealers can BUILD, OWN, MANAGE & OPERATE CBN Network to help farmers and agri-retailers",
          <br className="d-lg-block d-none"></br>,
          "with everything they need from seed to sale",
        ]}
        demo="true"
        bannerbodycopy="Local Trade | Quality Inputs | Insurance | Advisory | Capital"
        // bannerbodycopy={[
        //   "New Revenue Stream | Optimised resource management | Boosts agricultural productivity |",
        //   <br className="d-lg-block d-none"></br>,
        //   "Unlocks opportunities for investors and rural entrepreneurs",
        // ]}
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
        // buttonText3="Sign up"
        // href3="http://abn.gbrfarming.com/auth/3/signup
      />

      <div className="mt-5 ">
        <h4 className="container-title text-center mx-md-4 ">
          Overview
        </h4>
        <HomeSubSection
          fontBold="true"
          className="rowReverseAbout align-items-center"
          listItems={<CBNBenefits />}
          description="Agribusiness can Build their Own Community network (Virtual Clubs) to obtain group organic certification and find the best partners for local trade

          "
          src={CBN}
          grid="true"
          btn2="true"
        />
      </div>
      <CBNMainServices/>

      <CBNServices />

      <CBNNetworkBenefit/>
      <div className=" subSectionWrapper">
        <h2 className="text-center container-title">BENEFICIARIES OF CBN NETWORK</h2>

        {/* <h2 className="container-title text-center">Our Community</h2> */}

        {/* <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image16"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
          Agri-producers' Advisors"
          src={AdvisorImg}
          href2="auth/18/cbnsignup"
          buttoncont2="Register"
          href1="auth/18/cbnsignin"
          buttoncont1="Sign In"
          userType="Trainer"
        /> */}

        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image17"
          descriptionTitle="
            Traders"
          description="GBR is a technology company based in Bangalore that
          provides an alternate innovate options for agri-MSMEs
          Founders to maximize profit [reduce procurement cost &
          export] and raise easy investments through IAA.
          "
          src={TradersImg}
          href2="auth/3/signup"
          buttoncont2="Register"
          href1="auth/3/signin"
          buttoncont1="Sign In"
        /> */}

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          description="NGOs are non-profit organizations focused on supporting agricultural development and rural communities. They offer services like education, training, and technical assistance to farmers, helping them adopt sustainable practices and access markets, ultimately promoting food security and rural development.


            "
            description1="NGOs | SHGs | FPOs | CS"
          descriptionTitle="
            Collectives"
          src={TransporterImg}
          
          className3="ngo-img"
          href1="/collectives"
          buttoncont1="View CBN deployment for collectives"
        />
        {/* <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="SHGs (Self-Help Groups) are community-based organizations formed by farmers to pool resources, share knowledge, and collectively engage in agricultural activities. These groups empower farmers, particularly women, by providing access to credit, training, and market linkages, thus enhancing their livelihoods and promoting socio-economic development in rural areas.


            "
          descriptionTitle="
            SHGs"
          src={TransporterImg}
       
          className3="shg-img"
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout borderedCards borderedCards--mod"
          description="FPOs are collective groups formed by farmers to improve their bargaining power, access resources, and market their produce together. They empower small farmers by pooling resources, sharing knowledge, and facilitating access to credit and markets, thereby enhancing livelihoods and promoting sustainable agriculture in rural areas.


            "
          descriptionTitle="
            FPOs"
          src={TransporterImg}
        
          className3="fpo-img"
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          description="CS are farmer-led organizations that enable collective action to address agricultural challenges and improve economic conditions. They help farmers pool resources, access markets, and obtain inputs at lower costs, while providing essential services like credit and technical assistance, fostering rural development.


            "
          descriptionTitle="
            CS"
         
          className3="cs-img"
        /> */}

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="agri-retailer-role"
          description="Agri-retailers are businesses that specialize in selling agricultural inputs such as seeds, fertilizers, pesticides, and farm equipment to farmers. They often provide a wide range of products and services tailored to the needs of the agricultural community, including agronomic advice, soil testing, and crop protection solutions. Agri-retailers play a crucial role in the agricultural supply chain, facilitating the distribution of essential inputs to farmers and supporting their efforts to maximize yields and profitability.
              "
          descriptionTitle="
              Agri Retailer"
          src={ProductionManagerImg}
          href1="/agri-retailers"
          buttoncont1="View CBN deployment for agri-retailers"
          description1= "Farm Machinery Retailers | Nutrition Retailers | Agri Input Retailers | Farmers' Advisors"

        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          description="GBR Team strongly believes that one of the way to create IMPACT is to improve the living standards of small-scale agri-producers & generate rural employment through Agri-MSMEs
            "
          descriptionTitle="
            Farmer"
          src={FarmerImg}
          href1="/farmers"
          buttoncont1="View CBN deployment for farmers"
          
        />

      </div>

      <div className="subSectionWrapper main-wrapper2">
        <CBNManager />
      </div>
      <FooterComponent />
    </>
  );
}



