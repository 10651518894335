import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";

import ABNAPPFeatures from "./ABNAPPFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import APPBenefit from "../APPBenefits/APPBenefit";

export default function ABNAPP() {
  const data1= [
    "Supply Chain Orchestration: ABN provides supply chain orchestration services, optimizing coordination and efficiency across the entire supply chain ecosystem.",
    "Solutions: ABN offers a comprehensive track and trace solution and end-to-end traceability solution for seamless monitoring and management.",
    "Contract Services: ABN offers contract services, including contract manufacturing and contract farming, streamlining production and agricultural operations."
]

const data2= [
  "Marketplace: ABN offers various marketplaces, such as OnMarketplace, Social Marketplace, Social IAA Marketplace, and Agrocare Marketplace, catering to diverse agricultural needs and preferences.",
  "Network: ABN provides access to networks such as CBN and DTN, enhancing connectivity and collaboration within the agricultural industry."
]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["ABN APP"]}
        
      />
      <SolutionEcosystem title="Overview" type="ABN" />
      <Container>
<OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <ABNAPPFeatures/>
      <APPBenefit/>
     
      <FooterComponent />
    </>
  );
}
