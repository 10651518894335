import { Container } from "@material-ui/core";
import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FoodProcessorClub from "../Club/FoodProcessorClub";
import SolutionProviderServices from "./SolutionProviderServices";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function SystemProviderClub() {
  const userTypes = ["AssetDeveloper"];

  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        bannerTitle="Solution Providers"
        className="solution-provider-bg"
      />
      <SolutionProviderServices />
      <Container className="subSectionWrapper">
        <div className=" pb-0">
          <h2 className="container-title mb-md-5 text-center">Device Makers</h2>
          <FoodProcessorClub
            type={userTypes}
            userTypeRole="DeviceMaker"
            explore="true"
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
