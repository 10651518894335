import React from 'react'

export default function PNBenefits() {
  return (
    <ul className='benefitList mb-0'>
        <li>To improvre the yield.</li>
        <li>Reduce mortality.</li>
        <li>Cost reduction by effective team coordination and management.</li>
        <li>Improve the quality of the yield.</li>
        <li>Enable the Traceability and Provenance.</li>
        <li>Enabling remote monitoring and maintainance of agri assets.</li>
        <li>Enabling new tracking field activites process.</li>
        <li>Enabling Convergence of digital technologies with farming practices.</li>
        <li>Creating Trust for Agri-investor to invest.</li>
           
    </ul>
  )
}
