import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function RegistryGatewayBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Open Standards Compliance",
      bodycopy:
        " Core Stack is compliant with industry standards such as OAuth 2.0, OpenID Connect (OIDC), SAML 2.0, and LDAP, ensuring interoperability and compatibility with a wide range of applications, frameworks, and platforms. ",
    },
    {
      img: img2,
      title: "Security and Compliance     ",
      bodycopy:
        "       Core Stack provides robust security features, including encryption, token-based authentication, secure password storage, and audit logging, helping agribusiness and system integrators comply with regulatory requirements such as GDPR, HIPAA, and PCI DSS.",
    },
    {
      img: img3,
      title: "Scalability and High Availability ",
      bodycopy:
        " Stackis designed for scalability and high availability, with support for clustering, load balancing, and distributed caching, enabling organizations to handle large volumes of authentication and authorization requests with minimal downtime or performance degradation.",
    },
    {
      img: img3,
      title: " Developer-Friendly APIs",
      bodycopy:
        " Stack offers comprehensive APIs  for integrating with applications and services, enabling developers to quickly and easily add authentication and authorization functionalities to their applications, reducing development time and effort.",
    },
    {
      img: img3,
      title: " Enhanced Transparency and Trust",
      bodycopy:
        "  By providing transparency into participant identities the registry helps build trust and confidence among core agri-business and their partners , fostering a secure and trustworthy digital transactions.",
    },

    {
      img: img3,
      title: "Interoperability and Integration",
      bodycopy:
        "  The registry supports interoperability and integration with external systems and platforms, enabling seamless exchange of data and information between different digital agricultural  ecosystems. This promotes interoperability and connectivity across the digital agricultural landscape.",
    },
  ];
  return (
    <div className="">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">Benefits</h2>
        <Row>
          {benefits.map((e) => {
            return (
              <Col md={4} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
