import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import "./LandingPageBanner.css";
import { NavLink } from "react-router-dom";
export default function LandingPageBanner() {
  return (
    <div className="container-banner">
      <Container>
        <Grid container spacing={2} className="justify-content-center">
          <Grid item md={8} sm={12} xs={12} className="p-0">
            <div className="abn-content text-center">
              <h1>
                Empowering sustainable Farming & Processing through Technology 
              </h1>
              <p>
                Introducing Tech Stack for end to end Agrifood Value Chain
              </p>
              
              <Button variant="contained" color="primary" className="">
                <NavLink to="/our-tech-stack">Explore Tech Stack </NavLink>
              </Button>
              
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
