import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";

import AgribusinessStack from "../AgribusinessStack/AgribusinessStack";
export default function AgriRetailers() {
  return (
    <>
      <HeaderComponent />


<AgribusinessStack
        classname="agriretailer-bg "
        level1="System Integrator"
        level2="Agribusiness"
        level3="Agri-Retailers"
      />
     
      <SolutionEcosystem
                title="CBN Deployment for Agri-Retailers"

        type="AR"
        bodycopy="ARN is a type of CBN through which agri-retailers sell quality inputs to the farmers."
      />
   
      
      <FooterComponent />
    </>
  );
}
