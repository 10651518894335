import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";

import CTA from "../CTA/CTA";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import TradeHubFeatures from "./TradeHubFeatures";
import TradeHubBenefits from "./TradeHubBenefits";
export default function TradeHubStack() {
  const data1 = [
    "Contract Manufacturing: contract manufacturing allows buyers to submit RFQ (Request for Quotation) and order requests to sellers for processed or semi-processed products, optionally specifying a time frame for delivery.",
    "Contract Farming: Contract Farming feature allows buyers to submit RFQ (Request for Quotation) and order requests to sellers weather it can be  assets or only raw products, optionally specifying a time frame for delivery",

    "Retail Order Management: Buyers can purchase any type of agricultural product, such as raw or processed or semi-processed, and send a request to the seller",
    "Agri Asset Management: With the help of Agri Asset Investment, investors may see available white-label assets and start making investments in them, giving them the chance to diversify their holdings within the agricultural industry.",
  ];

  const data2 = [
    "    Transport Management: It enables users to request transportation or storage services from logistic providers, facilitating efficient handling of goods and materials.",
    "Storage Management: It enables users to request storage services from storage providers, facilitating efficient storage of goods and materials.",

    "Beckn API: TradeHub backend network based api implemented to achieve decentralized and P2P architecture. This is done for wider interoperability among the agrifood ecosysten players.",
    "IAM: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.",
    "DB: The database (db) stores configurations, device metadata, topics, and commands, serving as a central repository for managing crucial system information and facilitating efficient data retrieval and manipulation.",
  ];
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["TradeHub Stack"]}
        bannerbodycopy="Contract Farming | Contract Manufacturing | Buy and Sell | Investment | Booking Management | Storage Management"
      />
      <SolutionEcosystem title="Overview" type="TradeHub" />
      <Container>
        <OverviewFeatures data1={data1} data2={data2} />
      </Container>
      <TradeHubFeatures />
      <TradeHubBenefits />
      {/* <ServiceTypes
              href="http://35.92.73.23:8081/v2/api-docs/"
              alt="Icon"
        id="systemView"
        src={systemViewImg}
        title="DeviceHub Stack Experience"
        description2="Integrate | Connect | Manage | Operate"
        target="true"
      /> */}
      <Container className="subSectionWrapper">
        <CTA
          btnText="View APIs"
          href="/ComingSoon"
          heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
        />
      </Container>
      <FooterComponent />
    </>
  );
}
