import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import CBNAPPFeatures from "./CBNAPPFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import APPBenefit from "../APPBenefits/APPBenefit";

export default function CBNAPP() {
  const data1= ["Hyper-local Supply Chain Orchestration: CBN offers hyper-local supply chain orchestration services, ensuring precise coordination and efficiency in agricultural logistics at the local level.",
    "Loan Services: CBN app offers convenient access to loan services for financial assistance and empowerment.",
"Buy and Sell Sevices : CBN app provides a platform for buying and selling goods and services to promote economic activity.",
"Agro Retailer Service: CBN app offers agro retailer services for farmers to access agricultural inputs and products conveniently."

  ]

  const data2 = [
"Advisory Service: CBN app provides agricultural advisory services for farmers to enhance productivity and decision-making.",
    "Training Service: CBN app offers training services to empower farmers with knowledge and skills for sustainable agriculture.    "
  ]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["CBN APP"]}
        DownloadText="Download App"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.cbnapp"
        DlBtn="true"
        
      />
      <SolutionEcosystem title="Overview" type="CBN" />
      <Container>
        <OverviewFeatures data1={data1} data2={data2}/>
      </Container>
      <CBNAPPFeatures/>
      <APPBenefit/>
    
      <FooterComponent />
    </>
  );
}
