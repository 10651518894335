import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function OTAStackBenefit() {
  const benefits = [
    {
      img: img1,
      title: "Unified OTA Management",
      bodycopy: "Unified OTA Management provided by OTA Management ensures seamless updates across various device models and solutions, optimizing efficiency and ensuring consistency in updates throughout the ecosystem."    },
    {
      img: img2,
      title: "Agnostic to 3rd Party OEMs",
      bodycopy: "Agnostic to 3rd Party OEMs OTA system is a benefit, allowing for versatile compatibility with diverse device manufacturers' systems, fostering flexibility and scalability in OTA updates across the ecosystem.",
    },
    

     
  ];
  return (
          <Container className="subSectionWrapper">
      <h2 className="text-center container-title mb-4">
        Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
  );
}
