import React from "react";
import ContractManufacturing from "../../../images/dtn-network-icons/contract-manufacturing.svg";
import ContractFarming from "../../../images/dtn-network-icons/contract-farming.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function ProductionNetworkServices() {
  const services = [

   
     
    {
      img: ContractManufacturing,
      title: "Agri Contract-Manufacturing Services",
      bodycopy:
      " DTN Network enables Agri Contract-Manufacturing Services, facilitating seamless collaboration between agri-contract manufacturers and brands to streamline production processes and optimize product quality in the agribusiness sector.",
    },
    {
        img: ContractFarming,
        title: "Agri Contract-Farming Services",
        bodycopy: " DTN facilitates Agri Contract-Farming Services, bridging Agri-entrepreneurs with brands to streamline agricultural production, fostering mutually beneficial partnerships and sustainable growth in the agri-industry."    },
     
        {
            img: ContractFarming,
            title: "Investable Asset Management Service",
            bodycopy: " DTN Network offers an Investable Asset Management Service, allowing investors to entrust farming and processing assets to agri-entrepreneurs and contract manufacturers, fostering efficient utilization and maximizing returns in the agricultural sector."    },
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Services
      </h2>
      <Row>
        {services.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
