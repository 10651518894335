import { Container } from "@material-ui/core";
import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from  "../WebSite/Header/HeaderComponent";
import FoodProcessorClub from "../Club/FoodProcessorClub";
import NetworkOperatorServices from "./NetworkOperatorServices";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
export default function NetworkOperatorClub() {
  const userTypes = ["AssetDeveloper"];

  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        bannerTitle="Network Operators"
        className="network-operator-bg"
      />
      <NetworkOperatorServices/>
      
      <Container className="subSectionWrapper">
        <div className=" pb-0">
          <h2 className="container-title mb-md-5 text-center">Device Makers</h2>
          <FoodProcessorClub
            type={userTypes}
            userTypeRole="
DeviceMaker"
            explore="true"
          />
        </div>
        <div className="subSectionWrapper pb-0">
          <h2 className="container-title mb-md-5 text-center">
            Agri-Solution Providers
          </h2>
          <FoodProcessorClub type={userTypes} userTypeRole="SystemIntegrator"  explore = "true"/>

        </div>{" "}
      </Container>
      <FooterComponent />
    </>
  );
}
