import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function AgroCareMarketplaceBenefits() {
  const benefits = [

   
     
    {
      img: img3,
      title: "Traceability & Provenance",
      bodycopy:
      " AgroCare Marketplace ensures traceability and provenance, providing transparency in product origin and journey, instilling trust in purchases.",
    },
    {
        img: img1,
        title: "Easy Product Listing",
        bodycopy: " Provide a simple interface for sellers to add their products with detailed descriptions, images, pricing, and certification details."    },
     
        
  ];
  return (
    <div className="">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
