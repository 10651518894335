import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function IOBBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Efficient Stock Management",
      bodycopy: "Ensure optimal inventory levels and reduce stock outs or overstock situations."    },
    {
      img: img2,
      title: "Improved Operational Efficiency      ",
      bodycopy: "     Streamline processes, reduce manual errors, and improve overall productivity.",
    },
    {
      img: img3,
      title: "Sustainability ",
      bodycopy:
        "Promote sustainable farming practices and environmental stewardship, contributing to long-term viability and resilience.    ",
    },
    {
        img: img3,
        title: "Transparency and Accountability",
        bodycopy:
          "Provide transparency in product sourcing and supply chain practices, enhancing trust with stakeholders.",
      },
      {
        img: img3,
        title: "Cost Savings",
        bodycopy:
          "Reduce wastage, optimize resources, and lower operational costs, leading to improved profitability.",
      },
      {
        img: img3,
        title: "Customer Satisfaction",
        bodycopy:
          "Provide customers with high-quality products and timely services, leading to satisfaction and repeat business.",
      },
      {
        img: img3,
        title: "Regulatory Compliance",
        bodycopy:
          "Avoid penalties and legal issues by adhering to industry regulations and standards.",
      },
      {
        img: img3,
        title: "Enhanced Product Quality",
        bodycopy:
          "Maintain consistent quality standards, leading to customer satisfaction and loyalty.",
      },
      {
        img: img3,
        title: "Efficiency",
        bodycopy:
          "By streamlining document generation processes, DocGen Management saves valuable time and resources for both users and organizations.",
      },

      {
        img: img3,
        title: "Consistency",
        bodycopy:
          "Ensure uniformity in document formatting, content, and branding across all communications, bolstering professionalism and brand integrity.",
      },

      {
        img: img3,
        title: "Accuracy",
        bodycopy:
          "Minimize errors and inaccuracies by automating data entry and eliminating the need for manual input, thereby enhancing the reliability of generated documents.",
      },
      {
        img: img3,
        title: "Better Decision Making",
        bodycopy:
          "Make informed decisions based on data-driven insights and analysis.",
      },
      

      {
        img: img3,
        title: "Efficient Asset Management",
        bodycopy:
          "Streamline the management of agricultural assets through organized categorization and easy access to asset information.",
      },

      {
        img: img3,
        title: "Enhanced Tracking and Identification",
        bodycopy:
          "QR code generation enables swift tracking and identification of assets, minimizing errors and improving efficiency.",
      },
      {
        img: img3,
        title: "Real-Time Monitoring",
        bodycopy:
          "Monitor asset locations and environmental conditions in real-time, allowing for timely interventions and better resource allocation.",
      },


      {
        img: img3,
        title: "Improved Decision-Making",
        bodycopy:
          "Access to comprehensive asset information and analytics empowers users to make informed decisions for optimal asset performance.",
      },

      {
        img: img3,
        title: "Proactive Maintenance",
        bodycopy:
          "With insights from environmental monitoring and device data, users can implement proactive maintenance measures to prolong asset lifespan and enhance productivity.",
      },
      {
        img: img3,
        title: "Operational Efficiency",
        bodycopy:
          "Perform asset operations seamlessly within the system, saving time and resources while ensuring accuracy and compliance.",
      },

      {
        img: img3,
        title: "Enhanced Visibility and Control",
        bodycopy:
          " Gain better visibility and control over assets, leading to improved resource allocation, risk management, and overall operational efficiency.",
      },
      {
        img: img3,
        title: "Scalability and Flexibility",
        bodycopy:
          "The system is scalable to accommodate growing asset portfolios and flexible to adapt to changing agricultural needs and requirements.",
      },

      {
        img: img3,
        title: "Efficient Site Management",
        bodycopy:
          "  Streamline the management of agricultural sites with easy access to site information and asset allocation, enhancing overall efficiency.",
      },


      {
        img: img3,
        title: "Optimized Resource Allocation:",
        bodycopy:
          " Gain insights into asset distribution and environmental conditions at each site, enabling better resource allocation and utilization.",
      },

      {
        img: img3,
        title: "Continuous Improvement",
        bodycopy:
          "  Evaluate site performance and address issues promptly, fostering a culture of continuous improvement and excellence.",
      },
      {
        img: img3,
        title: "Enhanced Monitoring and Control",
        bodycopy:
          " Monitor environmental conditions, track issues, and set up alarms for proactive management and control of site operations.",
      },

      {
        img: img3,
        title: "Data-Driven Decision-Making",
        bodycopy:
          "   Generate daily reports and analyze site data to make informed decisions, driving improvements in agricultural practices and outcomes.",
      },
      {
        img: img3,
        title: "Improved Communication",
        bodycopy:
          " Facilitate communication and collaboration among stakeholders with site updates, feedback mechanisms, and issue reporting features.",
      },

      {
        img: img3,
        title: "Compliance and Risk Management",
        bodycopy:
          "  Ensure compliance with regulations and standards by tracking site activities and addressing issues promptly, mitigating risks and liabilities.",
      },

      {
        img: img3,
        title: "Increased Productivity",
        bodycopy:
          "   By optimizing site operations, attendance, and nutrition plans, enhance productivity and yield across agricultural sites, leading to improved profitability.",
      },




      
  ];
  return (
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
        Benefits
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
  );
}
