import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import "../App.css";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";
import AgribusinessStack from "../../AgribusinessStack/AgribusinessStack";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import CTA from "../../CTA/CTA";

// import ForFarmerBenefits from "../ForFarmerBenefits/ForFarmerBenefits";
import ForFarmerChallenges from "../ForFarmerChallenges/ForFarmerChallenges";
import OurServices from "../../OurServices/OurServices";

export default class GBRCareLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedSlide: 0,
      phoneNumber: "",
    };
  }
  componentDidMount = () => {
    const id = localStorage.getItem("Log");
  };
  sendAppLink() {
    Toast.loading("Loading", () => {
      this.handleClose.call(this, -1);
      Toast.success("App link send to " + this.state.phoneNumber, 2000);
    });
    setTimeout(Toast.hide, 2000);
  }
  componentDidMount() {}

  onTransitionEnd(value) {
    if (this.state.selectedSlide === value.currentIndex) return;
    this.setState({ selectedSlide: value.currentIndex });
  }
  showSliderContent = () => {
    this.sliderContent.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  render() {
    const selectedSlide = this.state.selectedSlide;
    return (
      <>
        <HeaderComponent />
        
       <AgribusinessStack
        classname="farmUpdateBanner "
        level1="System Integrator"
        level2="Agribusiness"
        level3="Farmers"
      />


        <SolutionEcosystem
          title="CBN Deployment for Farmers"
          type="AB"
          bodycopy="ARN is a type of CBN network where farmer can buy inputs and LTN is a type of CBN network where farmer can sell the inputs."
        />

  

        <FooterComponent />
      </>
    );
  }
}
