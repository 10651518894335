import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";

import OnMarketplaceFeatures from "./OnMarketplaceFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import OnMarketPlaceAPPBenefits from "./OnMarketPlaceAPPBenefits";

export default function OnMarketPlaceAPP() {
  const data1 = [
    "Buy and Sell Service: OnMarketplace facilitates the buying and selling of raw, semi-processed, and processed products, fostering efficient trade in agricultural commodities.",
  ]

 
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["onMarketPlace"]}
        bannerbodycopy="Explore our marketplace offering a wide array of organic and natural products"
        
      />
      <SolutionEcosystem title="Overview" type="ONM" />
      <Container>
      <OverviewFeatures data1={data1}/>
      </Container>
      <OnMarketplaceFeatures/>
      <OnMarketPlaceAPPBenefits/>
    
      <FooterComponent />
    </>
  );
}
