import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import DeviceHubFeatures from "./DeviceHubFeatures";

import CTA from "../CTA/CTA";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import DeviceHubBenefits from "./DeviceHubBenefits";
export default function DeviceHub() {

    const data1 = [
        "                DeviceHub Gateway API: The Device Hub Gateway API includes token validation and directs requests to relevant services, streamlining communication within the Device Hub ecosystem.",
        "                Communication Service:  Communication Service acts as a proxy for the Core IoT server API, enabling manipulation of the broker through set APIs to streamline communication between devices and the IoT server.",
        "                Security Service: The Security Service manages SSL certificates by generating, revoking, and renewing them, ensuring secure connections for SSL-enabled communication within the system.    ",
        "                Command Publisher: The Command Publisher facilitates communication with devices through the MQTT protocol, allowing for the retrieval and modification of device property values, enhancing control and management capabilities remotely.    ",
        "Device Meta Data: Device Meta Data stores essential information about device types, models, and individual devices, serving as a central repository for managing device-related details within the system."
    ]
    
    const data2 = [
        "Configurations: Configurations store and manage server settings and parameters, providing a centralized location for configuring and maintaining server behavior within the system.    ",
        "Topic Manager: The Topic Manager sets and oversees topics for various entities, each with distinct topic types, facilitating organized and efficient communication within the system.    ",
        "Broker Service: The Broker Service transfers data between devices and services based on specified topics, enabling seamless communication and coordination within the system.    ",
        "IAM: IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.    ",
        "DB: The database (db) stores configurations, device metadata, topics, and commands, serving as a central repository for managing crucial system information and facilitating efficient data retrieval and manipulation.",
    ]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["DeviceHub Stack Experience"]}
        bannerbodycopy="Integrate | Connect | Manage | Operate
      "
      />
      <SolutionEcosystem title="Overview" type="DeviceHB" />
      <Container>
        <OverviewFeatures data1={data1} data2={data2}/>
        {/* <Row>
          <Col md={6}>
            <ul>
            <li>
                DeviceHub Gateway API: The Device Hub Gateway API includes token validation and directs requests to relevant services, streamlining communication within the Device Hub ecosystem.
                Device Hub Gateway API: To validate the token and send the request to respective services
              </li>
              <li>
                EMQ Service :  EMQ Service acts as a proxy for the Core IoT server API, enabling manipulation of the broker through set APIs to streamline communication between devices and the IoT server.
                EMQ service acts as a proxy for Core IoT server
                API. For this it will call set APIs to manipulate the broker
              </li>
              <li>
                Security Service : The Security Service manages SSL certificates by generating, revoking, and renewing them, ensuring secure connections for SSL-enabled communication within the system.
                It is used for generate,revoke and renew
                certificates for SSL connections
              </li>
              <li>
                Command Publisher : The Command Publisher facilitates communication with devices through the MQTT protocol, allowing for the retrieval and modification of device property values, enhancing control and management capabilities remotely.
                It is used to get and set property value of
                the device through MQTT Protocol.
              </li>
              <li>Device Meta Data: Device Meta Data stores essential information about device types, models, and individual devices, serving as a central repository for managing device-related details within the system.
                To store information of types, models, devices and its information.
                </li>
            </ul>
          </Col>
          <Col md={6}>
            <ul>
              <li>
                Configurations : Configurations store and manage server settings and parameters, providing a centralized location for configuring and maintaining server behavior within the system.
                To store and manage the configuration of servers.
              </li>
              <li>
                Topic Manager : The Topic Manager sets and oversees topics for various entities, each with distinct topic types, facilitating organized and efficient communication within the system.
                To set and manage topic for different entity with different topic type.
              </li>
              <li>
                Broker Service : The Broker Service transfers data between devices and services based on specified topics, enabling seamless communication and coordination within the system.
                 It is used to transfer the data based on topic between devices and services
              </li>

              <li>
              IAM : IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.
              </li>
              <li>
                db : The database (db) stores configurations, device metadata, topics, and commands, serving as a central repository for managing crucial system information and facilitating efficient data retrieval and manipulation.
                It is used to store configurations,device metadata, topics and commands
              </li>
            </ul>
          </Col>
        </Row> */}
      </Container>
      <DeviceHubFeatures />
      <DeviceHubBenefits/>
      <Container className="subSectionWrapper">
        <CTA
          href4="http://54.185.221.117:8900/v2/api-docs/"
          heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
        />
      </Container>
      <FooterComponent />
    </>
  );
}
