import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as serviceWorker from './serviceWorker';
import ExploreNetwork from './components/ExploreNetwork/ExploreNetwork';
import AgriStack from './components/AgriStack/AgriStackComponent';

import WebSiteApp from './components/WebSite/App';

import {NotificationContainer} from "react-notifications";

import TermsComponent from './components/T&C/Terms';
import PrivacyComponent from './components/Privacy/Privacy';
import "react-notifications/lib/notifications.css";
import './index.css';
import ExploreProducts from './components/ExploreProducts/ExploreProducts';
import ProcessedProduct from './components/ProceesedProducts/ProcessedProduct';
import { ScrollToTop } from './components/ScrollTo/ScrollToTop';
import Club from './components/Club/Club';
import IAA from './components/IAA/IAA';
import CBNNetwork from './components/WebSite/CBNNetork/CBNNetwork';
import ExploreTechnology from './components/ExploreTechnology/ExploreTechnology';
import ProcessingService from "./components/ProcessingService/ProcessingService";
import ForManufactures from './components/ForManufactures/ForManufactures';
import ExploreAnimalCare from './components/ExploreAnimalCare/ExploreAnimalCare';
import ExplorePlantCare from './components/ExplorePlantCare/ExplorePlantCare';
import ForFarmers from './components/ForFarmers/ForFarmers';

import ProductionNetwork from './components/WebSite/ProductionNetwork/ProductionNetwork';
import OnMarketplaceSell from './components/OnMarketplaceSell/OnMarketplaceSell';
import SocialMarketplace from './components/SocialMarketplace/SocialMarketplace';
import RFQ from './components/RFQ/RFQ';
import ExploreFoodProcessor from './components/WebSite/ExploreFoodProcessor/ExploreFoodProcessor';
import LandingPage from './components/WebSite/LandingPage/LandingPage';
import ContractFarming from './components/ContractFarming/ContractFarming';
// import ManufacturingOnDemand from './components/ManufacturingOnDemand/ManufacturingOnDemand';
import IAAMarketplace from './components/IAAMarketplace/IAAMarketplace';
import RetailInvestors from './components/RetailInvestors/RetailInvestors';
import InstitutionalInvestors from './components/InstitutionalInvestors/InstitutionalInvestors';
import Brands from './components/Brands/Brands';
import ABNComponent from './components/ABNComponent/ABNComponent';
import ForNetworKOperators from './components/ForNetworKOperators/ForNetworKOperators';
import './config'
import AgriBusinessCompany from './components/AgriBusinessCompany/AgriBusinessCompany';
import AssetService from './components/AssetService/AssetService';

import Request from './components/RequestAc/Request'
import CommunityPartners from './components/CommunityPartners/CommunityPartners';

import AgribusinessMain from './components/AgribusinessMain/AgribusinessMain';
import DealerTypes from './components/DealerTypes/DealerTypes';
import FFS from './components/FFS/FFS';

import FinancialService from './components/AgriProducers/FinancialService/FinancialService';
import CareServices from './components/AgriProducers/CareServices/CareServices';
import AssetMarketService from './components/AgriProducers/AssetMarketService/AssetMarketService';
// import CMNNetwork from './components/WebSite/AAMCNetwork/CMNNetwork';
import CBOClubs from './components/CBOClubs/CBOClubs';
import TechStack from './components/TechStack/TechStack';
import TechnologyPartners from './components/TechnologyPartners/TechnologyPartners'
import DeviceMarketplace from './components/DeviceMarketplace/DeviceMarketplace';
import NetworkOperatorABNService from './components/NetworkOperatorABNService/NetworkOperatorABNService';
import NetworkOperatorAppStoreService from './components/NetworkOperatorAppStoreService/NetworkOperatorAppStoreService';
import NetworkOperatorClub from './components/NetworkOperator/NetworkOperatorClub';
import DeviceMakerClub from './components/DeviceMakers/DeviceMakerClub';
import SystemProviderClub from './components/SolutionProviders/SystemProviderClub';
import AppMarketplace from './components/AppMarketplace/AppMarketplace';
import DeviceStoreService from './components/DeviceStoreService/DeviceStoreService';
import CertificationServices from './components/CertificationServices/CertificationServices';
import ACG from './components/ACG/ACG';
import SDH from './components/SDH/SDH'
import TPN from './components/TPN/TPN';
import DeviceHub from './components/DeviceHub/DeviceHub';
import TrackTraceStack from './components/TrackTraceStack/TrackTraceStack';
import DataHubStack from './components/DataHubStack/DataHubStack';
import IoBStack from './components/IoBStack/IoBStack';
import BlockChainStack from './components/BlockChainStack/BlockChainStack';
import RegistryGateway from './components/RegistryGateway/RegistryGateway';
import TradeHubStack from './components/TradeHubStack/TradeHubStack';
import AgriTechStack from './components/AgriTechStack/AgriTechStack';
import DTNAPP from './components/DTNAPP/DTNAPP';
import ABNAPP from './components/ABNAPP/ABNAPP';
import CBNAPP from './components/CBNAPP/CBNAPP';
import AgriminerApp from './components/AgrimInerApp/AgriminerApp';
import SocialOnMarketplace from './components/SocialOnMarketplace/SocialOnMarketplace';
import SocialIAAMarketplace from './components/SocialIAAMarketplace/SocialIAAMarketplace';
import AgroCareMarketPlace from './components/AgroCareMarketPlace/AgroCareMarketPlace';
import OnMarketPlaceAPP from './components/OnMarketPlaceAPP/OnMarketPlaceAPP';
import OTAStack from './components/OTAStack/OTAStack';
import ExploreMarketplace from './components/ExploreMarketplace/ExploreMarketplace';
import ThirdPartyInfrastructure from './components/ThirdPartyInfrastructure/ThirdPartyInfrastructure';
import ServicesComponent from './components/Services/ServicesComponent';
import ForAgriEntreprenuer from './components/ForAgriEntreprenuer/ForAgriEntreprenuer';
import ForAgriTransporter from './components/ForAgriTransporter/ForAgriTransporter';

import FieldSupportService from './components/FieldSupportService/FieldSupportService';
import GBRCareLandingPage from './components/WebSite/GBRCareLandingPage/GBRCareLandingPage';
import AgriRetailers from './components/AgriRetailers/AgriRetailers';
import Collective from './components/Collectives/Collective';
import BillingService from './components/BillingService/BillingService';


let ConsumerAdmin;
let ClientId;
let ClientSecretKey;
let ClientName;
let AssetverticalID;


if(process.env.REACT_APP_DEVELOPMENT_ENV === "production"){
    ConsumerAdmin = process.env.REACT_APP_CONSUMER_ADMIN_PRODUCTION
    ClientId = process.env.REACT_APP_CLIENT_ID_PRODUCTION
    ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_PRODUCTION
    ClientName = process.env.REACT_APP_NAME_PRODUCTION
    AssetverticalID = process.env.REACT_APP_ASSETVERTICAL_PRODUCTION
 }
 else if (process.env.REACT_APP_DEVELOPMENT_ENV === "staging"){
    ConsumerAdmin =  process.env.REACT_APP_CONSUMER_ADMIN_STAGING
    ClientId = process.env.REACT_APP_CLIENT_ID_STAGING
    ClientSecretKey = process.env.REACT_APP_CLIENT_SECRET_STAGING
    ClientName = process.env.REACT_APP_NAME_STAGING
    AssetverticalID = process.env.REACT_APP_ASSETVERTICAL_STAGING

 }



window.AppConfigData = {
   ConsumerAdmin:ConsumerAdmin,
   CBNRealm: ConsumerAdmin,
   AssetVerticalId:AssetverticalID,
   AssetVerticalFriendlyName: 'ANIMAL',
   ApiUrlMappCBN: window.appConfig.cbn,


   ClientName: ClientName,
   ClientId:ClientId,
   RedirectUrl: '/',
   Realm: ConsumerAdmin,
   EnterpriseId: ConsumerAdmin,
   ClientSecretKey: ClientSecretKey,
   ApiUrlMapp: window.appConfig,
   analytics: {
       google: window.googleAnalytics
   }
//     //ClientName: 'Agri-App',
//     //Realm: 'Agri-Admin',
//     //EnterpriseId: 'Agri-Admin',
//     //UserType: 'Agri-Investor',
//     //ClientId:'2aca444a-d378-4661-947a-4f75d102324c',
//     ClientName: 'Agri-App',
//     Realm: 'consumer2',
//     EnterpriseId: 'consumer2',
//     UserType: 'AssetOEM',
//     ClientId:'30c667be-1aa3-4f0d-94be-a4176d93558a',
//     RedirectUrl: '/',
//    // ClientSecretKey: 'dc13ff6f-7f55-4779-8a45-f3ab52d9b8aa',
//     ClientSecretKey: '6511ea61-2b7a-47f1-b548-0397c68828ad',
//     AssetVerticalId: 'cffa5ffe-f15e-4a27-9a9e-7bf8b5c5d110',
//     AssetVerticalFriendlyName: 'ANIMAL',
//     ApiUrlMapp: window.appConfig,
//     analytics: {
//         google: window.googleAnalytics
//     }
}



// window.AppConfigData = {
//     ConsumerAdmin:"consumer2",
//     CBNRealm: parentId,
//     AssetVerticalId: 'cffa5ffe-f15e-4a27-9a9e-7bf8b5c5d110',
//     AssetVerticalFriendlyName: 'ANIMAL',
//     ApiUrlMappCBN: window.appConfig.cbn,


//     ClientName: 'GBR1',
//     ClientId:'4f4bb68c-f310-4742-9dbd-dcbbc396f9a3',
//     RedirectUrl: '/',
//     Realm: parentId,
//     EnterpriseId: 'consumer2',
//     ClientSecretKey: '56b50768-f088-40e5-b015-9ec5eefdb213',
//     ApiUrlMapp: window.appConfig,
//     analytics: {
//         google: window.googleAnalytics
//     }
// //     //ClientName: 'Agri-App',
// //     //Realm: 'Agri-Admin',
// //     //EnterpriseId: 'Agri-Admin',
// //     //UserType: 'Agri-Investor',
// //     //ClientId:'2aca444a-d378-4661-947a-4f75d102324c',
// //     ClientName: 'Agri-App',
// //     Realm: 'consumer2',
// //     EnterpriseId: 'consumer2',
// //     UserType: 'AssetOEM',
// //     ClientId:'30c667be-1aa3-4f0d-94be-a4176d93558a',
// //     RedirectUrl: '/',
// //    // ClientSecretKey: 'dc13ff6f-7f55-4779-8a45-f3ab52d9b8aa',
// //     ClientSecretKey: '6511ea61-2b7a-47f1-b548-0397c68828ad',
// //     AssetVerticalId: 'cffa5ffe-f15e-4a27-9a9e-7bf8b5c5d110',
// //     AssetVerticalFriendlyName: 'ANIMAL',
// //     ApiUrlMapp: window.appConfig,
// //     analytics: {
// //         google: window.googleAnalytics
// //     }
// }

const theme = createMuiTheme(
    {
        palette: {
            primary: {main: '#3ECF8E'},
            secondary: {main: '#2F80ED'},
            danger: {main: '#BF360C', shadow: 'rgba(191,54,12 ,.3)'},
        },
        status: {
            danger: 'orange',
        }
    }
);

try{
    ReactGA.initialize(window.AppConfigData.analytics.google.accountId, { debug: false });
    ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log(window.location.pathname + window.location.search);
}catch(e){
    console.error(e);
}

class IndexComponent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // history: useHistory()
        }
    }



    componentDidUpdate(prevProps, prevState){
       
        if(prevProps.location !== this.props.location){
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    // createAccessToken(){
    //     fetch(
    //         `${window.appConfig.PublicAccessToken}${window.appConfig.TokenRelam}`,
    //         {
    //           method: "POST",
    //           headers: {
    //             "Content-Type": "application/json",
                 
    //           },
    //           body:JSON.stringify( {
    //             client_name:"GBR1",
    //             client_secret:"56b50768-f088-40e5-b015-9ec5eefdb213"
    //           })
    //         }
    //       ).then((res) => res.json())
    //     .then((result) => {
    //         this.setState({
    //          accessToken: result.access_token,
    //         });
    //         localStorage.setItem("GlobalAccess",result.access_token);

    //     })
    //         .catch((err) => console.log("err", err));
    //   }
    createAccessToken(){
        fetch(
            `${window.appConfig.PublicAccessToken}${window.appConfig.TokenRelam}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                 
              },
              body:JSON.stringify( {
                client_name:ClientName,
                client_secret:ClientSecretKey
              })
            }
          ).then((res) => res.json())
        .then((result) => {
            this.setState({
           });
            localStorage.setItem("GlobalAccess",result.access_token);

        })
            .catch((err) => console.log("err", err));
      }
      componentDidMount(){
        this.createAccessToken();
        // localStorage.setItem("Sapna", false);

      }

    render(){
        return (
            // <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {/* <CartItemProvider> */}
                    <Router>
                    <ScrollToTop>
                        <Switch>

                        {/* <Route exact path="/" component={(args) => <SigninPageComponent{...args}/>} /> */}

                        <Route exact path="/" component={(args) => <ForNetworKOperators{...args}/>} />
                        <Route exact path="/agri-producers" component={(args) => <WebSiteApp {...args}/>} />
                        <Route exact path='/Field-Support' component={(args)=><FieldSupportService {...args}/>} />

                            {/* <Route exact path="/" component={(args) => <ForAAMC {...args}/>} /> */}
                            <Route exact path='/partners' component={(args)=><CommunityPartners {...args}/>} />
                            <Route exact path='/farmers' component={(args)=><GBRCareLandingPage {...args}/>} />
                            <Route exact path='/agri-retailers' component={(args)=><AgriRetailers {...args}/>} />
                            <Route exact path='/collectives' component={(args)=><Collective {...args}/>} />

                            <Route exact path="/About" component={(args) => <LandingPage {...args}/>} />
                            <Route exact path="/ComingSoon" component={(args) => <Request {...args}/>} />
                            <Route exact path='/onMarketplace' component={(args)=><ExploreProducts {...args}/>}/>
                            <Route exact path='/profit-generating-services' component={(args)=><AssetService {...args}/>} />

                            <Route exact path='/Terms&Conditions' component={(args)=> <TermsComponent {...args}/>}/>
                            <Route exact path='/PrivacyPolicy' component={(args)=> <PrivacyComponent {...args}/>}/>
                            <Route exact path='/ProcessedProducts' component={(args)=><ProcessedProduct {...args}/>}/>
                            <Route exact path='/Club' component={(args)=><Club {...args}/>}/>
                            <Route exact path='/IAA' component={(args)=><IAA {...args}/>} />
                            <Route exact path='/CBNNetwork' component={(args)=><CBNNetwork {...args}/>} />

                            <Route exact path="/agri-storage-entrepreneurs" component={(args) => <WebSiteApp {...args}/>} />

                            <Route exact path='/explore-apps' component={(args)=><ExploreTechnology {...args}/>} />
                            <Route exact path='/infrastructure' component={(args)=><ThirdPartyInfrastructure {...args}/>} />
                            <Route exact path='/contract-manufacturer' component={(args)=><ForAgriEntreprenuer {...args}/>} />

<Route exact path='/GrowthService' component={(args)=><ProcessingService {...args}/>} />
                            <Route exact path='/ForBrands' component={(args)=><ForManufactures {...args}/>} />
                            <Route exact path='/ExploreAnimalCare' component={(args)=><ExploreAnimalCare {...args}/>}/>
                            <Route exact path='/ExplorePlantCare' component={(args)=><ExplorePlantCare {...args}/>}/>
                            <Route exact path='/PNNetwork' component={(args)=><ProductionNetwork {...args}/>}/>
                            <Route exact path='/agri-transporters' component={(args)=><ForAgriTransporter {...args}/>} />

                            <Route exact path="/onMarketplaceSell" component={(args)=><OnMarketplaceSell {...args}/>} />
                            <Route exact path="/SocialCFonMarketplace" component={(args)=><SocialMarketplace {...args}/>} />
                            <Route exact path="/RFQ" component={(args)=><RFQ {...args}/>} />
                            <Route exact path="/ContractFarming" component={(args)=><ContractFarming {...args}/>} />
                            <Route exact path="/Brands" component={(args)=><Brands {...args}/>} />
                            <Route exact path="/iaaMarketplace" component={(args)=><IAAMarketplace {...args}/>} />
                            <Route exact path="/RetailInvestors" component={(args)=><RetailInvestors {...args}/>} />
                            <Route exact path="/AgriInvestors" component={(args)=><InstitutionalInvestors {...args}/>} />
                            <Route exact path="/agri-business" component={AgriBusinessCompany}/>

                            <Route exact path='/FoodProcessorMachinery' component={(args)=><ExploreFoodProcessor {...args}/>} />

                            <Route exact path='/agri-entreprenur' component={(args)=><ForFarmers {...args}/>} />

                            <Route exact path='/ExploreNetwork' component={(args)=><ExploreNetwork {...args}/>} />
                            <Route exact path="/ExploreAPIs" component={(args) => <AgriStack {...args}/>}/>
                            <Route exact path='/ABN' component={(args)=><ABNComponent {...args}/>} />
                            <Route exact path='/DTNNetwork' component={(args)=><ProductionNetwork {...args}/>} />
                            <Route exact path='/agribusiness' component={(args)=><AgribusinessMain {...args}/>} />
                            <Route exact path='/agro-dealers-list' component={(args)=><DealerTypes {...args}/>} />
                            <Route exact path="/FFS" component={(args)=><FFS {...args}/>} />


                            
                            <Route exact path="/financial-services" component={(args)=><FinancialService {...args}/>} />
                            <Route exact path="/care-services" component={(args)=><CareServices {...args}/>} />
                            <Route exact path="/asset-market-services" component={(args)=><AssetMarketService {...args}/>} />
                            <Route exact path="/cbo-clubs" component={(args)=><CBOClubs {...args}/>} />

                            <Route exact path="/our-tech-stack" component={(args)=><TechStack {...args}/>} />
                            <Route exact path="/for-system-integrators" component={(args)=><ForNetworKOperators {...args}/>} />
                            <Route exact path="/part" component={(args)=><TechnologyPartners {...args}/>} />
                            <Route exact path="/device-marketplace" component={(args)=><DeviceMarketplace {...args}/>} />
                            <Route exact path="/abn-service" component={(args)=><NetworkOperatorABNService {...args}/>} />
                            <Route exact path="/appstore-service" component={(args)=><NetworkOperatorAppStoreService {...args}/>} />
                            <Route exact path="/devicestore-service" component={(args)=><DeviceStoreService {...args}/>} />
                            <Route exact path="/app-marketplace" component={(args)=><AppMarketplace {...args}/>} />
 
                           
                            <Route exact path="/network-operators" component={(args)=><NetworkOperatorClub {...args}/>} />
                            <Route exact path="/device-makers" component={(args)=><DeviceMakerClub {...args}/>} />
                            <Route exact path="/solution-providers" component={(args)=><SystemProviderClub {...args}/>} />
                            <Route exact path="/api-and-integration" component={(args)=><CertificationServices {...args}/>} />
                            <Route exact path="/ACG" component={(args)=><ACG {...args}/>} />
                            <Route exact path="/SDH" component={(args)=><SDH {...args}/>} />
                            <Route exact path="/GBR-Network" component={(args)=><TPN {...args}/>} />
                            <Route exact path="/device-hub-stack" component={(args)=><DeviceHub {...args}/>} />
                            <Route exact path="/track-trace-stack" component={(args)=><TrackTraceStack {...args}/>} />
                            <Route exact path="/data-hub-stack" component={(args)=><DataHubStack {...args}/>} />
                            <Route exact path="/agrifood-stack" component={(args)=><IoBStack {...args}/>} />
                            <Route exact path="/blockchain-stack" component={(args)=><BlockChainStack {...args}/>} />
                            <Route exact path="/registry-stack" component={(args)=><RegistryGateway {...args}/>} />
                            <Route exact path="/trade-hub-stack" component={(args)=><TradeHubStack {...args}/>} />
                            <Route exact path="/cloud-gateway-stack" component={(args)=><AgriTechStack {...args}/>} />
                            <Route exact path="/ota-device-stack" component={(args)=><OTAStack {...args}/>} />

                            <Route exact path="/dtn-app" component={(args)=><DTNAPP {...args}/>} />
                            <Route exact path="/abn-app" component={(args)=><ABNAPP {...args}/>} />
                            <Route exact path="/cbn-app" component={(args)=><CBNAPP {...args}/>} />
                            <Route exact path="/agriminer-app" component={(args)=><AgriminerApp {...args}/>} />
                            <Route exact path="/onmarketplace-app" component={(args)=><OnMarketPlaceAPP {...args}/>} />
                            <Route exact path="/explore-marketplace" component={(args)=><ExploreMarketplace {...args}/>} />

                            <Route exact path="/social-onmarketplace" component={(args)=><SocialOnMarketplace {...args}/>} />
                            <Route exact path="/social-iaamarketplace" component={(args)=><SocialIAAMarketplace {...args}/>} />
                            <Route exact path="/agrocare-marketplace" component={(args)=><AgroCareMarketPlace {...args}/>} />
                            <Route exact path='/Services' component={(args)=> <ServicesComponent {...args}/>}/>
                            <Route exact path="/billing-service" component={(args)=><BillingService {...args}/>} />

                            <Route component={(args) => <Redirect to={"/"} />} />
                        </Switch>
                        </ScrollToTop>

                    </Router>

                    <NotificationContainer />

                </ThemeProvider>
            // </StyledEngineProvider>
        );
    }
}

ReactDOM.render(<IndexComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

