import React from "react";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import AgribusinessStack from "../AgribusinessStack/AgribusinessStack";
import "./ForFarmers.css";

import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";

export default function ForFarmers() {
  return (
    <>
      <HeaderComponent />
      {/* <UpdatesBanner
        className="farmUpdateBanner text-white"
        bannerText={[
          "Embark on your earning journey now by efficiently ",
          // <br className="d-md-block d-none"></br>,
          "managing profit-generating assets",
        ]}
     
      /> */}

      
<AgribusinessStack
        classname="farmUpdateBanner"
        level1="System Integrator"
        level2="Agribusiness"
        level3="Agri-Entrepreneur"
      />

          {/* <div className="main-wrapper subSectionWrapper mb-0">
          <Container>
            <TitleDescription
              titleClass="text-start"
              classname2="text-start"
              classname="mb-0 mt-0 "
              title="Who are an Agri-Entrepreneur"
              description="An Agri-entrepreneur within the GBR initiative is a progressive farmer who embraces various levels of entrepreneurship in the agri-food sector, strategically leveraging digital transformation to revolutionize the industry. GBR Farming acts as a transformative force, officially recognizing and approving each Agri-entrepreneur, thereby empowering traditional farmers to evolve and thrive in the dynamic landscape of agri-food entrepreneurship."
            />

          </Container>
        </div>
      <FarmerChallangeSection /> */}
      <SolutionEcosystem
      title="DTN Deployment for Agri-Entrepreneur"
        type="AE"
        bodycopy=" AAMC is a type of DTN network where agri-entrepreneurs can join to monetize unutilized farming capacity."
      />
      <div>
    
        {/* <Container>
          <FarmerServices />
        </Container>
        <div className="main-wrapper">
          <GrowerBenefits />
        </div>
        <div className="subSectionWrapper">
          <Container>
            <ForFarmerSection4 />
          </Container>
        </div>
        <div className="main-wrapper">
          <Container>
            <GrowersContribution />
          </Container>
        </div> */}

     
        {/* <FArmerCTATab /> */}
      </div>
      <FooterComponent />
    </>
  );
}
