import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import './ACG.css';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
export default function ACG() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="acg-bg"
        bannerTitle={[
          "Agri Cloud Gateway Services",
        ]}/>

<div className="main-wrapper">
        <div className="subSectionWrapper">
    <HomeSubSection
        className3="app-store-access-sevice"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        ACG"
        description="ACG serves as an interoperability and data exchange network designed to facilitate seamless communication and integration among various machineries, point solutions, and enterprise applications. By leveraging ACG, businesses can bridge the gap between disparate systems, enabling them to exchange data and interact effectively. This interoperability extends across different types of machinery, point solutions, and enterprise applications such as ERP (Enterprise Resource Planning) and QMS (Quality Management System). ACG acts as a centralized hub, allowing for standardized data exchange protocols and protocols, thereby streamlining operations and enhancing efficiency across the entire ecosystem."
        href5="https://partner.gbrfarming.in/register.html?src=registerReq"
        buttoncont2="Register"
        buttoncont1="Explore"
        href1="/ComingSoon"
       
      />
      </div>
      </div>
    <FooterComponent/>
    
    </>  )
}
