import React from "react";
import EasyToUse from "../../images/app-benefits-icon/user-friendly.svg";
import MultiLanguage from "../../images/app-benefits-icon/multi-languages.svg";
import CustomerCare from "../../images/app-benefits-icon/customer-care.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";
export default function APPBenefit() {
  const benefits = [
    {
      img: EasyToUse,
      title: "Easy to Use",
      bodycopy:
        "Enjoy the convenience of our user-friendly app, ensuring seamless navigation and effortless access to all features and functionalities.",
    },
    {
      img: MultiLanguage,
      title: "Multi-language Support",
      bodycopy:
        "Experience the advantage of multi-language support within app, ensuring accessibility and ease of use for users from diverse linguistic backgrounds.",
    },
    {
      img: CustomerCare,
      title: "Customer Care Support",
      bodycopy:
      "Receive exceptional customer care support directly through our app, ensuring prompt assistance and a seamless user experience."
    },

  
  ];
  return (
    <div className="">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">Benefits</h2>
        <Row>
          {benefits.map((e) => {
            return (
              <Col md={4} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
