import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function ABNAPPFeatures() {
  const benefits = [
    {
      img: img3,
      title: "Asset Management",
      bodycopy:
        "Asset Management allows users to create, map to sites, update, and delete assets within the platform.",
    },
    {
      img: img1,
      title: "Site Management",
      bodycopy:
        " Site Management empowers users to create sites and oversee site operations, including updating, deleting, and mapping assets.",
    },

    {
      img: img1,
      title: "IAM Management ",
      bodycopy:
        " IAM Management enables users to create and manage their own users within the platform, facilitating control over access and permissions.",
    },

    {
      img: img1,
      title: "Role Management",
      bodycopy:
        " Role Upgradation allows users to submit role upgrade requests to their parent entities and manage the roles of their subordinate users.",
    },

    {
      img: img1,
      title: "Network  Management",
      bodycopy:
        " Network Management enables users to create and manage their own networks for overseeing sites and assets. Within these networks, users can receive updates from their team regarding site and asset status.",
    },
    {
        img: img1,
        title: "Order  Management",
        bodycopy:
          " Order Management allows users to view incoming orders from other users and fulfill them based on requests and requirements.",
      },
      {
        img: img1,
        title: "Models",
        bodycopy:
          " Models feature empowers users to create customized models for different asset types within the platform.",
      },
  ];
  return (
    <div className="main-wrapper">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">Features</h2>
        <Row>
          {benefits.map((e,index) => {
            return (
              <Col md={4} key={index} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
