import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForManufacturers.css";
import ManufacturersBenefits from "./ManufacturersBenefits";
// import ManufacturesForm from "./ManufacturesForm";
import TitleDescription from '../TitleDescription/TitleDescription';
import { Container } from "@material-ui/core";
import ManufacturerServices from "./ManufacturerServices";
import BrandCTA from "../BrandCTA/BrandCTA";
export default function ForManufactures() {

  return (
    <>


      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
        bannerText={[
          "We Build Brands and help the existing one to Package", <br className="d-lg-block d-none"></br>, "Organic and Traceable Products",
        ]}
        buttonText2="Sign in"
        href2="http://abn.gbrfarming.com/"

        buttonText3="Sign up"
        href3="http://abn.gbrfarming.com/auth/3/signup"
      />

      <Container>
<TitleDescription
          title="Who are an Agri-Input Manufacturer"
          description="Input manufacturers in agriculture encompass companies that produce essential elements for farming. These include agrochemical producers, supplying fertilizers and pesticides; seed companies, specializing in the development of seeds for various crops; manufacturers of farm machinery and equipment, offering tools for cultivation and harvesting; producers of animal feed, contributing to livestock and poultry nutrition; companies developing farm technology solutions; manufacturers of agricultural equipment and tools; and providers of livestock and poultry health products. Collectively, these input manufacturers play a pivotal role in supporting farmers by providing the necessary resources and technology to enhance agricultural productivity and sustainability.
          "
        />
     <ManufacturerServices/>

      </Container>
      {/* <div className="for-manufacturers-wrapper"> */}

        {/* <ManufacturesRoadMap /> */}
        {/* </div> */}

        <Container className="mt-4">
          <ManufacturersBenefits />
        </Container>
  <BrandCTA/>
      <FooterComponent />
    </>
  );
}
