import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function TradeHubBenefits() {
  const benefits = [
    {
      img: img1,
      title: "RFQ based Ordering System ",
      bodycopy: "An RFQ (Request for Quotation) based order system allows buyers to solicit quotes from multiple suppliers for a specific product or service. Suppliers respond with pricing and terms, enabling buyers to compare offers and make informed purchasing decisions."    },
    {
      img: img2,
      title: "History Management",
      bodycopy: "RFQ and order history provides a comprehensive record of procurement activities, including sourcing processes, supplier interactions, purchase transactions, and Delivery tracking, enabling informed decision-making and effective supplier management.",
    },
    {
      img: img3,
      title: "View and share document",
      bodycopy:
        "Viewing and sharing documents is a benefit of TradeHub, allowing users to organize documents for easy retrieval, confirm successful document sharing and recipient access, and find particular documents like tax invoices or purchase orders from the list of available files.",
    },
    {
        img: img3,
        title: "Interoperability",
        bodycopy:
          "Interoperability support for third-party marketplaces is a benefit of TradeHub API, enabling seamless integration and compatibility with various external platforms, enhancing flexibility and accessibility for users.",
      },
      
  ];
  return (
    <div className="">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Benefits
      </h2>
      <Row>
        {benefits.map((e, i) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard key={i} src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
