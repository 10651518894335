import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function TPNFeatures() {
  const benefits = [
    {
      img: img1,
      title: "Solution Management",
      bodycopy: "Solution Management, a GBR Network feature, equips system integrators with tools to create and optimize solutions, enhancing their value in delivering innovative agricultural solutions.",
    },
    {
      img: img2,
      title: "Agribusiness Management",
      bodycopy: "Agribusiness Management, a GBR Network feature for system integrators, enables them to own, manage, and operate agrifood ventures efficiently and enhance agri-food enagement.",
    },
    {
      img: img3,
      title: "SDH Management",
      bodycopy:
        "SDH Management, a GBR Network feature for system integrators, streamlines the management of SolutionDevice Hub operations, enhancing operational efficiency and improve data driven insights.",
    },
    {
        img: img3,
        title: "Build Knowledge Base",
        bodycopy:
          "'Build Knowledge Base,' a GBR Network feature for system integrators,to build generative AI applications, empowers them to create comprehensive repositories, enhancing their expertise and service offerings.",
      },

      {
        img: img3,
        title: "App Development Platform",
        bodycopy:
          "The App Development Platform, a GBR Network feature for system integrators, facilitates the creation of customized farming applications, empowering them to deliver tailored farming solutions efficiently.",
      },

      {
        img: img3,
        title: "Aggregate Value Chain actors",
        bodycopy:
          "In the agricultural and food sector, the value chain involves series of imterconnected activites and actors that contribute to the production, processing, distribution and marketing of agricultural products. GBR Network account helps System Integrators to onboard and manage these actors.",
      },

  
  ];
  return (
    <div className="">
        <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
      GBR Network Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} className="d-flex" key={index}>
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
