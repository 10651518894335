import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import DTNAPPFeatures from "./DTNAPPFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import APPBenefit from "../APPBenefits/APPBenefit";

export default function DTNAPP() {
  const data1 = [
   " Hyper-local Supply Chain Orchestration: DTN offers hyper-local supply chain orchestration services, ensuring precise coordination and efficiency in agricultural logistics at the local level.",
    "Contract Farming Services: DTN app integrates contract farming services for streamlined agricultural operations.",
    "Contract Manufacturing Services: DTN app offers contract manufacturing services to optimize production and supply chain management.",
   
  ];

  const data2 = [
    
    "Storage Service: DTN app includes storage services for efficient management of agricultural inventory. ",
    "Transportation Service : DTN app facilitates transportation services for seamless logistics in agricultural supply chains.",
  ];
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        DlBtn="true"
        className="sdh-bg"
        bannerTitle={["DTN APP"]}
        DownloadText="Download App"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp&hl=en&gl=US"
      />
      <SolutionEcosystem title="Overview" type="DTN" />
      <Container>
        <OverviewFeatures  data1={data1} data2={data2}/>
      </Container>
      <DTNAPPFeatures />
      <APPBenefit/>
      <FooterComponent />
    </>
  );
}
