import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import AgriminerAppFeatures from "./AgriminerAppFeatures";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import APPBenefit from "../APPBenefits/APPBenefit";

export default function AgriminerApp() {
  const data1 = [
    "Onboarding Service: Agriminer app offers streamlined onboarding services for users to easily join",
    "Doc Verification Service: Agriminer app offers document verification services to ensure authenticity and compliance",

  ]

  const data2 = [
    "Site Verification Service: Agriminer app provides site verification services to authenticate and validate location.",

    "App Demo Service: Agriminer app offers an app demo service, providing comprehensive guidance on benefits, usage, and scheme explanations for users' effective engagement.",
  ]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["AgriMiner APP"]}
        DownloadText="Download App"
        dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&pli=1?usp=sharing"
        DlBtn="true"
        
        
      />
      <SolutionEcosystem title="Overview" type="AM" />
      <Container>
    <OverviewFeatures data1={data1} data2 = {data2}/>
      </Container>
      <AgriminerAppFeatures/>
      <APPBenefit/>
    
      <FooterComponent />
    </>
  );
}
