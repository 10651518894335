import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function TrackTraceFeatures() {
  const benefits = [
    {
      img: img1,
      title: "Create and Manage Trips ",
      bodycopy: "Track and Trace API allows users to establish and oversee the logistics of trips, facilitating efficient tracking and management of goods or assets throughout their journey."    },
    {
      img: img2,
      title: "Assign Trips to Vehicles and Drivers",
      bodycopy: "It enables users to allocate specific trips to designated vehicles and drivers, ensuring efficient coordination and execution of logistics operations within the system.",
    },
    {
      img: img3,
      title: "Create and Manage Geofencing Restrictions for Vehicles",
      bodycopy:
        " It allows users to establish and oversee geographical boundaries and restrictions for vehicles, enabling efficient monitoring and control over their movement within specified areas.",
    },
    {
        img: img3,
        title: "Monitor Trips and Their Status",
        bodycopy:
          "It provides users with real-time visibility into the status of trips, facilitating efficient tracking and management of logistics operations within the system.",
      },
      {
        img: img3,
        title: "Real-Time Asset and Vehicle Tracking",
        bodycopy:
          "It enables users to monitor the movement of assets and vehicles continuously, providing up-to-date location information for effective logistics management and monitoring.",
      },
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
