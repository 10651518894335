import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function NetworkOperatorBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Improve Revenue",
      bodycopy: "GBR Network enhances revenue for partners through improved network performance and reliability, leading to increased customer satisfaction and higher usage rates.",
    },
    {
      img: img2,
      title: "Expand Business & Streamline Operations",
      bodycopy: "Expanding into the agrifood sector benefits network operators by diversifying their clientele, increasing revenue streams, and fostering business growth.",
    },
    {
      img: img3,
      title: "New Agri-Investment class",
      bodycopy:
        "Enabling partners to introduce an Agri-investment class for their users, provides a valuable benefit by expanding investment options, diversifying portfolios, and fostering financial inclusivity within the agricultural sector.",
    },
    {
      img: img3,
      title: "Unified Ecosystem",
      bodycopy:
        "The Unified Ecosystem offered by GBR Network streamlines integration processes, enhancing efficiency and facilitating seamless interoperability for system integrators.",
    },
    {
      img: img3,
      title: "Data Diven Insights",
      bodycopy:
        "Data-Driven Insights from GBR Network empower system integrators with actionable intelligence, enabling informed decision-making and optimized solutions.",
    },
    {
      img: img3,
      title: "Enhance Agrifood Business Engagement",
      bodycopy:
        "GBR Network's solution enhances agrifood business engagement for system integrators by providing tools and resources to foster collaboration and innovation within the industry, driving growth and efficiency.",
    },
  
  ];
  return (
    <div className="main-wrapper">
      <h2 className="text-center container-title mb-4">
         Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col key={index} md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
