import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function SDHBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Enable Precision Agriculture",
      bodycopy: "Precision Agriculture offers system integrators the opportunity to deliver advanced technology solutions, optimizing resource usage and increasing crop yields, thereby enhancing their value proposition and market competitiveness.",
    },
    {
      img: img2,
      title: "Enable Interoperatability",
      bodycopy: "Interoperability fosters seamless integration of diverse hardware and software applications, enhancing the versatility and efficiency of system integrators' solutions, leading to greater customer satisfaction and business growth opportunities.",
    },
    {
      img: img3,
      title: "Enable Track & Trace",
      bodycopy:
        "Enabling track and trace of agri assets across multiple sites offers system integrators a valuable solution, enhancing efficiency and decision-making, thereby boosting their market competitiveness.",
    },
    {
        img: img3,
        title: "Easy Integration",
        bodycopy:
          "Seamless integration with third-party FMIS (Farm Management Information Systems) streamlines processes for system integrators, offering enhanced efficiency and expanded functionality to clients, thus reinforcing their market position and client satisfaction.",
      },

      {
        img: img3,
        title: "Increase Profitability & Sustainability",
        bodycopy:
          "Utilizing data to boost profitability and sustainability enables system integrators to deliver value-added solutions, optimizing agricultural practices for clients while bolstering market competitiveness.",
      },
       {
        img: img3,
        title: "Plugin & Plugout Architecture",
        bodycopy:
          "SDH employs a versatile Plugin & Plugout Architecture, facilitating seamless support for various file formats and protocols, enhancing adaptability and interoperability for system integrators.",
      },
  
  
  ];
  return (
    <div className="subSectionWrapper">
        <Container>
      <h2 className="text-center container-title mb-4">
         SDH Benefits
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
