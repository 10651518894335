import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import './NetworkOperatorABNService.css';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
export default function NetworkOperatorABNService() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="network-operator-abn-bg"
        bannerTitle={[
          "ABN Service",
        ]}/>

<div className="main-wrapper">
        <div className="subSectionWrapper">
    <HomeSubSection
        className3="abn-service"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        Own, Manage and Operate ABN Network"
        description="GBR Network's Own, Manage, and Operate ABN Network service offers network operators the ability to efficiently possess, oversee, and run their Agribusiness network. This comprehensive solution not only enables operators to take full ownership of their network infrastructure and manage it effectively but also provides opportunities to earn revenue. By ensuring seamless operations within the Agribusiness sector, operators can generate income through subscription-based access, value-added services, and data monetization. With GBR Network's support, network operators can optimize their operations while creating sustainable revenue streams in the Agribusiness ecosystem.
        "
      href5="hthttps://partner.gbrfarming.in/register.html?src=registerReq"
       buttoncont2="Register"
       buttoncont1="Explore"
       href1="/ABN"
      />
      </div>
      </div>
    <FooterComponent/>
    
    </>
  )
}
