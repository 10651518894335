import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from '@material-ui/core';
import NetworkOperatorChallenges from './NetworkOperatorChallenges';
import SolutionEcosystem from '../WebSite/SolutionEcosystem/SolutionEcosystem';
import NetworkOperatorBenefits from './NetworkOperatorBenefits';
import CTA from "../CTA/CTA"
import NetworkOperatorServices from '../NetworkOperatorServices/NetworkOperatorServices';
import NetworkOperatorPartnerBenefits from './NetworkOperatorPartnerBenefits';
export default function ForNetworKOperators() {
  return (
    <><HeaderComponent/>
<UpdatesBanner
        className="forManufactures"
        bannerTitle={[
          "Advancing Agribusiness digital transformation together with System-Integrators",
        ]}
        bannerbodycopy1 ={["GBR open endless possibilities by introducing Agri-Business Network (ABN) and SolutionDevice Hub (SDH) for system-integrators to create their own ecosystem around agribusiness to scale & diversify their business with short time"]}
        bannerbodycopy="OWN | OPERATE | MANAGE | EARN
          "
        buttonText2="OWN ABN NOW"
        href2="https://partner.gbrfarming.in/login.html?src=0"
        // href1="/auth/3/signup"
        // buttonText1="Join"
        // onClick={() => {
        //   localStorage.setItem("abnUser", "FOOD-PROCESSOR");
        // }}
      /> 
      <Container>
        <TitleDescription
          classname=""
          title="Who are System Integrators"
          description={[
"System integrators are specialized entities responsible for harmonizing diverse hardware and software components into unified systems tailored to meet the specific needs of businesses. They adeptly orchestrate the design, development, implementation, and maintenance of complex technological solutions across various industries. By collaborating with technology vendors and stakeholders, system integrators ensure seamless integration of networking equipment, servers, databases, applications, and more, providing comprehensive services including consulting, project management, training, and ongoing support to optimize clients' IT investments and enhance operational efficiency."          ]}
description2="Financial Institutions | Telecom Companies | Aggregators | IoT Solution Providers | IT Companies | Value Added Re-Sellers


"
        />

      </Container>
      {/* <TechnologyPartners/> */}

      <div className="main-wrapper">
        <NetworkOperatorChallenges />
      </div>
      <SolutionEcosystem
        type="NO"
        bodycopy="We provide an account for system integrators through which they can own and manage SDH and ABN to enable digital transformation and Interoperatability among different hardware and software of agribusiness."
      />
      <div className='subSectionWrapper pb-0 main-wrapper'>
           <Container className="subSectionWrapper">
          <NetworkOperatorBenefits  />
        </Container>
        </div>
        <div className=' pb-0 '>
           <Container className="subSectionWrapper">
          <NetworkOperatorPartnerBenefits title="System-Integrators Customer's Benefits"/>
        </Container>
        </div>
<NetworkOperatorServices/>
        <Container>
      {" "}
      {/* <AgriEntrepenurBenefits /> */}
      <div className="subSectionWrapper">
      <CTA
      // secondBtn= "true" 
      // href3="/agri-business"
      // btnText2= "Explore Agri Business"
       data="present"
        href2="
        https://partner.gbrfarming.in/register.html?src=registerReq"
        heading="Create an account to Own, Operate & Manage Agrifood business network - ABN"
        btnText="Join Now"
      />
    </div>
    </Container>
         <FooterComponent/>
    </>
  )
}
