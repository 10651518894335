import React from "react";
import img1 from "../../images/partner-benefits/quality (2).svg";
import img2 from "../../images/partner-benefits/connect.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import img4 from "../../images/partner-benefits/integrate.svg";
import img5 from "../../images/partner-benefits/asset-track.svg"
import img6 from "../../images/partner-benefits/sell.svg"
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function NetworkOperatorPartnerBenefits({title}) {
  const benefits = [
    {
      img: img1,
      title: "Improve Quality Production",
      bodycopy: "Improved quality production in agribusiness enables competitive differentiation, fostering brand loyalty and facilitating access to premium market segments.",
    },
    {
      img: img2,
      title: "Expand Connect",
      bodycopy: "Establishing robust connections with various ecosystem players in agribusiness fosters secure transactions built on mutual trust, enhancing overall operational reliability and integrity within the industry.",
    },
    {
      img: img3,
      title: "Traceability",
      bodycopy:
      "Implementing traceability measures in agribusiness enhances accountability, boosts consumer confidence, and ensures product integrity by enabling transparent tracking of the entire supply chain from farm to table.",
    },

    {
        img: img4,
        title: "Easy Integration with legacy System",
        bodycopy:
        "Seamless integration with legacy systems in agribusiness streamlines operations, improves efficiency, and optimizes resource utilization, ensuring a smooth transition towards modernization while leveraging existing infrastructure investments.",
      },
      {
        img: img5,
        title: "Track & Trace for the assets",
        bodycopy:
        "Implementing a robust track and trace system for assets in agribusiness ensures accountability, enhances quality control, and mitigates risks by providing real-time visibility into the movement and status of assets throughout the supply chain, thereby fostering greater efficiency."
      },

      {
        img: img6,
        title: "Increase Sell",
        bodycopy:
        "Expanding sales channels through diverse frameworks in agribusiness cultivates market resilience, increases revenue streams, and fosters broader consumer engagement, ultimately enhancing profitability and market reach."      },
  
  ];
  return (
    <div className="">
      <h2 className="text-center container-title mb-4">
        {title}
      </h2>
      <Row>
        {benefits.map((e,index) => {
          return (
            <Col md={4} key={index} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
