import React from "react";

import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

export default function DealerTypes() {
  return (
    <>
      {/* <HeaderComponent />
      <UpdatesBanner
        className="agriEntrepreneur-bg"
        bannerText={[
          "Serving Local farmers and Agri-Retailer ",
          <br className="d-lg-block d-none"></br>,
          "through Agro-Dealer Network ",
        ]}
        // buttonText="Start Earning Now"
        // href="auth/3/signup"
        // clicked={() => {
        //   localStorage.setItem("userType", "Production-Manager");
        // }}

        // DlBtn ="true"
        // dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.aamcapp"
        // DownloadText="Download App"
      /> */}

   {/* <div className="subSectionWrapper main-wrapper">
        <h4 className="container-title text-center mx-md-4 ">
         Agro-Dealers
        </h4> */}
      <HomeSubSection
        className3="technology-provider-image"
        className="rowReverseAbout borderedCards pb-0"
        descriptionTitle="
        FMC Dealers"
        description="In GBR, the Farm Machinery Company serves as a key dealer, supplying farmers and agri-retailers with a diverse range of farm machinery solutions. This partnership aims to support agricultural stakeholders by offering them access to reliable and advanced equipment. Through this collaboration, the Farm Machinery Company contributes to the optimization of farming operations, ensuring that farmers and retailers have the tools they need for enhanced efficiency and productivity.

            "
        href2="auth/3/signup"
        buttoncont2="Register"
        onClick={() => {
          localStorage.setItem("userType", "FMC");
        }}
      />


<HomeSubSection
        className3="technology-provider-image"
        className="rowReverseAbout--mod borderedCards pb-0"
        descriptionTitle="
        PMC Dealers"
        description="As a trusted dealer in GBR, the Processing Machinery Company collaborates with farmers and retailers, offering a comprehensive range of processing machinery solutions. This partnership aims to empower agricultural stakeholders by providing them with efficient and modern equipment tailored to their processing needs. Through this association, the Processing Machinery Company contributes to enhancing productivity and streamlining operations within the agricultural sector, ensuring farmers and retailers have access to cutting-edge processing technologies.






            "
        href2="auth/3/signup"
        buttoncont2="Register"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />


<HomeSubSection
        className3="technology-provider-image"
        className="rowReverseAbout borderedCards pb-0"
        descriptionTitle="
        Animal Health Service Providers"
        description="Within GBR, Animal Health Service Providers play a pivotal role as suppliers of a diverse range of animal healthcare products to farmers and agri-retailers. This vital collaboration ensures that livestock owners and agricultural businesses have reliable access to high-quality veterinary solutions. By facilitating the availability of essential animal health products, these service providers contribute significantly to the overall well-being, productivity, and health management of livestock in the agricultural industry.


            "
        href2="auth/3/signup"
        buttoncont2="Register"
        onClick={() => {
          localStorage.setItem("userType", "AHP");
        }}
      />

<HomeSubSection
        className3="technology-provider-image"
        className="rowReverseAbout--mod borderedCards pb-0"
        descriptionTitle="
        Agri Input Companies"
        description="In GBR, Agri-input companies serve as key providers, offering a spectrum of plant health care products to both farmers and agri-retailers. This collaborative effort ensures that agricultural stakeholders have access to a diverse range of high-quality products tailored to enhance plant health and optimize crop yield. By supplying essential plant health care solutions, these companies play a crucial role in supporting sustainable and efficient agricultural practices, contributing to the overall success and prosperity of farmers and agri-retailers in the region.
            "
        href2="auth/3/signup"
        buttoncont2="Register"
        onClick={() => {
          localStorage.setItem("userType", "PHP");
        }}
      />
      {/* </div>
      <FooterComponent /> */}
    </>
  );
}
