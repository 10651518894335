import React from "react";
import FarmerImg from "../../../images/gbrPN/PNImg1.webp";
import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import PNBenefits from "../Benefits/PNBenefits";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import PN from "../../../images/DTN Network - GBR Tech - 23-04-2024 N.gif";
import ProductionManagerImg from "../../../images/DTN Network - GBR Tech - 23-04-2024 N.gif";
import HeaderComponent from "../Header/HeaderComponent";
import { Container } from "@material-ui/core";
import FooterComponent from "../Footer/FooterComponent";

import PNServices from "../../OurServices/PNServices";
import ProductionNetworkServices from "./ProductionNetworkServices";
import ProductionNetworkBenefits from "./ProductionNetworkBenefits";

export default function ProductionNetwork() {
  return (
    <>
      <HeaderComponent />



<UpdatesBanner
        className="pnbg text-white"
        bannerTitle ="Monetize Platform"
        bannerbodycopy1={[


          "Network for Agrifood MSMEs to Monetize the unutilized capacities ",   <br className="d-lg-block d-none"></br>,"to maximize the profit",
        ]}
        
      />

<div className="mt-5 mb-5">
  <Container>
<h4 className="container-title text-center mx-md-4 ">
Overview          </h4> </Container>
        <HomeSubSection
          fontBold="true"
          className1="aboutBgGrey"
          className="rowReverseAbout align-items-center"
          listItems={<PNBenefits/>}
          description="  Production network also called Digital Transformation Network (DTN) is designed to manage investable assets by agribusiness for following benefits
          "
          src={PN}
          // href1="/DTNNetwork"
          // buttoncont1="Explore Production Network"
          grid="true"
          btn2= "true"

        />
      </div>


<ProductionNetworkServices/>
<div className="">
      <PNServices title="Features" />
      </div>
      <ProductionNetworkBenefits/>

      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">
           BENEFICIARIES OF THE DTN NETWORK
        </h2>

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="image11"
          description="GBR's agri-entrepreneurs are the backbone of our network, they're the progressive farmers who meet our rigorous verification process, ensuring the quality and reliability of the produce in our eco-system.
          "
          descriptionTitle="
              Agri-entrepreneur"
          src={FarmerImg}
          buttoncont1 = "View DTN deployment for agri-entrepreneur"
          href1 = "/agri-entreprenur"
        />

 

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="image15"
          description="GBR's agri-entrepreneurs are the backbone of our network, they're the progressive farmers who meet our rigorous verification process, ensuring the quality and reliability of the produce in our eco-system.
              "
          descriptionTitle="
              Contract Manufacturer "
          src={ProductionManagerImg}
          buttoncont1 = "View DTN deployment for contract manufacturer"
          href1 = "/contract-manufacturer"
        />


     

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="agri-storage-entreprenuer-role"
          description="Agri-storage entrepreneurs play a pivotal role by monetizing their storage capacity, offering valuable space to agri-entrepreneurs. Their strategic management of resources adds a crucial dimension to the success of our agricultural network.
              "
          descriptionTitle="
              Agri-Storage Entreprenuer"
          src={ProductionManagerImg}
          buttoncont1 = "View DTN deployment for agri-Storage entreprenuer"
          href1 = "/agri-storage-entrepreneurs"
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="agri-transporter-role"
          description="GBR Agri-transporters are the backbone of our agricultural network, ensuring efficient product movement and optimal resource utilization. With access to our dynamic marketplace, they can monetize their transportation capacity effectively, contributing to the sustainable growth of the agri-industry.
              "
          descriptionTitle="
              Agri Transporter"
          src={ProductionManagerImg}
          buttoncont1 = "View DTN deployment for agri transporter"
          href1 = "/agri-transporters"
        />



</div>

<div className="subSectionWrapper main-wrapper">
<h2 className="container-title text-center ">
          DTN NETWORK OPERATORS       </h2>
        
<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards"
          className3="agribusiness-img"
          description="A farm enterprise, also known as a farming enterprise, is an agribusiness that is primarily engaged in agricultural activities. This includes the cultivation of crops, the raising of livestock, and other related farming practices. We help farm enterprises to become Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.


              "
          descriptionTitle="
          Farm Enterprise

          "
          src={ProductionManagerImg}
          
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards"
          className3="agri-foodmanufacturer-img"
          description="Food Processing Enterprises are companies involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.
              "
          descriptionTitle="
          Food Processing Enterprises

          "
          src={ProductionManagerImg}
          
        />

<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards"
          className3="agribusiness-img"
          description="Logistic providers are the backbone of our agricultural network, ensuring efficient product movement and optimal resource utilization. With access to our dynamic marketplace, they can monetize their transportation capacity effectively, contributing to the sustainable growth of the agri-industry.


              "
          descriptionTitle="
          Logistic Providers

          "
          src={ProductionManagerImg}
          
        />

        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards"
          className3="agri-foodmanufacturer-img"
          description="Storage companies play a pivotal role by monetizing their storage capacity, offering valuable space to agri-entrepreneurs. Their strategic management of resources adds a crucial dimension to the success of our agricultural network.
              "
          descriptionTitle="
          Storage Companies

          "
          src={ProductionManagerImg}
          
        />
      </div>
      <FooterComponent />
    </>
  );
}
