import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function AgriTechStackFeatures() {
  const benefits = [
    {
      img: img1,
      title: "Integration Adapters and Connectors ",
      bodycopy: "  The stack should provide pre-built integration adapters and connectors for popular IoT platforms, cloud services, ERP systems, and other third-party applications. These adapters simplify integration efforts and accelerate time-to-market for new applications and services."    },
    {
      img: img2,
      title: "Cloud-agnostic Deployment",
      bodycopy: "  The stack should be cloud-agnostic, allowing deployment across multiple public clouds (e.g., AWS, Azure, Google Cloud) and private clouds (e.g., OpenStack, VMware). It should support multi-cloud deployment strategies, including hybrid cloud and multi-cloud architectures, to avoid vendor lock-in and ensure flexibility and portability.",
    },
    {
      img: img3,
      title: "Protocol Agnostic",
      bodycopy:
        "  The stack should support a wide range of communication protocols commonly used in IoT and  IOB. It should provide protocol translation capabilities to facilitate communication between devices, systems, and applications using different protocols.",
    },
    {
        img: img3,
        title: "Standardized APIs",
        bodycopy:
          " The stack should offer standardized APIs that abstract the complexity of underlying systems and protocols, enabling seamless integration with third-party IoT platforms, IOB systems, cloud services, ERP systems, and other applications. These APIs should follow industry standards such as RESTful APIs, or WebSocket.",
      },

  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
