import React from "react";
import PeerToPeer from "../../images/social-iaamarketplace/peer.svg";
import BetterROI from "../../images/social-iaamarketplace/return-on-investment.svg";
import RaiseCapital from "../../images/social-iaamarketplace/capital.svg";
import LiveMonitoring from "../../images/social-iaamarketplace/live-monitoring.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function SocialIAAMarketplaceBenefits() {
  const benefits = [

   
     
    {
      img: PeerToPeer,
      title: "Peer-to-Peer Networking",
      bodycopy:
      "Investors can connect with other members of the Social IAA Marketplace community, share insights, exchange investment ideas, and collaborate on potential deals.",
    },
    {
        img: BetterROI,
        title: "Better ROI",
        bodycopy: " Social IaaMarketplace enables better returns on tangible assets, optimizing investment opportunities within its ecosystem."    },
     

        {
          img: RaiseCapital,
          title: "Raise Capital",
          bodycopy: " Social IaaMarketplace offers an alternative avenue for raising capital, providing businesses with streamlined access to funding.          "    },
 
          {
            img: LiveMonitoring,
            title: "Live Monitoring",
            bodycopy: " Social iaaMarketplace provides live monitoring capabilities for investable assets, allowing users to track their performance and make informed investment decisions in real-time.  "    },
        ];
  return (
    <div className="">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Benefits
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
