import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import SocialOnMarketplaceFeature from "./SocialOnMarketplaceFeature";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import SocialOnMarketplaceBenefit from "./SocialOnMarketplaceBenefit";

export default function SocialOnMarketplace() {
  const data1 = [
    "Contract Farming: Social OnMarketplace facilitates contract farming of assets and raw products, optimizing agricultural production and distribution.",
    "Contract Manufacturing: Social OnMarketplace offers contract manufacturing of processed products, streamlining production and distribution for businesses."
  ]

  const data2 = [
    "Bulk Buy: Social OnMarketplace enables bulk purchasing of raw, semi-processed, and processed products, enhancing efficiency in procurement and supply chain management.",
    
  ]
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["Social OnMarketplace"]}
        bannerbodycopy="Ensured Quality | Traceability | Transparency"
        
      />
      <SolutionEcosystem title="Overview" type="SM" />
      <Container>
      <OverviewFeatures data1={data1} data2 = {data2}/>

      </Container>
      <SocialOnMarketplaceFeature/>
      <SocialOnMarketplaceBenefit/>
    
      <FooterComponent />
    </>
  );
}
