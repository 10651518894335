import React from 'react'
import HeaderComponent from '../../WebSite/Header/HeaderComponent'
import FooterComponent from '../../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../../UpdatesBanner/UpdatesBanner'
import HomeSubSection from '../../WebSite/HomeSubSection/HomeSubSection'
import './CareServices.css'

export default function CareServices() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner bannerText="Care Service" className="care-service-bg"/>
    <div className="main-wrapper">
        <div className="subSectionWrapper">
         

          <HomeSubSection
            className3="veterinary-service"
            className1="aboutBgGrey"
            className="rowReverseAbout borderedCards"
            descriptionTitle="
            Veterinary"
            description="GBR offers a veterinary service tailored to the needs of agri-producers, providing on-site checkups and medical care for livestock. Veterinary professionals dispatched by GBR conduct thorough examinations and provide necessary treatments to ensure the health and well-being of animals. This service not only promotes animal welfare but also supports agri-producers in maintaining the productivity and profitability of their livestock operations. By offering accessible and reliable veterinary care, GBR contributes to the sustainable growth of the agricultural sector.
        "
          />

          <HomeSubSection
            className3="advisory-service"
            className1="aboutBgGrey"
            className="rowReverseAbout--mod borderedCards"
            descriptionTitle="
        Advisory"
            description="GBR's advisory service is tailored to the needs of agri-producers, offering expert guidance on animal, plant, and field health care. Through this service, agricultural specialists provide personalized recommendations and best practices to optimize the health and productivity of livestock, crops, and fields. Whether it's disease prevention strategies, crop management techniques, or soil health improvement measures, GBR's advisory service equips agri-producers with the knowledge and tools necessary to enhance agricultural sustainability and profitability.
        "
          />
        </div>
      </div>
    <FooterComponent/>
    </>
  )
}
