import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
// import './AGG.css';
import HomeSubSection from '../WebSite/HomeSubSection/HomeSubSection';
export default function CertificationServices() {
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner className="network-operator-appstore-bg"
        bannerTitle={[
          "API & Integration",
        ]}/>

{/* <div className="main-wrapper">
        <div className="subSectionWrapper"> */}
    <HomeSubSection
        className3="app-store-access-sevice"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards"
        descriptionTitle="
        App Certifications"
        description="GBR Network extends app certification services to their partners, ensuring the quality, security, and compatibility of their applications. Through this service, partners can undergo a rigorous evaluation process to validate their apps against industry standards and best practices. GBR Network's certification process encompasses thorough testing and analysis to guarantee that the applications meet predefined criteria for performance, reliability, and user experience. By obtaining certification from GBR Network, partners can enhance the credibility of their apps, instill trust among users, and accelerate their market penetration. This service underscores GBR Network's commitment to facilitating the success of its partners by delivering high-quality and reliable solutions.
        "
        href5="https://partner.gbrfarming.in/register.html?src=registerReq"
        buttoncont2="Register"
        buttoncont1="Explore"
        href1="/ComingSoon"
       
      />
      {/* </div>
      </div> */}
    <FooterComponent/>
    
    </>  )
}
