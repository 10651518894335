import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import { Container } from "@material-ui/core";
import CTA from "../CTA/CTA";
import OverviewFeatures from "../OverviewFeatures/OverviewFeatures";
import TrackTraceBenefits from "./TrackTraceBenefits";
import TrackTraceFeatures from "./TrackTraceFeatures";
export default function TrackTraceStack() {
  const data1 = [
    "Agri-asset Live Monitoring: Track and Trace API offers Agri-asset Live Monitoring, enabling real-time tracking and surveillance of agricultural assets for enhanced security and management. ",
    "Agri-Asset Geofence: Track and Trace API includes Agri-Asset Geofence, allowing users to set virtual boundaries for agricultural assets, enhancing security and monitoring capabilities.",
    "Order Tracking: Track and Trace API offers Order Tracking, providing real-time visibility into the status and location of agricultural orders for streamlined logistics management.",
    "Provenance: Track and Trace API offers allows for the tracking and documentation of the origin, journey, and authenticity of products or assets throughout their lifecycle.",
  ];
  const data2 = [
    "Trip Management: Track and Trace API includes Trip Management, allowing for efficient planning, monitoring, and optimization of agricultural transportation routes and schedules.",
    "              IAM : IAM  validates access tokens, ensuring secure and authorized access to resources and services within the system based on defined policies and permissions.",
    "                DB : The database (db) stores configurations, device metadata, topics, and commands, serving as a central repository for managing crucial system information and facilitating efficient data retrieval and manipulation.",
  ];
  return (
    <>
      {" "}
      <HeaderComponent />
      <UpdatesBanner
        className="sdh-bg"
        bannerTitle={["Track and Trace"]}
        bannerbodycopy="Trip Management | Live Monitoring | Geofence


      "
      />
      <SolutionEcosystem title="Overview" type="TT" />
      <Container>
        <OverviewFeatures data1={data1} data2={data2} />
      </Container>
      <TrackTraceFeatures/>
      <TrackTraceBenefits />
      {/* <ServiceTypes
              href="http://54.185.221.117:8902/v2/api-docs/"
              alt="Icon"
              id="systemView"
              src={systemViewImg}
              title="DeviceHub Stack Experience"
           
              description2="Integrate | Connect | Manage | Operate"
              target="true"
            /> */}
      <Container className="subSectionWrapper">
        <CTA
          href4="http://54.185.221.117:8902/v2/api-docs"
          heading="Do yow want to develop applications or Integrate your legacy hardware and software..."
        />
      </Container>
      <FooterComponent />
    </>
  );
}
