import React from "react";
import AgribusinessDiscovery from "../../images/social-iaamarketplace/agribusiness-discovery.svg";
import InvestorDiscovery from "../../images/social-iaamarketplace/investor-discovery.svg";
import AssetDiscovery from "../../images/social-iaamarketplace/asset-discovery.svg";
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function SocialIAAMarketplaceFeatures() {
  const benefits = [
    {
      img: AgribusinessDiscovery,
      title: " Agribusiness Discovery",
      bodycopy:
        " Investors can browse through a directory of agribusiness profiles, filtering by criteria such as industry sector, location, or investment size, to discover suitable opportunities",
    },

    {
      img: InvestorDiscovery,
      title: "Investor Discovery",
      bodycopy:
        " Agribusinesses can explore a comprehensive directory of investor profiles, filtering by criteria such as investment focus, geographical preference, or investment size, to identify potential partners and funding opportunities.",
    },

    {
      img: AssetDiscovery,
      title: " Investable Asset Discovery",
      bodycopy:
        " Social iaaMarketplace facilitates the discovery of investable assets, empowering users to explore and identify promising investment opportunities within the platform's ecosystem.",
    },
  ];
  return (
    <div className="main-wrapper">
      <Container className="subSectionWrapper ">
        <h2 className="text-center container-title mb-4">Features</h2>
        <Row>
          {benefits.map((e) => {
            return (
              <Col md={4} className="d-flex">
                <BenefitsCard
                  src={e.img}
                  title={e.title}
                  bodycopy={e.bodycopy}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
