import React from 'react'
import HomeSubSection from '../HomeSubSection/HomeSubSection'
import ProductionManagerImg from "../../../images/gbrPN/PNImg5.webp";

export default function CBNManager() {
  return (
    <>
          <h2 className='text-center container-title'>CBN OPERATORS & MANAGERS</h2>

    <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards"
          className3="agro-dealer-img"
          description="Agro-dealers serve as vital intermediaries, connecting agri-retailers and farmers with crucial agricultural inputs and services at the local level. They offer a range of resources, including seeds, fertilizers, and pesticides, while also providing technical support, credit facilities, and market access. Through these services, agro-dealers contribute significantly to improving agricultural productivity, empowering farmers with the necessary tools and knowledge for sustainable and profitable farming practices.
              "
          descriptionTitle="
          Agro-Dealer"
          src={ProductionManagerImg}
          
        />

<HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards"
          className3="veterinary-service"
          description="In CBN, veterinarians play a crucial role as esteemed members. Leveraging their expertise in animal health and welfare, they contribute valuable insights and resources to various initiatives. Whether providing guidance on animal husbandry practices, delivering medical care for livestock, or advocating for the well-being of pets and working animals, veterinarians are integral to promoting sustainable agriculture and ensuring food safety. Their active participation underscores the interconnectedness of agriculture, animal welfare, and community prosperity within our network, emphasizing the importance of collaboration and specialized knowledge.
            "
          descriptionTitle="
          Veterinary "
          
        />

<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards"
          className3="care-service-bg"
          description="Agronomists are pivotal members of our CBN, offering essential expertise in crop production and agricultural management. Their profound understanding of soil science, crop physiology, and sustainable farming practices greatly enriches our collective efforts. Agronomists provide invaluable guidance to farmers on optimizing crop yields, implementing efficient irrigation systems, and adopting environmentally friendly cultivation methods. As active participants in the CBN, agronomists contribute to initiatives aimed at enhancing agricultural productivity, conserving natural resources, and promoting resilient farming communities. Their dedication underscores the importance of scientific knowledge and innovation in ensuring the success and sustainability of our agricultural endeavors within the network.
              "
          descriptionTitle="
          Agronomist"
          src={ProductionManagerImg}
     
        />

<HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards"
          className3="agri-retailer-role"
          description="The Agri-Asset Distributor plays a vital role in our community by efficiently connecting agricultural producers with essential resources such as land, equipment, and inputs. With their expertise in the agricultural landscape and market dynamics, they streamline the distribution process, ensuring that farmers have access to the tools necessary for maximizing productivity and profitability. Whether coordinating farmland leases, providing machinery, or supplying seeds and fertilizers, the Agri-Asset Distributor contributes to the resilience and sustainability of our agricultural sector, supporting rural livelihoods and economic growth.
            "
          descriptionTitle="
          Agri-Asset Distributor
 "

        />

<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards"
          className3="agri-retailer-role"
          description="Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues in farming, thereby promoting resilient and inclusive agricultural systems at the local level.


              "
          descriptionTitle="
          CBO"
          src={ProductionManagerImg}
        
        />
    </>
  )
}
