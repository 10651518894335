import React from "react";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";

export default function AgribusinessMainSection() {
  return (
    <>
      <HomeSubSection
        id="ABN-Investor"
        className3="agribusiness-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards--mod"
        descriptionTitle="
                Farm Enterprise"
        description="A farm enterprise, also known as a farming enterprise, is an agribusiness that is primarily engaged in agricultural activities. This includes the cultivation of crops, the raising of livestock, and other related farming practices. We help farm enterprises to become Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.

        "
        description1="Organic farming companies | Agri-asset management companies"
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In"
      />
      <HomeSubSection
        id="ABN-Investor"
        className3="agri-foodmanufacturer-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Food Processing Enterprises"
        description="Food Processing Enterprises are companies involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.
        "
        description1="Food processor "
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In"
      />
      <HomeSubSection
        id="ABN-Investor"
        className3="agro-dealer-img"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards--mod"
        descriptionTitle="
        Agro-Dealers"
        description="Agro-dealers serve as vital intermediaries, connecting agri-retailers and farmers with crucial agricultural inputs and services at the local level. They offer a range of resources, including seeds, fertilizers, and pesticides, while also providing technical support, credit facilities, and market access. Through these services, agro-dealers contribute significantly to improving agricultural productivity, empowering farmers with the necessary tools and knowledge for sustainable and profitable farming practices.
        "
        description1="Agro-Dealers | Agro-Retailers | Veterinary | Agronomist | AIC-Dealers | AHSP-Dealers | PMC-Dealers | FMC-Dealers"
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In"
      />

      <HomeSubSection
        id="ABN-Investor"
        className3="cbo-img"
        className1="aboutBgGrey"
        className="rowReverseAbout--mod borderedCards--mod"
        descriptionTitle="
        Community Based Organizations"
        description="Community-based organizations (CBOs) in are grassroots groups formed by individuals within a community to collectively address agricultural challenges and enhance rural livelihoods. These organizations play a pivotal role in promoting sustainable farming practices, improving access to markets and resources, and strengthening community ties. By fostering collaboration and knowledge-sharing, CBOs contribute to the overall development of agriculture, empower small-scale farmers, and address specific issues  in farming, thereby promoting resilient and inclusive agricultural systems at the local level.
        "
        description1="FPCs | NGOs | PACS | Traders | SHG | CS"
        // href4="https://abn.gbrapp.com/"
        // buttoncont1="Sign In"
      />
    </>
  );
}
