import React from "react";
import Organic from "../../images/onMarketplace-benefits-icon/organic.svg"
import Tracable from "../../images/onMarketplace-benefits-icon/traceability-provenance.svg"
import ProductListing from "../../images/onMarketplace-benefits-icon/product-listing.svg"

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function OnMarketPlaceAPPBenefits() {
  const benefits = [

   
      {
      img: Organic,
      title: "Organic & Natural Products",
      bodycopy: " OnMarketplace provides users with access to a diverse range of organic and natural products, promoting healthier and sustainable lifestyle choices. ",
    },
    {
        img: Tracable,
        title: "Traceability & Provenance",
        bodycopy: " OnMarketplace ensures traceability and provenance, providing transparency in product origin and journey, instilling trust in purchases. ",
      },
 
    {
        img: ProductListing,
        title: "Easy Product Listing",
        bodycopy: "Provide a simple interface for sellers to add their products with detailed descriptions, images, pricing, and certification details."    },
     
  ];
  return (
    <div className="">
          <Container className=" subSectionWrapper">
      <h2 className="text-center container-title mb-4">
          Benefits
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
