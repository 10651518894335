import React from "react";
import ExploreAnimalCareBanner from "./ExploreAnimalCareBanner";
import { Container } from "@material-ui/core";
import ProcessedProducts from "../ExploreProducts/ProcessedProducts";
import "./ExploreAnimalCare.css"
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";

export default function ExploreAnimalCare() {
    const userType = ["AHSP"]
  return (
    <>
    <HeaderComponent/>
      <ExploreAnimalCareBanner />

      <Container className="mt-5 update-container-wrapper">
      <h3 class="container-title text-center mb-4">Animal Health Products</h3>

        <ProcessedProducts type="AHP" userType={userType} cbn="true" />
      </Container>

      <FooterComponent/>
    </>
  );
}
