import React from "react";
import ContractFarming from "../../images/social-onmarketplace-icons/contract-farming.svg";
import ContractManufacturing from "../../images/social-onmarketplace-icons/contract-manufacturing.svg";

import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";
import { Container } from "@material-ui/core";

export default function SocialOnMarketplaceFeature() {
  const benefits = [

   


    {
        img: ContractManufacturing,
        title: "Contract Manufacturing",
        bodycopy: "GBR Network facilitates brands by providing contract manufacturing services for their products.",
      },

      {
        img: ContractFarming,
        title: "Contract Farming",
        bodycopy: "GBR Network enables brands to engage in contract farming agreements, ensuring a sustainable and reliable supply of agricultural produce for their products.",
      },
    // {
    //   img: img3,
    //   title: "Peer-to-Peer Networking",
    //   bodycopy:
    //   "Investors can connect with other members of the Social IAA Marketplace community, share insights, exchange investment ideas, and collaborate on potential deals.",
    // },
    // {
    //     img: img1,
    //     title: "Easy Product Listing",
    //     bodycopy: "Provide a simple interface for sellers to add their products with detailed descriptions, images, pricing, and certification details."    },
     
  ];
  return (
    <div className="main-wrapper">
          <Container className="subSectionWrapper ">
      <h2 className="text-center container-title mb-4">
          Features
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
      </Container>
    </div>
  );
}
